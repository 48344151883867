import Helpers from '@/src/utils/shared/helpers'

export default function BlogItem(props) {
  const {
    title,
    date = '2020-06-01',
    url = null,
  } = props
  return (
    <>
      <div className="column">
        {url && (
          <a
            href={url}
            className="blog-post"
          >
            <span className="blog-post-date label">{Helpers.format.human.date.long(date)}</span>
            <span className="blog-post-title heading-1">{title}</span>
          </a>
        )}
        {!url && (
          <div className="blog-post">
            <span className="blog-post-date label">{Helpers.format.human.date.long(date)}</span>
            <span className="blog-post-title heading-1">{title}</span>
          </div>
        )}
      </div>
    </>
  )
}
