import React, {useEffect} from 'react'
import Section from './shared/Section'
import {InlineTextWithVariables, RichtextWithVariables} from './shared/SectionsWithVariables'

export default function BMICalculator(props) {
  const {
    title,
    subtitle,
    color_scheme,
    className,
    css_overrides,
    color_scheme_id_override, lucid_page_section_id, meta,
    cms,
  } = props
  const [calcValues, setCalcValues] = React.useState({
    measurement: 'imperial',
    weight: 0,
    height: 0,
    calculatedValue: null
  })

  const [heightImperial, setHeightImperial] = React.useState({
    feet: 0,
    inches: 0,
  })

  const convertLBSToKG = (lbs) => {
    return lbs / 2.2046
  }

  const calculateBMI = () => {
    let {weight, height} = calcValues
    if (calcValues.measurement === 'imperial') {
        weight = convertLBSToKG(weight)
    }
    height = height / 100
    const bmi = weight / (height * height)
    setCalcValues({
      ...calcValues,
      calculatedValue: Math.round(bmi * 100) / 100
    })
  }

  const reset = () => {
    setCalcValues({
      ...calcValues,
      calculatedValue: null,
    })
  }

  const changeMeasurement = (e) => {
    setCalcValues({
      ...calcValues,
      measurement: e.target.value,
    })
  }

  useEffect(() => {
    console.log('calcValues', calcValues, heightImperial)
  }, [calcValues, heightImperial])

  useEffect(() => {
    const inchesToCM = 2.54
    const inchesInFoot = 12
    setCalcValues({
        ...calcValues,
        height: (Number(heightImperial.feet) * inchesInFoot + Number(heightImperial.inches)) * inchesToCM,
    })
  }, [heightImperial])


  return (
    <Section
      cms={cms}
      css_overrides={css_overrides}
      color_scheme_id_override={color_scheme_id_override} meta={meta}
      lucid_page_section_id={lucid_page_section_id}
      className={className + 'section bmi-calculator-section'}
      name="bmicalculator"
      colorSchemeId={color_scheme}
    >
      <div className="section-container">
        <div className="section-content">
          <div className="section-heading">
            <h2>
              <span className="title heading-1"><InlineTextWithVariables cms={cms} name="title" value={title}/></span>
              <span className="subtitle heading-3"><InlineTextWithVariables cms={cms} name="subtitle" value={subtitle}/></span>
            </h2>
          </div>
          <div className="row">
            <div className="column">
              <div className="section-richtext">
                <div className="BMI-calc">
                  <div className="contact-form">
                    {
                      calcValues.calculatedValue === null && <form className="BMI-calc__form">
                        <fieldset>
                          <div className="field BMI-calc__field__measurement">
                            <label>
                              <input checked={calcValues.measurement === 'imperial'} name="measurement" type="radio"
                                     value="imperial" onChange={changeMeasurement}/>
                              <span className="label">Imperial</span>
                            </label>
                            <label>
                              <input checked={calcValues.measurement === 'metric'} name="measurement" type="radio"
                                     onChange={changeMeasurement} value="metric"/>
                              <span className="label">Metric</span>
                            </label>
                          </div>
                          <div className="field BMI-calc__field BMI-calc__input BMI-calc__input--weight">
                            <label><strong><span className="label">Weight</span></strong> <span
                                className="BMI-calc__unit">{calcValues.measurement === 'imperial' ? 'pounds' : 'kg'}</span>
                            </label> <input max="999" min="0" name="weight_lbs" required="" step="1" type="number" value={calcValues.weight} onChange={(e) => {
                            setCalcValues({
                              ...calcValues,
                              weight: e.target.value,
                            })
                          }}/>
                          </div>
                          {
                              calcValues.measurement === 'imperial' &&
                              <>
                                <div className="field BMI-calc__field BMI-calc__input BMI-calc__field__feet">
                                  <label htmlFor="height_ft"><strong><span className="label">Height</span></strong> <span
                                      className="BMI-calc__unit small" >feet</span> </label>
                                  <input max="9" min="0" name="height_ft" required="" step="1" type="number" value={heightImperial.feet}
                                         onChange={(e) => {
                                           setHeightImperial({
                                             ...heightImperial,
                                             feet: e.target.value,
                                           })
                                         }}/>
                                </div>
                                <div className="field BMI-calc__field BMI-calc__input BMI-calc__field__inches">
                                  <label htmlFor="height_in"><strong><span className="label">Height</span></strong> <span
                                      className="BMI-calc__unit small">inches</span> </label>
                                  <input max="11" min="0" name="height_in" required="" step="1" type="number" value={heightImperial.inches}
                                         onChange={(e) => {
                                           setHeightImperial({
                                             ...heightImperial,
                                             inches: e.target.value,
                                           })
                                         }}/>
                                </div>
                              </>
                          }
                          {
                              calcValues.measurement === 'metric' &&
                              <>
                                <div className="field BMI-calc__field BMI-calc__input">
                                  <label htmlFor="height_cm"><strong><span className="label">Height</span></strong> <span
                                      className="BMI-calc__unit small">centimeters</span> </label>
                                  <input max="999" min="0" name="height_cm" required="" step="1" type="number" value={calcValues.height}
                                         onChange={(e) => {
                                           // console.log(e.target.value)
                                           setCalcValues({
                                             ...calcValues,
                                             height: e.target.value,
                                           })
                                         }}/>
                                </div>
                              </>
                          }

                          <button className="BMI-calc__button BMI-calc__submit button" name="button" type="submit"
                                  onClick={calculateBMI}>
                            <span><strong>Calculate</strong></span></button>
                        </fieldset>
                      </form>
                    }
                    {
                        calcValues.calculatedValue !== null && <div className="BMI-calc__results active">
                      <p className="BMI-calc__results-number heading-1">{isNaN(calcValues.calculatedValue) ? 'There was an error' : calcValues.calculatedValue}</p>
                      <p className="BMI-calc__results-status heading-3">{isNaN(calcValues.calculatedValue) && 'Please verify that you have filled out all fields'}</p>
                      <button className="BMI-calc__button BMI-calc__reset button button-border-0-1 button" type="button" onClick={reset}>
                        <span className="button-border-0-2"></span>
                        <span className="button-text button"><span><strong>Recalculate</strong></span></span>
                      </button>
                    </div>
                    }
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="section-background"></div>
    </Section>
  )
}

BMICalculator.StyleCore = {
  config: {},
  target: {
    componentName: Object.keys({BMICalculator})[0].toLowerCase(),
  },
  css: ''
}
