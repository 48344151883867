import React, {ChangeEvent, useEffect, useState} from 'react'
import useGoogleFonts from 'use-google-fonts'
import {
  Badge,
  Button,
  Card,
  Collapse,
  FormElement,
  Grid,
  Input,
  Link,
  Loading,
  Modal,
  Spinner,
  Text
} from '@nextui-org/react'
import {
  Add,
  ArrowDown3,
  ArrowLeft,
  Box1,
  BoxAdd,
  DocumentText,
  Edit2,
  Information,
  MinusSquare,
  SearchNormal,
  Text as TextIcon,
  TextalignLeft,
  TextBlock,
  TextBold,
  TextItalic,
  TextUnderline,
  TickSquare,
  Trash,
  Warning2
} from 'iconsax-react'
import {v4 as uuidv4} from 'uuid'
import {useDebounce} from 'react-use'
import {Field, NextUIFieldProps} from '@/src/utils/shared/FormManagerFormNextUI'
import {
  configFields,
  configFieldsGrouping,
  defaultConfiguration,
  defaultTypographyGroupElements,
  previewText
} from '@/components/editor/typography/config'
import {
  GoogleFont,
  TypographyElement,
  TypographyFormProps,
  TypographyGroup,
  TypographyModalProps
} from '@/components/editor/typography/types'
import {Helpers, useStyleCoreTypography} from '@/components/shared/StyleCore'

const TypographyFormListCreate = ({onCreate, buttonText, children, disabled}: { onCreate: () => void, buttonText: string, children: JSX.Element, disabled: boolean | undefined }) => {
  return <Card
    isHoverable={!disabled}
    isPressable={!disabled}
    variant="flat"
    style={{width: '100%'}}
    onPress={onCreate}
  >
    <Grid.Container gap={2}>
      <Grid xs={1}>
        {children}
      </Grid>
      <Grid xs={11}>
        <Text b>{buttonText}</Text>
      </Grid>
    </Grid.Container>
  </Card>
}
const TypographyFormGroupListElement = ({element, onSelect}: { element: TypographyGroup, onSelect: (selected: TypographyGroup) => void }) => {
  return <Card
    isPressable
    isHoverable
    variant="shadow"
    style={{width: '100%'}}
    onPress={() => {
      onSelect(element)
    }}
  >
    <Grid.Container gap={3} alignItems="center">
      <Grid xs={12}>
        <Box1 size={24}/>
      </Grid>
      <Grid xs={12}>
        <div>
          <Text b>{element.name} {element.isDefault && <Badge color="primary">Default</Badge>}</Text>
          {element.description &&
              <><br/><Text size={'$sm'}>{element.description}</Text></>
          }
        </div>
      </Grid>
    </Grid.Container>
  </Card>
}
const TypographyFormGroupList = ({typographyGroups, onSelect, show}: { typographyGroups: TypographyGroup[], onSelect: (selected: TypographyGroup) => void, show: boolean}) => {
  if (!show) return null
  function createNewGroup() {
    onSelect({
      name: 'New Group',
      id: uuidv4(),
      description: '',
      isDefault: false,
      elements: [...defaultTypographyGroupElements]
    })
  }

  return <>
    <Text id="modal-title" size={24} style={{textAlign: 'center', width: '100%'}}>
      <TextIcon size={24} style={{marginRight: 5, paddingTop: 5}}/>
      StyleCore
      <Text b size={24}>
        Typography
      </Text>
    </Text>
    <Grid.Container gap={2}>
    {
      typographyGroups.map((group, k) =>
        <Grid xs={24} sm={12} key={k}>
          <TypographyFormGroupListElement
            key={k}
            element={group}
            onSelect={onSelect}/>
        </Grid>)
    }
    <Grid xs={24} sm={12}>
      <TypographyFormListCreate
        disabled={false}
        onCreate={createNewGroup}
        buttonText={'Create new Typography Group'}>
        <BoxAdd/>
      </TypographyFormListCreate></Grid>

  </Grid.Container>
  </>
}
const TypographyFormPreview = ({element, inModal}: { element: TypographyElement, inModal: boolean }) => {
  const [demoType, setDemoType] = useState<'text' | 'paragraph' | 'custom'>('text')
  const [demoFontDecoration, setFontDecoration] = useState({
    bold: false,
    italic: false,
    underline: false
  })
  const [demoText, setDemoText] = useState({...previewText})

  useGoogleFonts([[element.configuration.fonts.primary.name]])

  return <>
    <Card style={{position: 'absolute', top: inModal ? 180 : 235, left: inModal ? 60 : 50, zIndex: 2147483646, width: 310, borderRadius: 999}}>
      <Button.Group rounded size={'sm'}>
        <Button flat={demoType !== 'text'} onPress={() => setDemoType('text')}><TextIcon size={18} style={{paddingRight: 4}}/> Text</Button>
        <Button flat={demoType !== 'paragraph'} onPress={() => setDemoType('paragraph')}><TextalignLeft size={18} style={{paddingRight: 4}}/> Paragraph</Button>
        <Button flat={demoType !== 'custom'} onPress={() => setDemoType('custom')}><DocumentText size={18} style={{paddingRight: 4}}/> Custom</Button>
      </Button.Group>
    </Card>
    <Card style={{position: 'absolute', top: inModal ? 180 : 235, right: inModal ? 60 : 50, zIndex: 2147483646, width: 163, borderRadius: 999}}>
      <Button.Group rounded size={'sm'}>
        <Button flat={!demoFontDecoration.bold}
                onPress={() => setFontDecoration({...demoFontDecoration, bold: !demoFontDecoration.bold})}><TextBold
          size={18}/></Button>
        <Button flat={!demoFontDecoration.italic} onPress={() => setFontDecoration({
          ...demoFontDecoration,
          italic: !demoFontDecoration.italic
        })}><TextItalic size={18}/></Button>
        <Button flat={!demoFontDecoration.underline} onPress={() => setFontDecoration({
          ...demoFontDecoration,
          underline: !demoFontDecoration.underline
        })}><TextUnderline size={18}/></Button>
      </Button.Group>
    </Card>
    {demoType === 'custom' && <Card variant={'flat'} style={{
      position: 'absolute',
      top: inModal ? 290 : 357,
      left: '40%',
      zIndex: 2147483646,
      width: 310,
      borderRadius: 999
    }}>
        <Input size={'sm'} placeholder="Enter demo text" onChange={(e) => {
          setDemoText({...demoText, custom: e.target.value})
        }}/>
    </Card>}
    <Card style={{
      paddingTop: 50,
      paddingBottom: 50
    }}>
      <Text style={{
        ...element.configuration,
        width: '100%',
        textAlign: 'center',
        paddingLeft: 25,
        paddingRight: 25,
        fontWeight: (!demoFontDecoration.bold ? (element?.configuration?.fontWeight ?? 'normal') : (Number(element?.configuration?.fontWeight ?? 400) + 300)),
        fontFamily: `'${element.configuration.fonts.primary.name}', '${element.configuration.fonts.fallback.name}', sans-serif`,
        fontStyle: (demoFontDecoration.italic ? 'italic' : 'normal'),
        textDecoration: (demoFontDecoration.underline ? 'underline' : 'none')
      }}>{demoText[demoType]}</Text>
      <hr style={{padding: 0, margin: 0}}/>
    </Card>
  </>
}
const TypographyFieldFontSelection = ({fonts, onSelect}: { fonts: GoogleFont[], onSelect: (selected: GoogleFont) => void }) => {
  const [selectedFont, setSelectedFont] = useState<GoogleFont | undefined>(undefined)
  const [fontsFiltered, setFontsFiltered] = useState<GoogleFont[]>(fonts)

  useEffect(() => {
    if (typeof selectedFont !== 'undefined')
      onSelect(selectedFont)
  }, [selectedFont])

  return <Grid.Container gap={0.2}>
    <Grid xs={24} style={{width: '100%', paddingTop: 10}}>
      <Input
        clearable
        fullWidth
        style={{width: '100%'}}
        onChange={(e) => {
          const value = e.target.value
          if (value === '') {
            setFontsFiltered(fonts)
          } else {
            setFontsFiltered(fonts.filter((font) => font.name.toLowerCase().includes(value.toLowerCase())))
          }
        }
        }
        color="primary"
        placeholder="Search Fonts"
        contentLeft={<SearchNormal/>}
      />
    </Grid>
    <Grid xs={24}>
      <div style={{
        height: 300,
        padding: 10,
        overflowY: 'scroll'
      }}>
        <Grid.Container gap={2}>
          {
            fontsFiltered.map((font, k) => <>
              <Grid xs={24}>
                <Card
                  isPressable
                  isHoverable
                  variant="bordered"
                  style={{width: '932px'}}
                  onPress={() => {
                    setSelectedFont(font)
                  }}
                >
                  <Card.Body>
                    <Text>{font.name}</Text>
                    <Text color="secondary">{font.category}</Text>
                  </Card.Body>
                </Card>
              </Grid>
            </>)
          }
        </Grid.Container>
      </div>
    </Grid>
  </Grid.Container>
}
const TypographyFieldFont = ({
                               element,
                               onChange
                             }: { element: TypographyElement, onChange: (selected: TypographyElement) => void }) => {
  const [selectedFont, setSelectedFont] = useState(element.configuration.fonts.primary)
  const [openFontSelection, setOpenFontSelection] = useState(false)
  const [selectedFallbackFont, setSelectedFallbackFont] = useState(element.configuration.fonts.fallback)
  const [loadingFontList, setLoadingFontList] = useState(true)
  const [menuItems, setMenuItems] = useState<GoogleFont[]>([])
  const [fetchError, setFetchError] = useState(false)
  useEffect(() => {
    if (selectedFont) {
      const font = menuItems.find((item) => item.name === selectedFont.name)
      if (font) {
        onChange({
          ...element,
          configuration: {
            ...element.configuration,
            fonts: {
              ...element.configuration.fonts,
              primary: {
                ...element.configuration.fonts.primary,
                name: font.name
              }
            }
          }
        })
      }
    }
  }, [selectedFont, selectedFallbackFont])
  useEffect(() => {
    setLoadingFontList(true)

    async function fetchFonts() {
      const response = await fetch('/api/googleFontsList')
      const data = await response.json()
      if (!response.ok || data.error) {
        setFetchError(true)
        setLoadingFontList(false)
        return
      }
      const items = data.items.map((item: any, index: number) => {
        return {
          name: item.family,
          category: item.category,
          variants: item.variants.length,
          index
        }
      })
      setMenuItems(items)
      setLoadingFontList(false)
    }
    fetchFonts()
  }, [])
  const fontButtonTriggerName = selectedFont.name ?? 'Select a Primary Font'
  return <TypographyFieldWrapper title={'Font'} icon={<TextIcon size={'26'} variant="Bulk"/>}>
    <Grid.Container gap={.5}>
      <Grid xs={24}>
        <Button
          flat
          disabled={loadingFontList || fetchError}
          style={{width: '100%'}}
          onPress={() => setOpenFontSelection(!openFontSelection)}>
          {loadingFontList ? <Loading/> : <>{fontButtonTriggerName}<ArrowDown3 size={16} variant="Bulk"/> </>}
        </Button>

      </Grid>
      {
        fetchError && <Grid xs={12}>
              <Card style={{padding: '15px'}} variant={'flat'}>
                  <Text color={'$red600'}><Warning2/> Error while fetching fonts. </Text>
              </Card>
        </Grid>
      }
      <Grid xs={12}>
        {(!loadingFontList && openFontSelection) &&
            <TypographyFieldFontSelection
                fonts={menuItems}
                onSelect={(selected) => {
                  setSelectedFont(selected)
                  setOpenFontSelection(false)
                }}/>}
      </Grid>
      <Grid xs={12}>
        <Card variant={'bordered'} style={{padding: 10}} color={'primary'}>
          <Grid.Container gap={.5} alignItems={'center'} alignContent={'center'}>
            <Grid xs={1}>
              <Text color="primary"><Information variant={'Bulk'} size="20px"/></Text>
            </Grid>
            <Grid xs={11}>
              <Text size={'$sm'} color={'$gray600'}>
                All available fonts can be found on &nbsp;
                <Link href="https://fonts.google.com/" isExternal>
                  Google Fonts
                </Link>
              </Text>
            </Grid>
          </Grid.Container>
        </Card>
      </Grid>
    </Grid.Container>
  </TypographyFieldWrapper>
}
const TypographyFieldWrapper = ({children, title, description, icon}: { children: React.ReactNode, title: string, description?: string, icon: any }) => {
  return <Card style={{padding: 25}} variant="bordered">
    <Text h3>{icon} {title}</Text>
    {description && <Text size={'$sm'}>{description}</Text>}
    {children}
  </Card>
}

function EditElementName(props: {show: boolean, onChange: (e: ChangeEvent<FormElement>) => void, selectedElement: TypographyElement, onPress: () => void }) {
  if (!props.show) return <></>
  return <Grid xs={24}>
    <Grid.Container gap={2} style={{paddingTop: '30px'}}>
      <Grid>
        <Input
          onChange={props.onChange}
          initialValue={props.selectedElement.name}
          bordered
          labelPlaceholder="Name"
          color="default"/>
      </Grid>
      <Grid>
        <Button
          auto
          onPress={props.onPress}>
          Done
        </Button>
      </Grid>
    </Grid.Container>
  </Grid>
}

const TypographyFormElement = ({element, onChange, onBack, onDelete, show, inModal}: { element: TypographyElement | null, onChange: (selected: TypographyElement) => void, onBack: () => void, onDelete: () => void, show: boolean, inModal: boolean}) => {
  const [selectedElement, setSelectedElement] = useState<TypographyElement | null>(element)
  const [showEditName, setShowEditName] = useState(false)

  useEffect(() => {
    if (selectedElement === null) return
    onChange(selectedElement)
  }, [selectedElement])

  useEffect(() => {
    setSelectedElement(element)
  }, [element])

  if (!show || element === null || selectedElement === null) return null

  return <div style={{padding: 20, width: '100%'}}>
    <Grid.Container gap={2}>
      <Grid xs={24}>
        <Grid.Container gap={2}>
          <Grid xs={24}>
            <Button style={{width: '10px', marginRight: '10px'}} onPress={onBack} icon={<ArrowLeft/>}>Back</Button>
            <Button
              style={{width: '10px', marginRight: '10px'}}
              flat
              icon={<Edit2/>}
              onPress={() => {
                setShowEditName(!showEditName)
              }}>
              Edit Name
            </Button>
            <Button style={{width: '10px'}} flat onPress={() => {
              onDelete()
              onBack()
            }} color={'error'} icon={<Trash/>}>Delete</Button>
          </Grid>
          <EditElementName
            selectedElement={selectedElement}
            show={showEditName}
            onChange={(e) => {
              setSelectedElement({
                ...selectedElement,
                name: e.target.value
              })
            }}
            onPress={() => {
              setShowEditName(false)
            }}
          />
          <Grid xs={24}>
            <TextBlock width={40} size={30}/>
            <Text b h3 style={{paddingLeft: 10}}>{selectedElement.name} <code>{Helpers.selectors.getTypographyBaseSelector(selectedElement)}</code></Text>
          </Grid>
        </Grid.Container>
      </Grid>
      <Grid xs={24}>
        {
          !showEditName && <TypographyFormPreview inModal={inModal} element={selectedElement}/>
        }
      </Grid>
      <Grid xs={24} style={{width: '100%'}}>
        <Collapse.Group shadow style={{width: '100%'}}>
          <Collapse title={'Font'} subtitle={'Change both primary and fallback fonts'}>
            <Grid.Container gap={2}>
              <Grid xs={12}>
                <TypographyFieldFont element={selectedElement} onChange={(element) => {
                  setSelectedElement(element)
                }}/>
              </Grid>
            </Grid.Container>
          </Collapse>
          {
            configFieldsGrouping.map((group, index) => {
              return <Collapse key={index} title={group.name} subtitle={group.description}>
                <Grid.Container gap={2}>
                  {
                    group.fields.map((field, index) => {
                      return <Grid key={index} xs={12} md={4} lg={3}>
                        <Field enableInheritanceVisuals={false} field={configFields[field] as NextUIFieldProps} wrapWithGrid={false} onChange={(e) => {
                          setSelectedElement({
                            ...selectedElement,
                            configuration: {
                              ...selectedElement.configuration,
                              [field]: e
                            }
                          })
                        }} initialValue={selectedElement.configuration[field as keyof typeof selectedElement.configuration]}/>
                      </Grid>
                    })
                  }
                </Grid.Container>
              </Collapse>
            })
          }
        </Collapse.Group>
      </Grid>
    </Grid.Container>
  </div>
}
export const TypographyFormElementListElement = ({element, onSelect, disabled}: { element: TypographyElement, onSelect: (selected: TypographyElement) => void, disabled?: boolean}) => {
  useGoogleFonts([[element.configuration.fonts.primary.name]])
  return <Card
    isPressable={!disabled}
    isHoverable={!disabled}
    variant={disabled ? 'bordered' : 'shadow'}
    style={{width: '100%'}}
    onPress={() => {
      onSelect(element)
    }}>
    <Grid.Container gap={2}>
      <Grid xs={24}>
        <Text
          b
          style={{
            ...element.configuration,
            fontFamily: `'${element.configuration.fonts.primary.name}', '${element.configuration.fonts.fallback.name}', sans-serif`
        }}>
          {element.name}
        </Text>
      </Grid>
    </Grid.Container>
  </Card>
}

function EditGroupInfo(props: { show: boolean, onChangeName: (e: ChangeEvent<FormElement>) => void, groupState: TypographyGroup | null, onChangeDescription: (e: ChangeEvent<FormElement>) => void, onPressFinish: () => void }) {
  if (!props.show || props.groupState === null) return <></>
  return <Grid xs={24}>
    <Grid.Container gap={2} style={{paddingTop: '30px'}}>
      <Grid>
        <Input
          onChange={props.onChangeName}
          initialValue={props.groupState?.name}
          bordered
          labelPlaceholder="Name"
          color="default"/>
      </Grid>
      <Grid>
        <Input
          bordered
          onChange={props.onChangeDescription}
          initialValue={props.groupState?.description}
          labelPlaceholder="Description"
          color="default"/>
      </Grid>
      <Grid>
        <Button
          auto
          onPress={props.onPressFinish}>
          Done
        </Button>
      </Grid>
    </Grid.Container>
  </Grid>
}

const TypographyFormElementList = ({group, onSelect, onChange, onBack, onDelete, show, onMakeDefault}: { group: TypographyGroup | null, onSelect: (selected: TypographyElement) => void, onChange: (selected: TypographyGroup) => void, onBack: () => void, onDelete: () => void, show: boolean, onMakeDefault: () => void}) => {
  const [groupState, setGroupState] = useState<TypographyGroup | null>(group)
  const [showEditGroup, setShowEditGroup] = useState(false)

  function createNewElement() {
    const newElement = {
      name: 'New Element',
      id: uuidv4(),
      ...defaultConfiguration
    } as TypographyElement
    if (groupState === null) return
    setGroupState({
      ...groupState,
      elements: [...groupState.elements, newElement]
    } as TypographyGroup)
    onSelect(newElement)
  }

  useEffect(() => {
    setGroupState(group)
  }, [group])

  useDebounce(() => {
    if (groupState === null) return
    onChange(groupState)
  }, 150, [groupState])

  if (!show || group === null) return null

  return <div style={{padding: 20}}>
    <Grid.Container gap={1}>
      <Grid xs={24}>
        <Button
          style={{width: '10px'}}
          onPress={onBack}
          icon={<ArrowLeft/>}>
          Back
        </Button>
        <Button
          style={{width: '10px', marginLeft: '10px'}}
          onPress={group.isDefault ? undefined : onMakeDefault}
          flat={!group.isDefault}
          shadow={group.isDefault}
          icon={group.isDefault ? <TickSquare/> : <MinusSquare/>}>
          {group.isDefault ? 'Default' : 'Make Default'}
        </Button>
        <Button
          style={{width: '10px', marginLeft: '10px'}}
          flat
          icon={<Edit2/>}
          onPress={() => {
            setShowEditGroup(!showEditGroup)
          }}>
          Edit Group
        </Button>
        <Button
          style={{width: '10px', marginLeft: '10px'}}
          flat
          onPress={() => {
            onDelete()
            onBack()
          }}
          color={'error'}
          icon={<Trash/>}
          disabled={group.isDefault}>
          Delete
        </Button>
    </Grid>
      <EditGroupInfo
        show={showEditGroup}
        onChangeName={(e) => {
          if (groupState === null) return
          setGroupState({
            ...groupState,
            name: e.target.value
          })
        }}
        groupState={groupState}
        onChangeDescription={(e) => {
          if (groupState === null) return
          setGroupState({
            ...groupState,
            description: e.target.value
          })
        }}
        onPressFinish={() => {
          setShowEditGroup(false)
        }}
      />
    <Grid xs={24}>
      <Text h2>
        {groupState?.name}
        {group.isDefault && <Text><Badge color="primary">Default</Badge></Text>}
      </Text>
    </Grid>
    {
      groupState?.elements.map((element, k) =>
        <Grid sm={12} key={k}>
          <TypographyFormElementListElement element={element} onSelect={onSelect}/>
        </Grid>
      )
    }
    <Grid xs={24} sm={12}>
      <TypographyFormListCreate
        disabled={false}
        onCreate={createNewElement}
        buttonText={'Create new'}>
        <Add/>
      </TypographyFormListCreate>
    </Grid>
  </Grid.Container>
  </div>
}
const TypographyForm = ({typographyGroups, onChange, inModal}: TypographyFormProps) => {
  const [groups, setGroups] = useState<TypographyGroup[]>(typographyGroups)
  const [selectedGroup, setSelectedGroup] = useState<TypographyGroup | null>(null)
  const [selectedElement, setSelectedElement] = useState<TypographyElement | null>(null)
  const [issuingGQLRequest, setIssuingGQLRequest] = useState('inactive')
  useEffect(() => {
    if (selectedGroup === null) return
    let found = false
    const newGroups = groups.map((group) => {
      if (group.id === selectedGroup?.id) {
        found = true
        return selectedGroup
      } else {
        return group
      }
    })

    if (!found) {
      setGroups([...groups, selectedGroup])
    } else {
      setGroups(newGroups)
    }

  }, [selectedGroup])

  useEffect(() => {
    if (selectedGroup === null) return
    if (selectedElement === null) return

    const element = selectedGroup?.elements.findIndex((element) => element.id === selectedElement?.id)
    let elements = selectedGroup.elements

    if (element === undefined || element === -1) {
      elements = [...selectedGroup.elements, selectedElement] as TypographyElement[]
    } else {
      elements = selectedGroup.elements.map((element) => {
        if (element.id === selectedElement?.id) {
          return selectedElement
        } else {
          return element
        }
      })
    }

    setSelectedGroup({
      ...selectedGroup,
      elements: elements as TypographyElement[]
    })

  }, [selectedElement])

  const handlers = {
    group: {
      back: () => setSelectedGroup(null),
      select: setSelectedElement,
      change: setSelectedGroup,
      delete: () => {
        const groupsNew = groups.filter((g) => g.id !== selectedGroup?.id)
        setGroups(groupsNew)
        setSelectedGroup(null)
      },
      makeDefault: () => {
        const groupsNew = groups.map((g) => {
          if (g.id === selectedGroup?.id) {
            return {
              ...g,
              isDefault: true
            }
          } else {
            return {
              ...g,
              isDefault: false
            }
          }
        })
        setGroups(groupsNew)
        setSelectedGroup(groupsNew.filter((g) => g.id === selectedGroup?.id)[0])
      }
    },
    element: {
      back: () => setSelectedElement(null),
      change: setSelectedElement,
      delete: () => {
        if (selectedGroup === null) return
        const elements = selectedGroup.elements.filter((e) => e.id !== selectedElement?.id)
        setSelectedGroup({
          ...selectedGroup,
          elements
        })
      }
    }
  }

  return <>
    <TypographyFormGroupList
      show={selectedGroup === null}
      typographyGroups={groups}
      onSelect={setSelectedGroup}
    />
    <TypographyFormElementList
      group={selectedGroup}
      show={selectedGroup !== null && selectedElement === null}
      onSelect={handlers.group.select}
      onDelete={handlers.group.delete}
      onChange={handlers.group.change}
      onBack={handlers.group.back}
      onMakeDefault={handlers.group.makeDefault}
    />
    <TypographyFormElement
      show={selectedGroup !== null && selectedElement !== null}
      element={selectedElement}
      onChange={handlers.element.change}
      onDelete={handlers.element.delete}
      onBack={handlers.element.back}
      inModal={inModal}
    />
    <Button size="lg" disabled={issuingGQLRequest !== 'inactive'} style={{
      width: '100%',
    }} onPress={async () => {
      setIssuingGQLRequest('issued')
      await onChange(groups)
      setIssuingGQLRequest('cooldown')
      setTimeout(() => {
        setIssuingGQLRequest('inactive')
      }, 1500)
    }}>
      {
        issuingGQLRequest === 'issued' && <Spinner/>
      }
      {
        issuingGQLRequest === 'cooldown' && 'Changes saved'
      }
      {
        issuingGQLRequest === 'inactive' && 'Save changes'
      }
    </Button>
  </>

}
export const TypographyModal = ({isOpen = false, ...props}: TypographyModalProps) => {
  const [siteTypographyGroups, updateTypographyGroups] = useStyleCoreTypography(true)

  const TypographyModalPrepared = () => {
    return <TypographyForm typographyGroups={siteTypographyGroups} inModal={props.inModal} onChange={updateTypographyGroups}/>
  }
  if (!props.inModal) {
    return <div style={{padding: 15}}>
      <TypographyModalPrepared/>
    </div>
  }
  return <Modal
    closeButton
    aria-labelledby="modal-title"
    open={isOpen}
    onClose={() => {
      props.onClose()
    }}
    width={'1200px'}
  >
    <Modal.Body>
      <TypographyModalPrepared/>
    </Modal.Body>
  </Modal>
}
