import {RichtextWithVariables} from './shared/SectionsWithVariables'
import Section from './shared/Section'
import SchemaVideo from './shared/SchemaVideo'
import SectionVideo from './shared/SectionVideo'
import SectionButton from './shared/SectionButton'
import SectionText from './shared/SectionText'

export default function VideoWide(props) {
  const {
    title,
    subtitle,
    intro,
    video,
    outro,
    button,
    color_scheme,
    className,
    css_overrides,
    color_scheme_id_override,
    lucid_page_section_id, meta,
    cms,
  } = props
  return (
    <Section
      cms={cms}
      css_overrides={css_overrides} color_scheme_id_override={color_scheme_id_override} meta={meta} lucid_page_section_id={lucid_page_section_id}
      className={className + ' section videowide-section stack'}
      colorSchemeId={color_scheme}
      name="videowide"
    >
      <SchemaVideo
        name={title}
        description={video?.caption}
        thumbnailUrl={video?.poster}
        contentUrl={video?.src}
        embedUrl={video?.src}
      />
      <div className="section-container">
        <div className="section-content">
          <div className="section-heading">
            <h1>
              <span className="title heading-1">
                <SectionText name="title" text={title} cms={cms}/>
              </span>
              <span className="subtitle heading-2">
                <SectionText name="subtitle" text={subtitle} cms={cms}/>
              </span>
            </h1>
          </div>
          <div className="section-description">
            <div className="row">
              <div className="column">
                <div className="section-richtext paragraph">
                  <RichtextWithVariables cms={cms} name="intro" richtext={intro}/>
                </div>
              </div>
            </div>
          </div>
          <div className="video-wrapper">
            <SectionVideo
              title={title}
              src={video?.src}
              poster={video?.poster}
              size={video?.size}
            />
          </div>
          <div className="row">
            <div className="column">
              <div
                className="default section-richtext description video-caption"
                itemprop="description"
              >
                <RichtextWithVariables
                  name="description"
                  richtext={video?.caption}
                  cms={cms}
                />
              </div>
            </div>
          </div>
          <div className="section-outro">
            <div className="row">
              <div
                className="column section-richtext"
              >
                <RichtextWithVariables
                  name="outro"
                  richtext={outro}
                  cms={cms}
                />
              </div>
            </div>
          </div>

          <div className="button-container">
            {button?.label && (
              <SectionButton
                href={button.url}
                label={button.label}
                cms={cms}
                name="button"
              />
            )}
          </div>
        </div>
      </div>
      <div className="section-background"></div>
    </Section>
  )
}

VideoWide.StyleCore = {
  config: {},
  target: {
    componentName: Object.keys({VideoWide})[0].toLowerCase(),
  },
  css: ''
}
