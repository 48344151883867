import {usePlugin, useScreenPlugin} from '@einsteinindustries/tinacms'
import {PageManagerPlugin} from './PageManager'
import WorkflowTasks from './Toolbar/WorkflowTasks'
import PageLiveToDevToggle from './Toolbar/PageLiveToDevToggle'
import ProxyConfigButton from './Toolbar/ProxyConfigButton'
import {TypographyModal} from '../editor/typography/TypographyModal'
import {HambergerMenu, LinkSquare, Text} from 'iconsax-react'
import {RouteManager} from './RouteManager'
import {MenuManager} from './MenuManager'
import React, {useContext} from 'react'
import {LucidSiteContext} from '../../state/site/Store'

export const usePlugins = (pageBuild, site, page) => {
  const [{pages},] = useContext(LucidSiteContext)
  const WorkflowTasksPlugin = () => <WorkflowTasks site={site} pages={pages} pageBuild={pageBuild}/>
  const PageLiveToDevTogglePlugin = () => (
    <PageLiveToDevToggle page={page}/>
  )

  const ProxyConfigPlugin = () => (
    <ProxyConfigButton site={site} page={page}/>
  )

  const proxyConfigPlugin = {
    __type: 'toolbar:widget',
    name: 'proxyConfigSync',
    weight: 10,
    component: ProxyConfigPlugin,
  }

  const workflowTasksPlugin = {
    __type: 'toolbar:widget',
    name: 'workflowTasks',
    weight: 20,
    component: WorkflowTasksPlugin,
  }

  const pageTogglePlugin = {
    __type: 'toolbar:widget',
    name: 'pageLiveToDevToggle',
    weight: 30,
    component: PageLiveToDevTogglePlugin,
  }

  // Screen plugin
  useScreenPlugin(PageManagerPlugin)

  useScreenPlugin({
    name: 'Routes',
    Component: () => <RouteManager siteId={Number(site.id)}/>,
    Icon: () => {
      return <LinkSquare variant="Bulk" />
    },
    layout: 'fullscreen',
  })

  useScreenPlugin({
    name: 'Typography',
    Component: () => <TypographyModal inModal={false} onClose={() => {}} />,
    Icon: () => {
      return <Text variant="Bulk" />
    },
    layout: 'fullscreen',
  })

  useScreenPlugin({
    name: 'Menus',
    Component: MenuManager,
    Icon: () => {
      return <HambergerMenu variant="Bulk" />
    },
    layout: 'fullscreen',
  })

  // usePlugin(UserManager)

  // Toolbar plugin
  usePlugin(proxyConfigPlugin)
  usePlugin(workflowTasksPlugin)
  usePlugin(pageTogglePlugin)
}

export * from './MenuManager'
export * from './RouteManager'
export * from './PageManager'
export * from './ColorSchemeManager/ColorScheme'
