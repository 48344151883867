import Section from '../shared/Section'
import StyledHeading from '../shared/StyledHeading'
import {InlineTextWithVariables, RichtextWithVariables} from '../shared/SectionsWithVariables'
import {MENU_ITEM_BLOCKS} from './config'
import styled from 'styled-components'
import LucidInlineBlocks from '../shared/LucidInlineBlocks'
import SectionButton from '../shared/SectionButton'


const StyledInlineBlocks = styled(LucidInlineBlocks)`
  display: flex;
  flex-flow: row wrap;
  justify-content: space-between;
`

export default function MenuDemo(props) {
  const {
    title,
    subtitle,
    intro,
    // items,
    outro,
    button,
    color_scheme,
    className,
    css_overrides,
    color_scheme_id_override, lucid_page_section_id, meta,
    cms,
  } = props
  return (
    <Section
      cms={cms}
      css_overrides={css_overrides} color_scheme_id_override={color_scheme_id_override} meta={meta} lucid_page_section_id={lucid_page_section_id}
      className={className}
      name="menudemo"
      colorSchemeId={color_scheme}
    >
      <StyledHeading>
        <h2>
          <InlineTextWithVariables cms={cms} name="title" value={title} />
        </h2>
        <InlineTextWithVariables cms={cms} name="subtitle" value={subtitle} />
      </StyledHeading>
      <RichtextWithVariables
        name="intro"
        richtext={intro}
        cms={cms}
      />
      <StyledInlineBlocks
        itemProps={props}
        name="menu_items"
        blocks={MENU_ITEM_BLOCKS}
      />
      <RichtextWithVariables
        name="outro"
        richtext={outro}
        cms={cms}
      />
      <SectionButton name='button' href={button?.href} label={button?.label} cms={cms} />
    </Section>
  )
}
