import NextImage, {ImageProps} from 'next/image'

const LucidImage = (props: ImageProps & {className?: string}) => {
    const src = props.src as string | undefined
    const readUrl = process.env.NEXT_PUBLIC_IMGIX_READ_URL

    if (typeof src === 'undefined') return null

    if (readUrl && src.startsWith(readUrl)) {
        const {pathname} = new URL(src)
        return NextImage({...props, src: pathname})
    } else if (src && src.charAt(0) === '/') {
        return NextImage({...props})
    }
    return NextImage({...props, unoptimized: true})
}

export default LucidImage
