import {UnitOptions} from '../options/UnitOptions'

export const PaddingFields = {
    createFields(defaults = {}) {
        let defaultFields = {
            unit: 'px',
            topPadding: 0,
            rightPadding: 0,
            bottomPadding: 0,
            leftPadding: 0,
        }
        defaultFields = Object.assign(defaultFields, defaults)
        return {
            padding: {
                content: {
                    unit: {
                        content: 'select',
                        default: defaultFields.unit,
                        options: UnitOptions
                    },
                    topPadding: {
                        content: 'number',
                        default: defaultFields.topPadding
                    },
                    rightPadding: {
                        content: 'number',
                        default: defaultFields.rightPadding,
                    },
                    bottomPadding: {
                        content: 'number',
                        default: defaultFields.bottomPadding,
                    },
                    leftPadding: {
                        content: 'number',
                        default: defaultFields.leftPadding,
                    }
                }
            }
        }
    },
    createPartialCSS(prefix = '') {
        return `
            padding-top: {${prefix}padding.topPadding}{${prefix}padding.unit};
            padding-right: {${prefix}padding.rightPadding}{${prefix}padding.unit};
            padding-bottom: {${prefix}padding.bottomPadding}{${prefix}padding.unit};
            padding-left: {${prefix}padding.leftPadding}{${prefix}padding.unit};
        `
    }
}