import {createContext, PropsWithChildren, useReducer} from 'react'
import {Dispatcher} from '@/src/state/site/Store'
import reducer from '@/src/state/site/reducer'
import {TypographyGroup, TypographyGroupMap} from '@/components/editor/typography/types'
import {StyleCoreMap, StyleCoreRenderedMap} from '@/components/shared/StyleCore'

export type StyleCoreState = {
  StyleCore: {
    map: StyleCoreMap,
    typography: {
      map: TypographyGroupMap
    },
    rendered: {
      map: StyleCoreRenderedMap
    }
  }
}

const initialStyleCoreState: StyleCoreState = {
  StyleCore: {
    map: new Map(),
    typography: {
      map: new Map()
    },
    rendered: {
      map: new Map()
    }
  }
}
type StyleCoreContextInterface = readonly [StyleCoreState, Dispatcher]

export const StyleCoreContext = createContext<StyleCoreContextInterface>([initialStyleCoreState, () => {}])

export const StyleCoreSiteProvider = ({children}: PropsWithChildren<any>) => {
  const [styleCoreState, dispatchStyleCore] = useReducer(reducer, initialStyleCoreState)

  return (
      <StyleCoreContext.Provider value={[styleCoreState as StyleCoreState, dispatchStyleCore]}>
        {children}
      </StyleCoreContext.Provider>
  )
}

