import SectionText from '../shared/SectionText'
import {SOCIAL_STRIPE_ITEM_BLOCKS} from './config'
import LucidInlineBlocks from '../shared/LucidInlineBlocks'
import StyledSection from '../shared/Section'

export default function SocialStripe(props) {
  const {
    title,
    color_scheme,
    className,
    css_overrides,
    color_scheme_id_override,
    lucid_page_section_id,
    cms,
    meta
  } = props
  return (
    <StyledSection
      cms={cms}
      css_overrides={css_overrides}
      color_scheme_id_override={color_scheme_id_override} meta={meta}
      lucid_page_section_id={lucid_page_section_id}
      className={className + ' site section stack socialstripe-section'}
      name="socialstripe"
      colorSchemeId={color_scheme}>
      <div className="section-container">
        <div className="section-content">
          <div className="section-widget">
            <div className="heading-1">
              <SectionText name="title" text={title} cms={cms}/>
            </div>
            <div className="accounts-and-link">
              <LucidInlineBlocks
                itemProps={props}
                name="socialstripe_items"
                blocks={SOCIAL_STRIPE_ITEM_BLOCKS}
                className="section-item-wrapper"
              />
            </div>
          </div>
        </div>
      </div>
    </StyledSection>
  )
}

SocialStripe.StyleCore = {
  config: {},
  target: {
    componentName: Object.keys({SocialStripe})[0].toLowerCase(),
  },
  css: ''
}
