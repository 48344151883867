import styled from 'styled-components'

export const Container = styled.div`
  overflow-y: auto;
  background-color: var(--tina-color-grey-1);
  height: 100%;
`

export * from './form'
export * from './dialog'
export * from './nav'
export * from './search'
export * from './table'
