import Section from './shared/Section'
import SectionRichtext from './shared/SectionRichtext'
import {RichtextWithVariables} from './shared/SectionsWithVariables'
import SectionText from './shared/SectionText'

export default function Intro(props) {
  const {
    title,
    subtitle,
    richtext,
    color_scheme,
    className,
    css_overrides,
    color_scheme_id_override,
    lucid_page_section_id,
    meta,
    cms
  } = props
  return (
    <Section
      cms={cms}
      css_overrides={css_overrides}
      color_scheme_id_override={color_scheme_id_override} meta={meta}
      lucid_page_section_id={lucid_page_section_id}
      className={className + ' section intro-section stack with-content'}
      name="intro"
      colorSchemeId={color_scheme}>
      <div className="section-container">
        <div className="section-content">
          <div className="section-heading">
            <h1>
              <div className="title heading-1">
                <SectionText name="title" text={title} cms={cms}/>
              </div>
              <div className="subtitle heading-2">
                <SectionText name="subtitle" text={subtitle} cms={cms}/>
              </div>
            </h1>
          </div>
          <div className="row">
            <div className="column">
              <div className="section-richtext paragraph">
                <RichtextWithVariables cms={cms} name="richtext" richtext={richtext}/>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="section-background"></div>
    </Section>

  )
}

Intro.StyleCore = {
  config: {},
  target: {
    componentName: Object.keys({Intro})[0].toLowerCase(),
  },
  css: ''
}
