import StyledHeading from '../shared/StyledHeading'
import {InlineTextWithVariables, RichtextWithVariables} from '../shared/SectionsWithVariables'
import SectionText from '../shared/SectionText'
import SectionImage from '../shared/SectionImage'
import Section from '../shared/Section'
import styled from 'styled-components'
import LucidInlineBlocks from '../shared/LucidInlineBlocks'
import SectionButton from '../shared/SectionButton'
import {useEffect, useState} from 'react'
import {BlocksControls, InlineBlocks} from '@einsteinindustries/react-tinacms-inline'
import {BoxTick, Edit, Eye, EyeSlash, ToggleOff, ToggleOn} from 'iconsax-react'
import {Badge, Button, Card, Grid, Switch, Text} from '@nextui-org/react'


const itemTemplates = {
  heroprefacequote: {
    label: 'Quote',
    defaultItem: {
      quote: 'quote',
    },
    itemProps: (item) => {
      return {quote: item.quote}
    },
    // TODO: Create a set of default fields
    fields: [
      {
        name: 'quote',
        label: 'Quote',
        component: 'html',
      }
    ],
  }
}

function ItemHTMLWrapper(props) {
  return  <div className="swiper-slide textblock-quote-carousel-item swiper-slide-duplicate swiper-slide-duplicate-active"
              style={{
                width: '532px'
              }}>
           <div className="blockquote-border-1-1 quote">
               {props.children}
           </div>
  </div>
}

function ItemWrapper(props) {
  if (!props.cms) return <ItemHTMLWrapper>{props.children}</ItemHTMLWrapper>
  return (
      <BlocksControls
          index={props.index}
          focusRing={{offset: 16, borderRadius: 4}}
          insetControls={false}
      >
        <ItemHTMLWrapper>{props.children}</ItemHTMLWrapper>
      </BlocksControls>
  )
}

const TEXT_QUOTE_ITEM_BLOCKS = {
  heroprefacequote: {
    Component({_name, index, data, cms}) {
      return (
          <>
            {cms && <div style={{
            position: 'relative',
            zIndex: 100,
            top: 39,
            left: 13,
            pointerEvents: 'none',
          }}>
            <Badge color="primary" size="md" rounded>
              Quote {index + 1}
            </Badge>
          </div> }
            <div style={{
              padding: '0 20px',
              paddingLeft: 90,
              backgroundColor: '#ffffffBC',
              borderRadius: 30,
            }}>
              <ItemWrapper index={index} cms={cms}>
                <div>
                </div>
                <p className={'paragraph'}><RichtextWithVariables richtext={data.quote} cms={cms} name={'quote'}/></p>
              </ItemWrapper>
            </div>
          </>
      )
    },
    template: itemTemplates.heroprefacequote,
  },
}

export default function TextblockQuoteCarousel(props) {
  const {
    title,
    subtitle,
    intro,
    column_1_title,
    column_1_image,
    column_1_icon,
    column_1_richtext,
    column_1_button,
    column_2_title,
    column_2_image,
    column_2_icon,
    column_2_richtext,
    column_2_button,
    outro,
    button,
    color_scheme,
    className,
    css_overrides,
    quotes,
    color_scheme_id_override, lucid_page_section_id, meta,
    cms
  } = props
  const [edit, setEdit] = useState(false)
  const [showIndex, setShowIndex] = useState(0)
  const [showChangeView, setShowChangeView] = useState(false)

  useEffect(() => {
    console.log('cms', cms)
    setEdit(cms)
  }, [cms])

  const testimonials = <>
    {
      cms && <div style={{
          position: 'absolute',
          zIndex: 100,
          top: edit || showChangeView ? -120 : -110,
          width: '600px',
          left: 0,
          transition: 'all 0.3s ease-in-out',
          opacity: edit || showChangeView ? 1 : 0,
          pointerEvents: edit ? 'all' : 'none',
        }}><Card style={{
          padding: 5,
          paddingLeft: 20,
            paddingRight: 20,
          borderRadius: 30,
        }}>
            <Grid.Container gap={2} justify={'left'} >
              <Grid xs={1} alignContent="center" alignItems={'center'} justify={'right'}>
                <Edit variant={edit ? 'Bold' : 'Outline'} size={64}/>
              </Grid>
              <Grid xs={5} alignItems={'center'} justify={'left'}>
                <div>
                  <Text h5>{edit ? 'You are in editing mode' : 'This stack can be edited'}</Text>
                  <Text p style={{
                    fontSize: 12,
                  }}>{edit ? 'Click on a quote to edit it' : 'Click on the button to edit this stack'}</Text>
                </div>
              </Grid>
              <Grid xs={6} alignItems={'center'} justify={'center'} style={{
                width: '100%',
              }}>
                <Button auto size="md" icon={<BoxTick size={18}/>} style={{
                  width: '100%',
                }} onPress={() => {
                  setEdit(!edit)
                }} color={!edit ? 'secondary' : 'primary'} rounded>
                  {!edit ? 'Edit Quote Stack' : 'Done'}
                </Button>
              </Grid>
            </Grid.Container>
        </Card>
        </div>
    }
    {
        edit && <>
          <LucidInlineBlocks itemProps={props} name={'quotes'} blocks={TEXT_QUOTE_ITEM_BLOCKS}/>
        </>
    }
    {
        (!edit || !cms) && <ItemWrapper cms={cms} index={showIndex % (quotes?.length ?? 0)}>
          {
            typeof quotes !== 'undefined' && quotes?.length > 0 && <span dangerouslySetInnerHTML={{__html: quotes[showIndex % quotes?.length]?.quote}} />
          }
        </ItemWrapper>
    }
    </>

  const bullets = !edit && <>
    <div className="swiper-pagination swiper-pagination-clickable swiper-pagination-bullets">
      {
        quotes?.map((quote, index) => {
          return <span
              key={index}
              onClick={() => setShowIndex(index)}
              className={`swiper-pagination-bullet ${index === showIndex % quotes?.length && 'swiper-pagination-bullet-active'}`}>
                              </span>
        })
      }
    </div>
    <div className="swiper-button-prev" onClick={() => setShowIndex(showIndex <= 0 ? 0 : showIndex - 1)}></div>
    <div className="swiper-button-next" onClick={() => setShowIndex(showIndex + 1)}></div>
  </>

  const TestimonialControlWrapper = () => {
    return <div>{testimonials}</div>
    if (cms) {
      return <BlocksControls index={1} label={false} customActions={[
        {
          name: 'Toggle View',
          icon: <Button auto size="xs" onPress={() => {setEdit(!edit)}} color={edit ? 'secondary' : 'primary'} rounded>
            {!edit ? 'Stacked' : 'Edit'}  View
          </Button>,
          onClick: () => setEdit(!edit)
        }
      ]} insetControls={false} focusRing={{
        offset: {
          x: 0,
          y: edit ? 0 : 50
        },
        nestedFocus: true
      }}>
        {testimonials}
      </BlocksControls>
    } else {
      return <div>{testimonials}</div>
    }
  }

  const fullCarousel =  <div className="testimonials" style={cms ? {
    borderRadius: !edit && showChangeView ? 30 : 0,
    border: !edit && showChangeView ? '3px solid #f5f5f5' : 'none',
    transition: 'all 0.3s ease-in-out',
  } : {}} onMouseEnter={() => {setShowChangeView(true)}} onMouseLeave={() => {setShowChangeView(false)}}>
    <div className="swiper-container swiper-container-initialized swiper-container-horizontal swiper-container-pointer-events">
      <div className="swiper-wrapper"
           style={{
             transitionDuration: '0ms',
             transform: 'translate3d(-1596px, 0px, 0px);'
           }}>
        {TestimonialControlWrapper()}
      </div>
      <span className="swiper-notification"></span>
    </div>
    {
      bullets
    }
  </div>

  return (
    <Section
      cms={cms}
      css_overrides={css_overrides}
      color_scheme_id_override={color_scheme_id_override} meta={meta}
      lucid_page_section_id={lucid_page_section_id}
      className={className + ' textblock-quote-carousel-section stack'}
      name="textblockquotecarousel"
      colorSchemeId={color_scheme}>
      <div className="section-container" >
        <div className="section-content textblock_quote_carousel_gutter_row_1 textblock_quote_carousel_gutter_column_1">
          <div className="section-heading">
            <h2>
              <span className="title heading-1"><RichtextWithVariables richtext={title} cms={cms} name={'title'}/></span>
              <span className="subtitle heading-2"><RichtextWithVariables richtext={subtitle} cms={cms} name={'subtitle'}/></span>
            </h2>
          </div>
          <div className="section-description">
            <div className="row">
              <div className="column">
                <div className="section-richtext paragraph">
                  <RichtextWithVariables richtext={intro} cms={cms} name={'intro'}/>
                </div>
              </div>
            </div>
          </div>
          <div className="row content-row section-richtext left-on-top">
            <div className="column column-1">
              <h3 className="heading-3"><RichtextWithVariables richtext={column_1_title} cms={cms} name={'column_1_title'}/></h3>
              <div className="image">
                <SectionImage src={column_1_image?.src} name="column_1_image" alt={column_1_image?.alt} cms={cms}/>
                {
                  column_1_icon && <RichtextWithVariables richtext={column_1_icon} cms={cms} name={'column_1_icon'}/>
                }
              </div>

              <div className="section-richtext" >
                <p className={'paragraph'}><RichtextWithVariables richtext={column_1_richtext} cms={cms} name={'column_1_richtext'}/></p>
              </div>
              <div className="sc-show-inline-carousel section-richtext">
                {
                  fullCarousel
                }
              </div>
              {
                column_1_button && <div className="column-1-button">
                    <SectionButton cms={cms} href={column_1_button?.url} name={'column_1_button'} label={column_1_button?.label}/>
                  </div>
              }
            </div>
            <div className="column column-2">
              <h3 className="heading-3"><RichtextWithVariables richtext={column_2_title} cms={cms} name={'column_2_title'}/></h3>
              <div className="image">
                <SectionImage src={column_2_image?.src} name="column_2_image" alt={column_2_image?.alt} cms={cms}/>
                {
                  column_2_icon && <RichtextWithVariables richtext={column_2_icon} cms={cms} name={'column_2_icon'}/>
                }
              </div>
              <div className="section-richtext paragraph">
                <p><RichtextWithVariables richtext={column_2_richtext} cms={cms} name={'column_2_richtext'}/></p>
              </div>

              {
                column_2_button && <div className="column-2-button">
                    <SectionButton cms={cms} href={column_2_button?.url} name={'column_2_button'} label={column_2_button?.label}/>
                  </div>
              }
            </div>
          </div>
          <div className="sc-show-full-width-carousel section-richtext">
            {
              fullCarousel
            }
          </div>

          {
            outro && <div className="section-outro">
                <div className="row">
                  <div className="column section-richtext paragraph">
                    <RichtextWithVariables richtext={outro} cms={cms} name={'outro'}/>
                  </div>
                </div>
              </div>
          }

          {
            button && <div className="section-button-centering">
                <SectionButton cms={cms} href={button?.url} name={'button'} label={button?.label}/>
              </div>
          }
        </div>
      </div>

      <div className="section-background"></div>
    </Section>
  )
}

TextblockQuoteCarousel.StyleCore = {
  config: {
    carousel: {
      content: {
        showFullWidth: {
          content: 'toggle',
          defaultValue: true,
        }
      }
    }
  },
  target: {
    componentName: Object.keys({TextblockQuoteCarousel})[0].toLowerCase(),
  },
  css: `
    {target} .sc-show-full-width-carousel {
      display: {carousel.showFullWidth ? block : none};
    }
    
    {target} .sc-show-inline-carousel {
      display: {carousel.showFullWidth ? none : block};
    }
  `
}


