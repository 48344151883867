// @ts-nocheck
import FilterBox from '../../../../shared/FilterBox'
import {Page, PageBuild} from '@/components/shared/types'

interface PagesFilterBoxProps {
  pages: PageBuild[]
  initializedStatusFilterValue: string
  filterStatusValue: string
  onChange: (value: string) => void
  fieldToBeFiltered: keyof PageBuild
}

const PagesFilterBox = ({
  pages,
  initializedStatusFilterValue,
  filterStatusValue,
  onChange,
  fieldToBeFiltered,
}: PagesFilterBoxProps) => {
  // flatmap in case of undefined status (nullish values would break the app)
  const options = new Set(
    pages.flatMap((page) =>
      typeof page[fieldToBeFiltered] === 'string'
        ? [page[fieldToBeFiltered]]
        : // @ts-ignore if User, we check the name
          page[fieldToBeFiltered]?.name ?? []
    )
  )
  return (
    <FilterBox
      defaultValue={initializedStatusFilterValue}
      options={[...options]}
      onChange={(value) => onChange(value)}
      value={filterStatusValue}
    />
  )
}

export default PagesFilterBox
