import React, {useState} from 'react'
import styled from 'styled-components'
import {DialogContent, DialogOverlay} from '@reach/dialog'

interface WizardLayoutProps {
  title: string
  showDialog: boolean
  closeDialog: () => void
  onSave: () => void
  children: React.ReactNode
  isNextAllowed?: boolean
}

const StyledDialogOverlay = styled(DialogOverlay)`
  z-index: 9000;
`

const StyledDialogContent = styled(DialogContent)`
  /* border-radius: 10px; */
  overflow: hidden;
  /* width: 70vw; */
  box-shadow: 0 3px 7px rgba(0, 0, 0, 0.3);
  padding: 0;
`

const WizardHeader = styled.div`
  border-bottom: 1px solid var(--tina-color-grey-3);
  position: sticky;
  top: 0;
  background-color: white;
  /* height: 50px; */
  display: flex;
  align-items: center;
  padding: var(--tina-padding-big);
`

const WizardFooter = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  position: sticky;
  bottom: 0;
  background-color: white;
  height: 50px;
  padding: 0 var(--tina-padding-big);
  border-top: 1px solid var(--tina-color-grey-3);
`

const StyledButton = styled.button`
  border: none;
  background-color: white;
  font-size: var(--tina-font-size-2);
  cursor: pointer;
  padding: 0;
`

const StyledPreviousButton = styled(StyledButton)`
  color: var(--tina-color-grey-5);
`

const StyledNextButton = styled(StyledButton)`
  color: var(--tina-color-primary);
`

const WizardLayout = ({
  title,
  showDialog,
  closeDialog,
  onSave,
  children,
  isNextAllowed = true,
}: WizardLayoutProps) => {
  const [currentPage, setCurrentPage] = useState(1)
  const totalPages = React.Children.count(children)
  const isFirstPage = currentPage === 1
  const isLastPage = currentPage === totalPages
  const currentChild = React.Children.toArray(children)[currentPage - 1]

  const handleNext = () => {
    if (isNextAllowed) {
      if (isLastPage) {
        onSave()
        reset()
      } else {
        setCurrentPage(currentPage + 1)
      }
    }
  }

  const handlePrevious = () => {
    if (isFirstPage) {
      reset()
      closeDialog()
    } else {
      setCurrentPage(currentPage - 1)
    }
  }

  const reset = () => {
    setCurrentPage(1)
  }

  return (
    <StyledDialogOverlay
      isOpen={showDialog}
      onDismiss={() => {
        reset()
        closeDialog()
      }}
    >
      <StyledDialogContent aria-labelledby={'label--create-page'}>
        <div>
          <WizardHeader>
            <h4 style={{margin: 0}}>
              {title} ({currentPage}/{totalPages})
            </h4>
          </WizardHeader>
          <div
            style={{
              padding: 'var(--tina-padding-big) var(--tina-padding-big)',
              height: '50vh',
              overflowY: 'auto',
              backgroundColor: 'var(--tina-color-grey-1)',
            }}
          >
            {currentChild}
          </div>
          <WizardFooter>
            <StyledPreviousButton onClick={handlePrevious}>
              {isFirstPage ? 'Cancel' : 'Previous'}
            </StyledPreviousButton>
            <StyledNextButton onClick={handleNext}>
              {isLastPage ? 'Save' : 'Next'}
            </StyledNextButton>
          </WizardFooter>
        </div>
      </StyledDialogContent>
    </StyledDialogOverlay>
  )
}

export default WizardLayout
