import React from 'react'
import Plyr from 'plyr-react'
import 'plyr-react/dist/plyr.css'

type Props = {
  title: string
  src: string
  poster: string
  size: number
}

export default function SectionVideo({title, src, poster, size}: Props) {
  const plyrVideo = src ? (
    <Plyr
      source={{
        type: 'video',
        title: title,
        sources: [
          {
            src: src,
            type: 'video/mp4',
            size: size,
          },
        ],
        poster: poster,
      }}
    />
  ) : null
  return <section>{plyrVideo}</section>
}
