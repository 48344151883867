import SectionHeading from './shared/SectionHeading'
import {
  InlineTextWithVariables,
  RichtextWithVariables,
} from './shared/SectionsWithVariables'
import Section from './shared/Section'
import SectionButton from './shared/SectionButton'
import SectionImage from './shared/SectionImage'

const PhotoSet = (props) => {
  const {
    title,
    subtitle,
    richtext, // intro
    image_1,
    image_1_label,
    image_1_caption,
    image_2,
    image_2_label,
    image_2_caption,
    description, // outro
    button,
    color_scheme,
    className,
    css_overrides,
    color_scheme_id_override,
    lucid_page_section_id,
    meta,
    cms,
  } = props
  return (
    <Section
      cms={cms}
      css_overrides={css_overrides}
      color_scheme_id_override={color_scheme_id_override}
      meta={meta}
      lucid_page_section_id={lucid_page_section_id}
      className={className + ' section photo-set-section stack'}
      name="photoset"
      colorSchemeId={color_scheme}
    >
      <div className="section-container">
        <div className="section-content">
          {title || subtitle ? (
            <div className="section-heading">
              <SectionHeading
                title={title}
                subtitle={subtitle}
                cms={cms}
              ></SectionHeading>
            </div>
          ) : null}

          {richtext ? (
            <div className="section-description">
              <div className="row">
                <div className="column">
                  <div className="section-richtext">
                    <RichtextWithVariables
                      name="richtext"
                      richtext={richtext}
                      cms={cms}
                    />
                  </div>
                </div>
              </div>
            </div>
          ) : null}

          <figure role="group">
            <div className="row images-row">
              <div className="column">
                <figure className="before">
                  <SectionImage
                    name="image_1"
                    src={image_1?.src}
                    alt={image_1?.alt}
                    cms={cms}
                  />
                  {image_1_label || image_1_caption ? (
                    <figcaption>
                      <strong>
                        <InlineTextWithVariables
                          name="image_1_label"
                          value={image_1_label}
                          cms={cms}
                        />
                      </strong>
                      {image_1_caption ? (
                        <span className="hide-for-small-only">
                          <span className="caption">
                            <InlineTextWithVariables
                              name="image_1_caption"
                              value={image_1_caption}
                              cms={cms}
                            />
                          </span>
                        </span>
                      ) : null}
                    </figcaption>
                  ) : null}
                </figure>
              </div>

              <div className="column">
                <figure className="after">
                  <SectionImage
                    name="image_2"
                    src={image_2?.src}
                    alt={image_2?.alt}
                    cms={cms}
                  />
                  {image_2_label || image_2_caption ? (
                    <figcaption>
                      <strong>
                        <InlineTextWithVariables
                          name="image_2_label"
                          value={image_2_label}
                          cms={cms}
                        />
                      </strong>
                      {image_2_caption ? (
                        <span className="hide-for-small-only">
                          <span className="caption">
                            <InlineTextWithVariables
                              name="image_2_caption"
                              value={image_2_caption}
                              cms={cms}
                            />
                          </span>
                        </span>
                      ) : null}
                    </figcaption>
                  ) : null}
                </figure>
              </div>
            </div>

            {description ? (
              <div className="row large-collapse">
                <div className="large-6 column">
                  <figcaption className="default description">
                    <RichtextWithVariables
                      cms={cms}
                      name="description"
                      richtext={description}
                    />
                  </figcaption>
                </div>
              </div>
            ) : null}
          </figure>
        </div>
      </div>

      <SectionButton
        name="button"
        href={button?.button_url}
        label={button?.button_text}
        cms={cms}
      />
      <div className="section-background"></div>
    </Section>
  )
}

PhotoSet.StyleCore = {
  config: {},
  target: {
    componentName: Object.keys({PhotoSet})[0].toLowerCase(),
  },
  css: '',
}

export default PhotoSet
