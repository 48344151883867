import React from 'react'

type StreetViewProps = {
  embedCode: string
  width?: string
  height?: string
}

const StreetView: React.FC<StreetViewProps> = ({embedCode, width: userWidth, height: userHeight}) => {
  const extractAttribute = (attribute: string, embedCode: string): string => {
    const match = embedCode.match(new RegExp(`${attribute}="([^"]+)"`))
    return match ? match[1] : ''
  }

  const url =  extractAttribute('src', embedCode)
  const width = userWidth || extractAttribute('width', embedCode)
  const height = userHeight || extractAttribute('height', embedCode)

  return (
    <div className="iframe-wrapper">
      <iframe src={url} width={width} height={height} style={{border: '0'}} allowFullScreen={true} loading="lazy" referrerPolicy="no-referrer-when-downgrade" />
    </div>
  )
}

export default StreetView
