import {BlocksControls} from '@einsteinindustries/react-tinacms-inline'
import LucidInlineBlocks from '../shared/LucidInlineBlocks'
import styled from 'styled-components'
import React, {useState} from 'react'
import {useLucidContext} from '../../../state/ServerSideStore'

export const MENU = {
  template: {
    label: 'Menu',
    defaultItem: {
      menu_title: 'Menu'
    },
    fields: [
      {
        name: 'menu_title',
        label: 'Menu Title',
        component: 'text'
      }
    ]
  },
  Component({index, cms, data}) {
    const [toggle, setToggle] = useState(false)
    const [{menus = []}] = useLucidContext(cms)
    const classMap = {
      'true': 'menu-open',
      'false': 'menu-closed'
    }

    function onMenuClick() {
      setToggle(!toggle)
    }

    return (
      <StyledMenu key={index} onClick={onMenuClick}>
        <Hamburger/>
        <ItemWrapper cms={cms} index={index} x={60} y={16}>
          <StyledMenuTitle onClick={onMenuClick} className='main-menu'><span>{data.menu_title}</span></StyledMenuTitle>
        </ItemWrapper>
        <StyledMenuItems
          className={classMap[toggle]}
          name='menu_items'
          blocks={getBlocks(menus, 'menu-control', cms)}
          itemProps={{...data, cms: cms}}
        />
      </StyledMenu>
    )
  }
}

export const LINK = {
  template: {
    label: 'Link'
  },
  Component({index, cms, data}) {
    let [{menus = []}] = useLucidContext(cms)

    const menuLinks = []
    for (const {menu_items} of menus) {
      for (const menuItem of menu_items) {
        menuLinks.push({id: menuItem.id, label: menuItem.label, name: menuItem.label, route: menuItem.route})
      }
    }

    return (
      <ItemWrapper cms={cms} index={index} y={2} x={0}>
        <LucidInlineBlocks itemProps={{...data, cms: cms}} name='link' blocks={getBlocks(menuLinks, 'link-control', cms)}/>
      </ItemWrapper>
    )
  }
}

function ItemWrapper({cms, index, children, x, y}) {
  if (!cms) return <div><div className="section-item-wrapper">{children}</div></div>
  return (
    <BlocksControls
      style={{display: 'none'}}
      index={index}
      focusRing={{offset: {x, y}, borderRadius: 4}}
      insetControls={false}
    >
      <div className="section-item-wrapper">{children}</div>
    </BlocksControls>
  )
}

function buildMenuItemFromTemplate(menuItemId, targetNavigationItem, navigationObject) {
  let menuItem = []
  const isMenu = 'menu_items' in targetNavigationItem
  if (isMenu) {
    menuItem = navigationObject.find(
      menu => menu.id === menuItemId
    )?.menu_items.sort((a, b) => a.sort_order - b.sort_order).map(item => ({
      [item.label]: item.route?.url ?? item.label
    }))
  } else {
    const foundItem = navigationObject.find(item => item.id === menuItemId)
    if (foundItem) menuItem = [{[foundItem.label]: foundItem.route.url}]
  }
  /**
   * TODO: this will not happen, we need to update TinaCMS
   *  to generate a corresponding not found
   *  template so we can use this
   */
  if (menuItem.length === 0) {
    return {'Item Not Found': '/errors/404'}
  }
  return menuItem.reduce((accumulator, currentValue) => ({
    ...accumulator, ...currentValue
  }), {})
}

const getBlockTemplateData = (item) => {
  const items = item.menu_items?.sort((a, b) => a.sort_order - b.sort_order) ?? [item]
  const fields = []
  const defaultItem = {}

  for (const menuItem of items) {
    fields.push({
      name: menuItem.label,
      label: menuItem.label,
      component: 'text'
    })
    defaultItem[menuItem.label] = menuItem.route?.url ?? menuItem.label
  }
  return {fields, defaultItem}
}

const getBlocks = (navigation, className, cms) => {
  const blocks = {}
  navigation.forEach(item => {
    blocks[item.id] = {
      template: {
        label: item.name,
        name: item.name,
        key: item.id,
        ...getBlockTemplateData(item)
      },
      Component({index, data: {_template}}) {
        const displayableItems = buildMenuItemFromTemplate(_template, item, navigation)
        return (
          <StyledMenuGroup index={index}>
            <ul className={className}>
              {Object.entries(displayableItems).map(([label, url], i) => {
                  return (
                    <MenuItemLink
                      cms={cms}
                      key={i}
                      name={label}
                      href={url}
                      text={label}
                    />
                  )
                }
              )}
            </ul>
          </StyledMenuGroup>
        )
      }
    }
  })
  return blocks
}

function Hamburger() {
  return (
    <StyledHamburger className='main-menu'>
      <svg viewBox="0 0 100 80" width="40" height="40">
        <rect width="100" height="20"></rect>
        <rect y="30" width="100" height="20"></rect>
        <rect y="60" width="100" height="20"></rect>
      </svg>
    </StyledHamburger>
  )
}

function MenuItemLink({href, text, cms}) {

  function noLinkOnCms(e) {
    if (cms) {
      e.preventDefault()
    }
  }

  return (
    <StyledListItem>
      <a onClick={noLinkOnCms} href={href}>
        <span>{text}</span>
      </a>
    </StyledListItem>
  )
}

const StyledMenuItems = styled(LucidInlineBlocks)`
  display: flex;
  justify-items: center;
  align-items: flex-start;
`

const StyledMenu = styled.div`
  display: flex;
  align-items: center;
  position: relative;

  .menu-open ul {

  }

  .menu-closed ul {
    display: none;
  }

  .main-menu {
    // TODO: get it from color scheme
    color: black;
    fill: black;

    :hover {
      color: #8b9fad;
      fill: #8b9fad
    }
  }
`

const StyledHamburger = styled.div`
  cursor: pointer;

  svg {
    margin: 0 8px;
  }
`

// TODO: we need to hook this up to color scheme
const StyledMenuGroup = styled.div`
  ul.menu-control {
    position: absolute;
    width: auto;
    padding: 8px;
    border-radius: 4px;
    background-color: #434649;
    left: 6px;
    margin: 21px 0;
  }

  ul.link-control {
  }

`

const StyledListItem = styled.li`
  position: relative;

  :hover {
    opacity: 0.5;
  }
`

const StyledMenuTitle = styled.div`
  width: 100%;
  cursor: pointer;
`
