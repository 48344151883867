import {StyleCoreElement, StyleCoreTarget, useStyleCoreRenderer} from '@/components/shared/StyleCore'
import React, {Key, ReactNode, useEffect, useMemo, useState} from 'react'
import {Badge, Button, Card, Dropdown, Grid, Link, Modal, Spacer, Text} from '@nextui-org/react'
import {Paintbucket, Trash, Warning2} from 'iconsax-react'
import {TypographyModal} from '@/components/editor/typography/TypographyModal'
import {defaultTypographyGroupElements} from '@/components/editor/typography/config'
import styled from 'styled-components'
import {ListenerTargetInner} from '@/components/shared/externalTypes'
import {StyleCoreForm} from '@/src/utils/shared/FormManagerFormNextUI'
import {InlineFieldRenderProps} from '@einsteinindustries/react-tinacms-inline'
import {useDebounce} from 'react-use'
import CodeMirror from '@uiw/react-codemirror'
import {css} from '@codemirror/lang-css'
import {useLucidContext} from '@/src/state/ServerSideStore'
import {GraphQLColorSchemeResponse} from '@/components/managers'
import {TypographyGroup} from '@/components/editor/typography/types'

const StyleCoreNotAvailableMessage = () => {
  return <Text color={'error'}>
    <Grid.Container gap={0.2}>
      <Grid>
        <Warning2 size={'32px'}/>
      </Grid>
      <Grid xs={10}>
        <Text b color={'error'}>StyleCore Not Available</Text>
      </Grid>
      <Grid xs={12}>
        <Text size={'$sm'}>StyleCore has not been enabled for this section yet. <br/> To enable it, read the <Link
          isExternal href={'https://www.notion.so/einsteinindustries/StyleCore-b9b1fef4352b4e10a523517d6e4b7d62?pvs=4'}>documentation
          here</Link>.</Text>
      </Grid>
    </Grid.Container>
  </Text>

}
const StyleCoreFormWrapper = ({children, inModal}: { children: ReactNode, inModal: boolean }) => {
  const [open, setOpen] = useState(false)
  // TODO: Modal problems causes the modal to not open programmatically. Need to fix this. For now the inModal is set to false for all usages.
  return <>
    <Spacer y={2}/>
    <Text size={'$sm'} b>Style Overrides</Text>
    <Spacer y={0.3}/>
    <Card style={{
      padding: 20,
      overflow: 'visible'
    }}>
      {inModal && <>
          <Modal
              closeButton
              aria-labelledby="modal-title"
              open={open}
              width={'100%'}

          >
              <Modal.Header>
                  <Text id="modal-title" size={24}>
                      <Paintbucket size={24} style={{marginRight: 10}}/>
                      StyleCore
                      <Text b size={24}>
                          Editor
                      </Text>
                  </Text>

              </Modal.Header>

              <Modal.Body>
                {children}
              </Modal.Body>
          </Modal>
          <Button size="lg" onClick={() => setOpen(true)}><Paintbucket style={{marginRight: 5}}/> Open StyleCore Editor</Button>
      </>}
      {!inModal && children}
    </Card></>
}

export function StyleCoreFormInnerSettings({styleCoreElement, formManagerTarget, cms}:
                                             {styleCoreElement: StyleCoreElement, formManagerTarget: ListenerTargetInner, cms: boolean}) {
  return useMemo(() => {
    if (typeof styleCoreElement === 'undefined') {
      return <StyleCoreFormWrapper inModal={false}><StyleCoreNotAvailableMessage/></StyleCoreFormWrapper>
    }
    return <StyleCoreFormWrapper inModal={true}><StyleCoreForm cms={cms} styleCoreElement={styleCoreElement}
                                                                formManagerTarget={formManagerTarget}/></StyleCoreFormWrapper>
  }, [styleCoreElement, styleCoreElement?.target?.identifier, styleCoreElement?.target?.componentName])
}

export function StyleCoreCSSComponent({target, cms}: { target: StyleCoreTarget, cms:boolean }) {
  const [, StyleCoreCSS] = useStyleCoreRenderer(target, cms)
  return <style jsx>{`${StyleCoreCSS}`} </style>
}

export function CMSHiding({cms, children} : {
  cms: boolean,
  children: React.ReactNode
}) {
  if (!cms) {
    return null
  } else {
    return <>{children}</>
  }
}

export function CssOverridesContainer({
                                        display,
                                        children
                                      }: { display: 'visible' | 'hidden' | 'locked', children: React.ReactNode }) {
  if (display === 'hidden') {
    return null
  }
  if (display === 'locked') {
    return <CssOverridesContainerStyles>
      <Badge size="md" enableShadow disableOutline color="warning">
        <Warning2/>
        <Spacer x={0.5}/>
        Styles Available After Save
      </Badge>
    </CssOverridesContainerStyles>
  }
  return (
    <CssOverridesContainerStyles>{children}</CssOverridesContainerStyles>
  )
}

export function StylesModalContent({input}: { input: InlineFieldRenderProps }) {
  return <>
    <Spacer y={2}/>
    <Text size={'$sm'} b>CSS Override</Text>
    <Spacer y={0.1}/>
    <StylesIDE input={input}/>
  </>
}

export function SwitchColorScheme({input, color_scheme_id_override, activeColorScheme, onChange, cms}: {
  input: InlineFieldRenderProps,
  color_scheme_id_override: string | null,
  activeColorScheme: string,
  onChange: (id: string) => void,
  cms: boolean
}) {
  const [lucidSiteStore] = useLucidContext(cms)
  const [currentScheme, setCurrentScheme] = useState<GraphQLColorSchemeResponse>(lucidSiteStore.schemes.filter((scheme) => `${scheme.id}` === color_scheme_id_override)[0] ?? lucidSiteStore.schemes.filter((scheme) => scheme.active)[0])
  const [selected, setSelected] = useState<Set<Key>>(new Set([color_scheme_id_override ?? 'unset']))
  useEffect(() => {
    const dropdownSelection = selected.entries().next().value[0]
    if (dropdownSelection === color_scheme_id_override || (color_scheme_id_override === null && dropdownSelection === 'unset')) {
      return
    }
    input.onChange(dropdownSelection)
    onChange(dropdownSelection === 'unset' ? null : dropdownSelection)
    if (dropdownSelection === 'unset') {
      setCurrentScheme(lucidSiteStore.schemes.filter((scheme) => scheme.active)[0])
    } else {
      setCurrentScheme(lucidSiteStore.schemes.filter((scheme) => `${scheme.id}` === dropdownSelection)[0])
    }
  }, [selected])

  let colorSchemeOverrideName = 'Default'
  let colorSchemeOverrideInUse = color_scheme_id_override !== null
  return <FullWidthDropdown>
    <Text h4 b>Overrides<Text>Override the default styles for this section</Text></Text>
    <hr/>
    <Spacer y={1}/>
    <Spacer y={0.2}/>
    <Text size={'$sm'} b>Color Scheme Override <Badge {...(colorSchemeOverrideInUse ? {
      color: 'success'
    } : {
      color: 'primary',
      variant: 'flat'
    })}>{colorSchemeOverrideInUse ? 'Applied' : 'Unused'}</Badge></Text>
    <Spacer y={0.2}/>
    <Dropdown>
      <Dropdown.Button flat={!colorSchemeOverrideInUse} size={'lg'} style={{
        width: '100%'
      }}>{!colorSchemeOverrideInUse ? 'No Override' : currentScheme.name}</Dropdown.Button>
      <Dropdown.Menu
        aria-label="Single selection actions"
        color="secondary"
        disallowEmptySelection
        selectionMode="single"
        selectedKeys={selected}
        onSelectionChange={(keys: any) => {
          setSelected(keys)
          return keys
        }}
      >
        <Dropdown.Section title="Color Schemes">
          {lucidSiteStore.schemes.map((scheme) => {
            return <Dropdown.Item key={scheme.id}
                                  command={scheme.active ? colorSchemeOverrideName : undefined}>{scheme.name}</Dropdown.Item>
          })}
        </Dropdown.Section>
        <Dropdown.Section title="Actions">
          <Dropdown.Item
            key="unset"
            color="error"
            icon={<Trash size={22}/>}
          >
            No Override
          </Dropdown.Item>
        </Dropdown.Section>
      </Dropdown.Menu>
    </Dropdown>
  </FullWidthDropdown>
}

function StylesIDE({input}: { input: InlineFieldRenderProps }) {
  const [cssOverrides, setCssOverrides] = useState<string | undefined>()
  /**
   * Note: Dynamic style jsx is slow
   *  We need to prevent the onChange from firing a rerender everytime as that would cause sluggishness
   *  That is the reason for this debounce, it's just wrapping the default behavior of final form and tina.
   */
  useDebounce(() => {
    if (typeof cssOverrides !== 'undefined') {
      input.onChange(cssOverrides)
    }
  }, 1000, [cssOverrides])

  return (
    <CodeMirror
      {...input}
      height="200px"
      extensions={[css()]}
      onChange={setCssOverrides}
    />
  )
}


const FullWidthDropdown = styled.div`
  width: 100%;
`
const CssOverridesContainerStyles = styled.div`
  position: absolute;
  left: 24px;
  top: 64px;
  border: none;
  background-color: transparent;
  border-radius: 4px;
  z-index: 99;

  > svg {
    margin: 4px 0 0 6px;
    height: 32px;
    width: 32px;
  }

  > div {
    position: absolute;
    border-right: none !important;

    svg {
      height: 32px;
      width: 32px;
      fill: transparent;
    }
  }
`
