import Section from '../shared/Section'
import {InlineTextWithVariables, RichtextWithVariables} from '../shared/SectionsWithVariables'
import {FEATURES_LIST_ITEM_BLOCKS} from './config'
import LucidInlineBlocks from '../shared/LucidInlineBlocks'
import SectionButton from '../shared/SectionButton'

export default function FeaturesList(props) {
  const {
    title,
    subtitle,
    intro,
    outro,
    button,
    color_scheme,
    className,
    css_overrides,
    color_scheme_id_override, lucid_page_section_id, meta,
    cms,
  } = props
  return (
    <Section
      cms={cms}
      className={className + ' section featureslist-section stack menu-section'}
      css_overrides={css_overrides} color_scheme_id_override={color_scheme_id_override} meta={meta} lucid_page_section_id={lucid_page_section_id}
      name="featureslist"
      colorSchemeId={color_scheme}
    >
      <div className="section-container">
        <div className="section-content">
          <div className="section-heading">
            <h1>
              <span className="title heading-1">
                <InlineTextWithVariables cms={cms} name="title" value={title} />
              </span>
              <span className="subtitle heading-2">
                <InlineTextWithVariables cms={cms} name="subtitle" value={subtitle} />
              </span>
            </h1>
          </div>
          <div className="section-description">
            <div className="row">
              <div className="column">
                <div className="section-richtext paragraph">
                  <RichtextWithVariables
                    name="intro"
                    richtext={intro}
                    cms={cms}
                  />
                </div>
              </div>
            </div>
          </div>
          <LucidInlineBlocks
            className="row menus features-list-items"
            itemProps={props}
            name="features_list_items"
            blocks={FEATURES_LIST_ITEM_BLOCKS}
          />
          <div className="section-outro">
            <div className="row">
              <div className="column section-richtext">
                <RichtextWithVariables
                  name="outro"
                  richtext={outro}
                  cms={cms}
                />
              </div>
            </div>
          </div>
          {button?.label && (
            <div className="button-container">
              <SectionButton
                href={button.url}
                label={button.label}
                cms={cms}
                name="button"
              />
            </div>
          )}
        </div>
      </div>
      <div className="section-background"></div>
    </Section>
  )
}

FeaturesList.StyleCore = {
  config: {},
  target: {
    componentName: Object.keys({FeaturesList})[0].toLowerCase(),
  },
  css: ''
}
