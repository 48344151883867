import styled from 'styled-components'
import {InlineWysiwyg} from '@einsteinindustries/react-tinacms-editor'

export interface SectionRichtextProps {
  name: string
  richtext: string
}

const StyledDiv = styled.div`
  margin-bottom: 2rem;
  p {
    line-height: 1.4;
    margin-bottom: 1rem;
  }
  p:last-child {
    margin-bottom: 0;
  }
`

export default function SectionRichtext({
  name,
  richtext
}: SectionRichtextProps) {
  if (!richtext) {
    return null
  }
  return (
    <InlineWysiwyg
      name={name}
      format="html"
      focusRing={{borderRadius: 4}}
      sticky={false}
    >
      <StyledDiv dangerouslySetInnerHTML={{__html: richtext}}/>
    </InlineWysiwyg>
  )
}
