import Section from './shared/Section'
import StyledHeading from './shared/StyledHeading'
import {InlineTextWithVariables, RichtextWithVariables} from './shared/SectionsWithVariables'
import SectionText from './shared/SectionText'
import SectionImage from './shared/SectionImage'
import styled from 'styled-components'
import SectionButton from './shared/SectionButton'

const StyledDiv = styled.div`
  display: grid;
  justify-items: center;
  align-items: center;

  @media (min-width: 640px) {
    grid-template-columns: 50% auto repeat(2, 1fr);
  }
`

export default function QuoteImage(props) {
  const {
    title,
    subtitle,
    intro,
    quote,
    cite,
    image,
    outro,
    button,
    color_scheme,
    className,
    css_overrides,
    color_scheme_id_override, lucid_page_section_id, meta,
    cms,
    caption,
  } = props
  return (
    <Section
      cms={cms}
      css_overrides={css_overrides}
      color_scheme_id_override={color_scheme_id_override} meta={meta}
      lucid_page_section_id={lucid_page_section_id}
      className={className +  ' quote-image-section stack'}
      name="quoteimage"
      colorSchemeId={color_scheme}>
      <div className="section-container">
        <div className="section-content quote_image_gutter_row_1 quote_image_gutter_column_1">
          <div className="section-heading">
            <h2 className="heading-1">
                    <span className="title">
                        <RichtextWithVariables richtext={title} cms={cms} name={'title'}/>
                    </span>
              <span className="subtitle heading-2">
                        <RichtextWithVariables richtext={subtitle} cms={cms} name={'subtitle'}/>
                    </span>
            </h2>
          </div>
          <div className="section-description">
            <div className="row left-aligned">
              <div className="column">
                <div className="section-richtext paragraph">
                  <RichtextWithVariables richtext={intro} cms={cms} name={'intro'}/>
                </div>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="column quote">
              <blockquote className="quote">
                <span><RichtextWithVariables richtext={quote} cms={cms} name={'quote'}/></span>
                <cite className="citation"><RichtextWithVariables richtext={cite} cms={cms} name={'cite'}/></cite>
              </blockquote>
            </div>
            <div className="column shrink">
              <figure>
                    <SectionImage src={image?.src} name="image" alt={image?.alt} cms={cms}/>
                <figcaption className="paragraph"><RichtextWithVariables richtext={caption} cms={cms} name={'caption'}/></figcaption>
              </figure>
            </div>
          </div>
          <div className="section-outro">
            <div className="row left-aligned">
              <div className="column section-richtext paragraph">
                <RichtextWithVariables richtext={outro} cms={cms} name={'outro'}/>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="section-background"></div>
    </Section>
  )
}

QuoteImage.StyleCore = {
  config: {},
  target: {
    componentName: Object.keys({QuoteImage})[0].toLowerCase(),
  },
  css: ''
}


