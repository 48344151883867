import {User} from '@/components/shared/types'
import {
  Combobox,
  ComboboxInput,
  ComboboxList,
  ComboboxOption,
  ComboboxOptionText,
  ComboboxPopover,
} from '@reach/combobox'

interface UserComboBoxProps {
  users: User[]
  setAssigneeInput: (assignee: string) => void
  assigneeInput: string
}

export default function UserComboBox({
  users,
  setAssigneeInput,
  assigneeInput,
}: UserComboBoxProps) {
  return (
    <Combobox
      aria-label="Users"
      onSelect={(item) => setAssigneeInput(item)}
      openOnFocus
    >
      {' '}
      <ComboboxInput
        onChange={(e) => setAssigneeInput(e.target.value)}
        value={assigneeInput}
        style={{
          width: '100%',
          padding: '12px 20px',
          margin: '8px 0',
          display: 'inline-block',
          border: '1px solid #ccc',
          borderRadius: '4px',
          boxSizing: 'border-box',
        }}
      />{' '}
      {users && (
        <ComboboxPopover style={{zIndex: 9996}}>
          {' '}
          {users.length > 0 ? (
            <ComboboxList>
              {' '}
              {users.slice(0, 5).map((result, index) => (
                <ComboboxOption
                  key={index}
                  value={result.name}
                >
                  {result.role?.name} - <ComboboxOptionText />
                </ComboboxOption>
              ))}{' '}
            </ComboboxList>
          ) : (
            <span style={{display: 'block', margin: 8}}>
              {' '}
              No results found{' '}
            </span>
          )}{' '}
        </ComboboxPopover>
      )}{' '}
    </Combobox>
  )
}
