import React, {useEffect, useState} from 'react'
import styled from 'styled-components'
import {DialogContent, DialogOverlay} from '@reach/dialog'
import {CrossIcon} from '../../components/icons'

interface WizardLayoutProps {
  title: string
  showDialog: boolean
  closeDialog: () => void
  onSave: () => void
  children: React.ReactNode
  isNextAllowed?: boolean
  getCurrentPage?: (currentPage: number) => void
}

const StyledDialogOverlay = styled(DialogOverlay)`
  z-index: 9000;
`

const StyledDialogContent = styled(DialogContent)`
  border-radius: 10px;
  overflow: hidden;
  width: 70vw;
  box-shadow: 0 3px 7px rgba(0, 0, 0, 0.3);
  padding: 0;
`

const WizardHeader = styled.div`
  border-bottom: 1px solid #ccc;
  position: sticky;
  top: 0;
  background-color: white;
  height: 50px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 25px;
`

const CancelCross = styled.button`
  background-color: inherit;
  border: none;
  cursor: pointer;
`

const WizardContent = styled.div`
  margin: 10px 20px;
  height: 60vh;
  overflow: scroll;
`

const WizardFooter = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  position: sticky;
  bottom: 0;
  background-color: white;
  height: 50px;
  padding: 4px 25px;
  border-top: 1px solid #ccc;
`

const StyledButton = styled.button`
  border: none;
  background-color: white;
  font-size: 1.1em;
  cursor: pointer;
`

const StyledPreviousButton = styled(StyledButton)`
  color: red;
`

const StyledNextButton = styled(StyledButton)`
  color: ${(props) =>
    props.disabled ? '#adc3d7' : 'var(--tina-color-primary)'};
  cursor: ${(props) => (props.disabled ? 'not-allowed' : 'pointer')};
`

const WizardLayout = ({
  title,
  showDialog,
  closeDialog,
  onSave,
  children,
  isNextAllowed = true,
  getCurrentPage,
}: WizardLayoutProps) => {
  const [currentPage, setCurrentPage] = useState(1)
  const totalPages = React.Children.count(children)
  const isFirstPage = currentPage === 1
  const isLastPage = currentPage === totalPages
  const currentChild = React.Children.toArray(children)[currentPage - 1]

  useEffect(() => {
    if (getCurrentPage) {
      getCurrentPage(currentPage)
    }
  }, [currentPage, getCurrentPage])

  const handleNext = () => {
    if (isNextAllowed) {
      if (isLastPage) {
        onSave()
      } else {
        setCurrentPage(currentPage + 1)
      }
    }
  }

  const handlePrevious = () => {
    if (isFirstPage) {
      closeDialog()
    } else {
      setCurrentPage(currentPage - 1)
    }
  }
  return (
    <StyledDialogOverlay isOpen={showDialog} onDismiss={closeDialog}>
      {' '}
      <StyledDialogContent aria-labelledby={'label--create-page'}>
        {' '}
        <div>
          <WizardHeader>
            <h4 style={{margin: 0}}>
              {title} ({currentPage}/{totalPages})
            </h4>
            <CancelCross aria-label="crossCancel" onClick={() => closeDialog()}>
              <CrossIcon />
            </CancelCross>
          </WizardHeader>
          <WizardContent>{currentChild}</WizardContent>{' '}
          <WizardFooter>
            <StyledPreviousButton onClick={handlePrevious}>
              {isFirstPage ? 'Cancel' : 'Previous'}
            </StyledPreviousButton>
            <StyledNextButton onClick={handleNext} disabled={!isNextAllowed}>
              {isLastPage ? 'Save' : 'Next'}
            </StyledNextButton>
          </WizardFooter>
        </div>
      </StyledDialogContent>{' '}
    </StyledDialogOverlay>
  )
}

export default WizardLayout
