import {ArrowRightIcon} from '../../../icons'
import FilterBox from '../../../shared/FilterBox'
import styled from 'styled-components'
import {
  Menu,
  MenuButton,
  MenuItem,
  MenuItems,
  MenuPopover,
} from '@reach/menu-button'
import '@reach/menu-button/styles.css'
import {FilterState, Page, PageBuild} from '../../../shared/types'
import React, {Dispatch} from 'react'
import PagesFilter from '../../../../components/managers/PageManager/components/PagesFilter'
import {getPagesFromId} from '../utils'
import {
  DialogReducerAction,
  getAction,
} from '@/components/managers/PageManager/dialogReducer'
import {
  handleCreateProject,
  handleGroupActionModal,
  openActionsDialog,
} from '@/components/managers/PageManager/dialogUtilityFunctions'

export const NewButton = styled(MenuButton)`
  text-align: center;
  border-radius: var(--tina-radius-big);
  box-shadow: var(--tina-shadow-small);
  border: 1px solid var(--tina-color-grey-2);
  font-weight: var(--tina-font-weight-regular);
  cursor: pointer;
  font-size: var(--tina-font-size-1);
  height: 40px;
  padding: 0 var(--tina-padding-big);
  transition: all 85ms ease-out;
  opacity: 1;
  background-color: var(--tina-color-primary);
  color: var(--tina-color-grey-0);
  margin-right: 12px;
`
// TODO: refactor, too many props
// Component Composition with children?
// pass dispatch function
interface HeaderBarProps {
  pageBuilds: PageBuild[]
  searchInput: string
  currentSort: string
  sortDirection: string
  groupBy: string
  sortFields: string[]
  setSearchInput: (input: string) => void
  setGroupBy: (groupBy: keyof Page | 'none') => void
  setFilterState: (filterSate: FilterState) => void
  setCurrentSort: (sort: keyof PageBuild) => void
  handleToggleDirectionSort: () => void
  selectAll: () => void
  selectedPages: string[]
  dispatchAction: Dispatch<DialogReducerAction>
}

const HeaderBar = ({
  searchInput,
  setSearchInput,
  pageBuilds,
  setFilterState,
  sortFields,
  currentSort,
  setCurrentSort,
  sortDirection,
  handleToggleDirectionSort,
  groupBy,
  setGroupBy,
  selectAll,
  selectedPages,
  dispatchAction,
}: HeaderBarProps) => (
  <div
    style={{
      position: 'sticky',
      top: 0,
      backgroundColor: 'white',
      height: '62px',
      borderBottom: '1px solid var(--tina-color-grey-2)',
      display: 'flex',
      justifyContent: 'space-between',
      zIndex: 100,
    }}
  >
    <div
      style={{
        height: '62px',
        display: 'flex',
        alignItems: 'center',
        marginLeft: '12px',
      }}
    >
      <button
        style={{marginRight: '10px'}}
        onClick={selectAll}
      >
        Select All
      </button>
      <input
        placeholder="Search"
        value={searchInput}
        onChange={(e) => setSearchInput(e.target.value)}
        style={{
          height: '40px',
          marginRight: '12px',
          padding: '12px',
          border: '1px solid var(--tina-color-grey-2)',
        }}
      />
      <div style={{display: 'flex', marginRight: '12px'}}>
        <span style={{marginRight: '12px', color: '#757575'}}>Filters:</span>
        <PagesFilter
          pageBuilds={pageBuilds}
          onChange={setFilterState}
        />
      </div>
      <div style={{display: 'flex', alignItems: 'center'}}>
        <span style={{marginRight: '15px', color: '#757575'}}>Sort:</span>
        <FilterBox
          options={sortFields}
          value={currentSort}
          onChange={(value: string) => setCurrentSort(value as keyof PageBuild)}
        />
        <ArrowRightIcon
          style={{
            marginLeft: '5px',
            transform:
              sortDirection === 'ASC' ? 'rotate(90deg)' : 'rotate(-90deg)',
            cursor: 'pointer',
          }}
          height="15pt"
          width="15pt"
          title="Sort Desc"
          onClick={handleToggleDirectionSort}
        />
      </div>
      <div
        style={{
          marginLeft: '12px',
          display: 'flex',
          alignItems: 'center',
        }}
      >
        <span style={{marginRight: '15px', color: '#757575'}}>Group by:</span>
        <FilterBox
          options={['status', 'assigned_to_user', 'none']}
          value={groupBy}
          onChange={(value: string) => setGroupBy(value as keyof Page | 'none')}
        />
      </div>
    </div>
    <div
      style={{
        display: 'flex',
        justifyContent: 'flex-end',
        alignItems: 'center',
        height: '62px',
      }}
    >
      <DropdownButton name="Actions">
        {/*<MenuItem
          onSelect={() =>
            dispatchAction(getAction('PublishProjectDialog', 'Show'))
          }
        >
          Publish Project
        </MenuItem>*/}
        {!!selectedPages.length && (
          <>
            <MenuItem
              onSelect={() => handleGroupActionModal('all', dispatchAction)}
            >
              Assign To User
            </MenuItem>
            {getPagesFromId(selectedPages, pageBuilds).every(
              (page) => page.status === 'IN_PROGRESS'
            ) && (
              <MenuItem
                onSelect={() =>
                  handleGroupActionModal('IN_PROGRESS', dispatchAction)
                }
              >
                Submit for Approval
              </MenuItem>
            )}
            {getPagesFromId(selectedPages, pageBuilds).every(
              (page) => page.status === 'READY_FOR_REVIEW'
            ) && (
              <MenuItem
                onSelect={() =>
                  handleGroupActionModal('READY_FOR_REVIEW', dispatchAction)
                }
              >
                Approve/Reject
              </MenuItem>
            )}
            {getPagesFromId(selectedPages, pageBuilds).every(
              (page) => page.status === 'APPROVED'
            ) && (
              <MenuItem
                onSelect={() =>
                  handleGroupActionModal('APPROVED', dispatchAction)
                }
              >
                Publish/Reject
              </MenuItem>
            )}
            {/*{getPagesFromId(selectedPages, pageBuilds).every(
              (page) => page.status === 'P'
            ) && (
              <MenuItem
                onSelect={() =>
                  handleGroupActionModal('published', dispatchAction)
                }
              >
                Unpublish
              </MenuItem>
            )}*/}
            {(getPagesFromId(selectedPages, pageBuilds).every(
              (page) => page.locked
            ) ||
              getPagesFromId(selectedPages, pageBuilds).every(
                (page) => !page.locked
              )) && (
              <MenuItem
                onSelect={() => openActionsDialog('lock', dispatchAction)}
              >
                {getPagesFromId(selectedPages, pageBuilds)[0]?.locked
                  ? 'unlock'
                  : 'lock'}
              </MenuItem>
            )}
            <MenuItem
              onSelect={() => openActionsDialog('delete', dispatchAction)}
            >
              Delete
            </MenuItem>
            <MenuItem
              onSelect={() => openActionsDialog('copy', dispatchAction)}
            >
              Copy
            </MenuItem>
          </>
        )}
      </DropdownButton>

      <DropdownButton name="New">
        <MenuItem
          onSelect={() => dispatchAction(getAction('CreateDialog', 'Show'))}
        >
          New Page
        </MenuItem>
        <MenuItem
          onSelect={() => dispatchAction(getAction('SyndicatedDialog', 'Show'))}
        >
          New Syndicated Page
        </MenuItem>
        <MenuItem onSelect={() => handleCreateProject(dispatchAction)}>
          New Project
        </MenuItem>
      </DropdownButton>
    </div>
  </div>
)

interface DropdownButtonProps {
  name: string
}

const DropdownButton: React.FC<DropdownButtonProps> = ({name, children}) => (
  <Menu>
    <NewButton>
      {name}
      <span aria-hidden>▾</span>
    </NewButton>
    <MenuPopover style={{zIndex: 8000}}>
      <div className="arbitrary-element">
        <MenuItems>{children}</MenuItems>{' '}
      </div>{' '}
    </MenuPopover>
  </Menu>
)

export default HeaderBar
