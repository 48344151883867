import {InlineTextWithVariables} from './shared/SectionsWithVariables'
import {RichtextWithVariables} from './shared/SectionsWithVariables'
import SectionImage from './shared/SectionImage'
import Section from './shared/Section'

export default function ImageWide(props) {
  const {
    title,
    subtitle,
    image,
    caption,
    color_scheme,
    className,
    css_overrides,
    color_scheme_id_override, lucid_page_section_id, meta,
    cms,
  } = props
  return (
    <Section
      cms={cms}
      css_overrides={css_overrides}
      color_scheme_id_override={color_scheme_id_override} meta={meta}
      lucid_page_section_id={lucid_page_section_id}
      className={className + ' section imagewide-section stack'}
      name="imagewide"
      colorSchemeId={color_scheme}>
      <div className="section-container">
        <div className="section-content">
          <div className="section-heading">
            <h2>
              <span className="title heading-1">
                <InlineTextWithVariables name="title" value={title} cms={cms} />
              </span>
              <span className="subtitle heading-2">
                <InlineTextWithVariables name="subtitle" value={subtitle} cms={cms} />
              </span>
            </h2>
          </div>
          <div className="row expanded collapse">
            <div className="column">
              <figure>
                <div className="image-overlay">
                  <SectionImage
                    name='image'
                    src={image?.src}
                    alt={image?.alt}
                    width={image?.width}
                    height={image?.height}
                    cms={cms}
                  />
                </div>
                <figcaption className="label caption">
                  <RichtextWithVariables name="caption" richtext={caption} cms={cms} />
                </figcaption>
              </figure>
            </div>
          </div>
        </div>
      </div>
      <div className="section-background"></div>
    </Section>
  )
}

ImageWide.StyleCore = {
  config: {},
  target: {
    componentName: Object.keys({ImageWide})[0].toLowerCase(),
  },
  css: ''
}
