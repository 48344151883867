import {DialogContent, DialogOverlay} from '@reach/dialog'
import '@reach/dialog/styles.css'
import {FormEvent, useState} from 'react'
import {LockIcon} from '../../../icons'
import '@reach/combobox/styles.css'
import {Page, PageBuild} from '@/components/shared/types'
import useUserSearch from '@/src/utils/useUserSearch'
import UserComboBox from '@/components/shared/UserComboBox'
import DialogLayout from '@/components/shared/DialogLayout'

// const ROLES_ALLOWED = [2, 4] // https://einsteinindustries.atlassian.net/browse/LUC-368 roles will be hardcoded

interface PageDialogProps {
  pageBuild: PageBuild
  showDialog: boolean
  closeDialog: () => void
  onEdit: () => void
  onPublish: (pages: PageBuild[]) => void
  updateTags: (pageId: string, tags: string[]) => void
}

const PageDialog = ({
  pageBuild,
  showDialog,
  closeDialog,
  onEdit,
  onPublish,
  updateTags,
}: PageDialogProps) => {
  const {page_tags: previous_page_tags} = pageBuild.page!.attributes!
  const [newTag, setNewTag] = useState('')
  const [tags, setTags] = useState(previous_page_tags)
  const [assigneeInput, setAssigneeInput] = useState(
    pageBuild.assigned_to_user?.name || ''
  )

  const users = useUserSearch(assigneeInput)

  const handleSubmitTag = (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault()
    if (!newTag) return false
    setTags([...tags, newTag])
    setNewTag('')
    return false
  }
  // test if user changed the tags of the page
  const isTagsModified = () => {
    if (tags.length !== previous_page_tags.length) return true
    return previous_page_tags.some((tag) => !tags.includes(tag))
  }

  const isAssigned = () =>
    (users.length === 1 && pageBuild.assigned_to_user?.id !== users[0].id) ||
    (!users.length && pageBuild.assigned_to_user)

  const handlePublish = () => {
    onPublish([pageBuild])
    handleSave()
  }

  const handleLock = () => {
    pageBuild.locked = true
    handleSave()
  }

  const handleSave = () => {
    if (pageBuild.locked || isAssigned()) {
      if (isAssigned()) {
        pageBuild.assigned_to_user = users[0] || {id: 0} // id of zero will reset the user field of the page to null
        /*pageBuild.status =
          pageBuild.status === 'PROPOSED' ? 'IN_PROGRESS' : pageBuild.status*/
      }
      onEdit()
    }
    if (isTagsModified()) {
      updateTags(pageBuild.page_id, tags)
    }
    closeDialog()
  }

  const deleteTag = (tagToBeDeleted: string) =>
    setTags(tags.filter((tag) => tag !== tagToBeDeleted))

  return (
    <DialogLayout
      title={'Edit Page'}
      showDialog={showDialog}
      closeDialog={closeDialog}
      onSave={handleSave}
      tabs={['actions', 'infos']}
      size={{width: '35vw'}}
    >
      <div>
        <div style={{marginBottom: '15px'}}>
          <button
            onClick={handleLock}
            style={{
              cursor: 'pointer',
              width: '100%',
              padding: '8px 0',
              backgroundColor: '#dc3545',
              color: 'white',
              border: '1px solid #dc3545',
              borderRadius: '5px',
              fontWeight: 'bold',
              fontSize: '0.9em',
            }}
          >
            <LockIcon
              width="16"
              height="16"
              fill="white"
              style={{marginBottom: '-2px'}}
            />{' '}
            Lock page
          </button>
        </div>
        <div>
          <form
            action=""
            onSubmit={(e) => handleSubmitTag(e)}
          >
            <label htmlFor="add-tag">Add tags: </label>
            <input
              placeholder="Press Enter to confirm"
              type="text"
              value={newTag}
              name="add-tag"
              onChange={(e) => setNewTag(e.target.value)}
              style={{
                width: '100%',
                padding: '12px 20px',
                margin: '8px 0',
                display: 'inline-block',
                border: '1px solid #ccc',
                borderRadius: '4px',
                boxSizing: 'border-box',
              }}
            />
          </form>
        </div>
        <div>
          <div
            style={{
              display: 'flex',
              justifyContent: 'start',
              alignItems: 'center',
              flexWrap: 'wrap',
            }}
          >
            {tags
              ? tags.map((tag: string) => (
                  <span
                    key={tag}
                    onClick={() => deleteTag(tag)}
                    style={{
                      backgroundColor: ' var(--tina-color-primary)',
                      color: 'white',
                      borderRadius: ' 3px',
                      padding: ' 3px 12px',
                      margin: '5px',
                      cursor: 'pointer',
                    }}
                  >
                    {tag}{' '}
                    <span style={{fontWeight: 'lighter', marginLeft: '4px'}}>
                      {' '}
                      &#10005;
                    </span>
                  </span>
                ))
              : 'No tag to show'}
          </div>
        </div>

        <div style={{marginTop: '15px'}}>
          <span>Assign a user to this page</span>
          <UserComboBox
            assigneeInput={assigneeInput}
            setAssigneeInput={setAssigneeInput}
            users={users}
          />
        </div>
        {pageBuild.page?.dev_page_build_id === pageBuild.id && (
          <div style={{marginTop: '15px'}}>
            <button
              onClick={handlePublish}
              style={{
                cursor: 'pointer',
                width: '100%',
                padding: '8px 0',
                backgroundColor: '#16a085',
                color: 'white',
                border: '1px solid #16a085',
                borderRadius: '5px',
                fontWeight: 'bold',
                fontSize: '0.9em',
              }}
            >
              Publish this page
            </button>
          </div>
        )}
      </div>
      <div>
        {Object.entries(pageBuild).map((entry) => (
          <div key={entry[0]}>
            <label htmlFor={entry[0]}>{entry[0]}: </label>
            <input
              readOnly
              type="text"
              value={entry[1] || ''}
              name={entry[0]}
              style={{
                width: '100%',
                padding: '12px 20px',
                margin: '8px 0',
                display: 'inline-block',
                border: '1px solid #ccc',
                borderRadius: '4px',
                boxSizing: 'border-box',
              }}
            />
          </div>
        ))}
      </div>
    </DialogLayout>
  )
}

export default PageDialog
