import {
  ADD_MENU,
  ADD_MENU_ITEM,
  ADD_ROUTE,
  ARCHIVE_MENU,
  GROUP_TEMPLATE_MENU_ITEM,
  REMOVE_MENU_ITEM,
  UPDATE_MENU_ITEM
} from '@/src/graphql/mutations'
import {lucidDataFetcherV2} from '@/src/graphql/fetchers'
import {Menu, MenuItem, Route} from '@/components/shared/types'

export const deleteMenu = async (menu_id: string): Promise<boolean> => {
  if (!menu_id) return false
  const {data: {archiveMenu}} = await lucidDataFetcherV2<boolean>(ARCHIVE_MENU, {id: menu_id})
  return archiveMenu
}

export const deleteMenuItem = async (menu_item_id: string): Promise<boolean> => {
  if (!menu_item_id) return false
  const {data: {removeMenuItem}} = await lucidDataFetcherV2<boolean>(REMOVE_MENU_ITEM, {id: menu_item_id})
  return removeMenuItem
}

export const addMenu = async (name: string, site_build_id: number): Promise<Menu | undefined> => {
  if (!name || !site_build_id) return
  const {data: {addMenu}} = await lucidDataFetcherV2<Menu>(ADD_MENU, {new_menu_input: {name, site_build_id}})
  return addMenu
}

export const addMenuGroup = async (label: string, site_build_id: number): Promise<MenuItem | undefined> => {
  if (!label || !site_build_id) return
  const {data: {addGroupTemplateMenuItem}} = await lucidDataFetcherV2<MenuItem>(
    GROUP_TEMPLATE_MENU_ITEM,
    {label, site_build_id}
  )
  return addGroupTemplateMenuItem
}

export const updateMenuItem = async (menu_item_id: string, sort_order: number): Promise<MenuItem | undefined> => {
  if (!menu_item_id || typeof sort_order === 'undefined' || sort_order === null) return
  const {data: {updateMenuItem}} = await lucidDataFetcherV2<MenuItem>(
    UPDATE_MENU_ITEM,
    {id: menu_item_id, update_menu_item_input: {sort_order}}
  )
  return updateMenuItem
}

export const addMenuItem = async (item: Partial<MenuItem>): Promise<MenuItem | undefined> => {

  if (!item) return

  const add_menu_item_input: Record<string, string | number | null | undefined> = {
    label: item.label,
    route_id: item.route?.id ?? item.route_id,
    sort_order: item.sort_order
  }
  let hasAssociation = false
  if (item.site_build_id) {
    hasAssociation = true
    add_menu_item_input.site_build_id = item.site_build_id
  }
  if (item.parent_menu_item_id) {
    hasAssociation = true
    add_menu_item_input.parent_menu_item_id = item.parent_menu_item_id
  } else if (item.menu_id) {
    hasAssociation = true
    add_menu_item_input.menu_id = item.menu_id
  }
  if (!hasAssociation) return

  const {data: {addMenuItem}} = await lucidDataFetcherV2<MenuItem>(ADD_MENU_ITEM, {add_menu_item_input})
  return addMenuItem
}

export const addLinkRoute = async (name: string, url: string, site_build_id: number | string): Promise<Route> => {
  const {data: {addRoute}} = await lucidDataFetcherV2<Route>(
    ADD_ROUTE,
    {newRoute: {
        route_type: 'EXTERNAL',
        site_build_id,
        url
    }}
  )
  return addRoute
}
