export default function BlogCardItem(props) {
  const {
    title,
    subtitle = null,
    url = null,
  } = props
  return (
    <>
      <div className="column">
        {url && (
          <a
            href={url}
            className="blog-post"
          >
            <span className="blog-post-title heading-1">{title}</span>
            <span className="blog-post-author heading-3">{subtitle}</span>
          </a>
        )}
        {!url && (
          <div className="blog-post">
            <span className="blog-post-title heading-1">{title}</span>
            <span className="blog-post-author heading-3">{subtitle}</span>
          </div>
        )}
      </div>
    </>
  )
}
