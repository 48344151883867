import {NextPage} from 'next'
import {AppProps} from 'next/app'
import {ReactElement, ReactNode} from 'react'
import 'react-toastify/dist/ReactToastify.css'
import {NextUIProvider} from '@nextui-org/react'
import {ConfirmationDialogProvider} from '@/components/shared/AlertDialogs'

type NextPageWithLayout = NextPage & {
  getLayout?: (page: ReactElement) => ReactNode
}

type AppPropsWithLayout = AppProps & {
  Component: NextPageWithLayout
}

export default function App({Component, pageProps}: AppPropsWithLayout) {
  const getLayout = Component.getLayout ?? ((page) => page)

  return getLayout(
    <>
      <NextUIProvider>
        <ConfirmationDialogProvider>
          <Component {...pageProps} />
        </ConfirmationDialogProvider>
      </NextUIProvider>
    </>
  )
}
