import {BlocksControls} from '@einsteinindustries/react-tinacms-inline'
import SectionImage from '../shared/SectionImage'
import SectionText from '../shared/SectionText'

// TODO: Use actual `cms`

// Base item for all the other item types to pull from
const baseItem = {
  label: 'Item',
  defaultItem: {
    title: 'Title',
    subtitle: 'Subtitle',
  },
  itemProps: (item) => {
    if (!item.title && !item.subtitle) {
      return
    }

    return {label: item.title || item.subtitle}
  },
  // TODO: Create a set of default fields
  fields: [
    {
      name: 'title',
      label: 'Title',
      component: 'text',
    },
    {
      name: 'subtitle',
      label: 'Subtitle',
      component: 'text',
    },
  ],
}

// Templates to use in the parent's block config and the InlineBlocks component
const itemTemplates = {
  block_portrait: {
    ...baseItem,
    label: 'Block Portrait Item',
    defaultItem: {
      ...baseItem.defaultItem,
      title: 'Title',
      image: {
        src: '',
        alt: '',
      },
    },
    fields: [
      ...baseItem.fields,
      {
        name: 'image',
        label: 'Image',
        component: 'group',
        fields: [
          {
            name: 'src',
            label: 'Image',
            component: 'image',
            description: 'Image description',
            clearable: true,
            parse: (media) => {
              if (!media) {
                return
              }
              // console.log('image field config - parse:', media)
              return `${process.env.NEXT_PUBLIC_IMGIX_READ_URL}/${media.directory}/${media.filename}`
            },
            previewSrc: (src) => {
              console.log('previewSrc', src)
              return src
            },
          },
          {
            name: 'alt',
            label: 'Image Alt',
            component: 'text',
          },
        ],
      },
    ],
  },
}

// InlineBlocks `blocks`
export const MENU_ITEM_BLOCKS = {
  block_portrait: {
    Component({_name, index, data}) {
      return (
        <ItemWrapper index={index}>
          <ItemHeader title={data.title} subtitle={data.subtitle} />
          <SectionImage
            name="image.src"
            src={data.image.src}
            alt={data.image.alt}
            // width={image.width}
            // height={image.height}
            cms={data.cms}
          />
          {/* <pre style={{ whiteSpace: 'pre-wrap' }}>
            {JSON.stringify(data, null, 2)}
          </pre> */}
        </ItemWrapper>
      )
    },
    template: itemTemplates.block_portrait,
  },
}

function ItemWrapper(props) {
  return (
    <div
      className="section-item-wrapper"
      style={{
        flex: '1 0 calc(33% - 1rem)',
        // border: '1px dashed #000',
        padding: '1rem',
      }}
    >
      <BlocksControls
        index={props.index}
        focusRing={{offset: 16, borderRadius: 4}}
        insetControls={false}
      >
        {props.children}
      </BlocksControls>
    </div>
  )
}

function ItemHeader(props) {
  if (!props.title && !props.subtitle) {
    return null
  }
  return (
    <h4>
      {props.title ? (
        <span className="title">
          <SectionText name="title" text={props.title} cms={props.cms} />
        </span>
      ) : null}
      {props.subtitle ? (
        <span className="subtitle">
          <SectionText name="subtitle" text={props.subtitle} cms={props.cms} />
        </span>
      ) : null}
    </h4>
  )
}
