export function LinkIcon() {
  return (
    <svg
      height="100"
      width="100"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 89.757 92.244"
    >
      <g data-name="Camada 2">
        <g data-name="Your Icon">
          <path d="M81.163 5.169v-.001a23.587 23.587 0 00-32.77 3.222l-6.896 8.255a5.884 5.884 0 009.03 7.545l6.898-8.256a11.734 11.734 0 0116.776-1.244 11.983 11.983 0 01.98 16.532L60.841 48.39l-.132.147a11.481 11.481 0 01-2.662 2.27 11.718 11.718 0 01-14.753-1.972 5.885 5.885 0 00-8.612 8.022 23.504 23.504 0 0021.186 7.138 24.042 24.042 0 003.545-.906 23.359 23.359 0 0010.475-7.176l14.304-17.12a24.18 24.18 0 005.451-17.76 23.099 23.099 0 00-8.48-15.864z" />
          <path d="M38.819 68.743l-6.165 7.378a11.983 11.983 0 01-16.442 1.974 11.732 11.732 0 01-1.758-16.73l14.587-17.46.107-.12a11.475 11.475 0 012.662-2.27 11.69 11.69 0 0113.69.957 11.83 11.83 0 011.268 1.23 5.877 5.877 0 004.484 1.985 5.809 5.809 0 004.374-2.082l.07-.085a5.802 5.802 0 00-.099-7.598 23.488 23.488 0 00-35.628.486L5.423 53.82A23.589 23.589 0 008.08 86.64a23.098 23.098 0 0017.12 5.523q1.1-.089 2.184-.28a24.172 24.172 0 0014.32-8.241l6.144-7.354a5.884 5.884 0 00-9.03-7.545z" />
        </g>
      </g>
    </svg>
  )
}
