// import dynamic from 'next/dynamic'
import Section from './shared/Section'
import {InlineTextWithVariables, RichtextWithVariables} from './shared/SectionsWithVariables'
import SectionButton from './shared/SectionButton'

const AnnouncementA = (props) => {
  const {
    title,
    subtitle,
    richtext,
    color_scheme,
    button,
    cms = true,
      css_overrides,
    color_scheme_id_override,
    className,
    lucid_page_section_id
  } = props

  return (
    <Section
      cms={cms}
      css_overrides={css_overrides}
      color_scheme_id_override={color_scheme_id_override}
      lucid_page_section_id={lucid_page_section_id}
      className={className + ' section announcement-section stack announcement-a-section'}
      name="announcementa"
      colorSchemeId={color_scheme}>
      <div className="section-container">
        <div className="section-content announcement_gutter_row_1 announcement_gutter_column_1">
          <div className="row announcement-wrapper">
            <div className="column">
              <div className="section-heading">
                <h1>
                  <span className="title heading-1">
                      <InlineTextWithVariables name="title" value={title} cms={cms} />
                  </span>
                  <span className="heading-4 subtitle">
                      <InlineTextWithVariables name="subtitle" value={subtitle} cms={cms} />
                  </span>
                </h1>
              </div>
              <span className="section-richtext">
                <RichtextWithVariables name="richtext" richtext={richtext} cms={cms} />
              </span>
            </div>
            {button?.label && (
              <span className="column shrink">
                <SectionButton
                  href={button.url}
                  label={button.label}
                  cms={cms}
                  name="button"
                />
              </span>
            )}
          </div>
        </div>
      </div>
    
      <div className="section-background"></div>
    </Section>
  )
}

AnnouncementA.StyleCore = {
  config: {},
  target: {
    componentName: Object.keys({AnnouncementA})[0].toLowerCase(),
  },
  css: ''
}

export default AnnouncementA
