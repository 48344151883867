import styled from 'styled-components'

export const Form = styled.form`
  .route-manager-dialog-field {
    margin-bottom: var(--tina-padding-big);
  }
`

export const InputLabel = styled.label`
  /* all: unset; */
  /* font-family: 'Inter', sans-serif; */
  display: block;
  font-size: var(--tina-font-size-1);
  font-weight: 600;
  letter-spacing: 0.01em;
  line-height: 1.35;
  color: var(--tina-color-grey-8);
  margin-top: 16px;
  margin-bottom: 8px;
  text-overflow: ellipsis;
  width: 100%;
  overflow: hidden;
  &:first-child {
    margin-top: 0;
  }
`

export const InputLabelSuggestion = styled.div`
  font-weight: 400;
  color: var(--tina-color-grey-5);
  code {
    cursor: auto;
  }
`

export const FormButtonBar = styled.div`
  display: flex;
  flex-flow: row nowrap;
  justify-content: flex-end;
  flex: 1 0 auto;
`
