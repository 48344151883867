import {PageTemplate} from '@/src/components/shared/types'

const PageTemplates: PageTemplate[] = [
    {
        name: 'Contact',
        sections: [{
            name: 'ContactForm',
            sort_order: 0
        }]
    },
    {
        name: 'Thank You',
        sections: [{
            name: 'Textblock',
            sort_order: 0
        }] // todo: create a thank you section
    },
    {
        name: 'Consultation',
        sections: [{
            name: 'Consultation',
            sort_order: 0
        }]
    },
    {
        name: 'Locations',
        sections: [{
            name: 'Locations',
            sort_order: 0
        }]
    },
    {
        name: 'Location',
        sections: [{
            name: 'Location',
            sort_order: 0
        }]
    },
    {
        name: 'Site Map',
        sections: [{
            name: 'Textblock',
            sort_order: 0
        }] // todo: create a sitemap section
    }
]

export default PageTemplates
