import styled from 'styled-components'
import {
  DialogOverlay as ReachDialogOverlay,
  DialogContent as ReachDialogConent,
} from '@reach/dialog'

export const DialogOverlay = styled(ReachDialogOverlay)`
  font-family: var(--tina-font-family);
  z-index: var(--tina-z-index-5);
`

export const DialogContent = styled(ReachDialogConent)`
  overflow: scroll;
  max-height: 85vh;
  margin: 7.5vh auto;
`
