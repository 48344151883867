// Function for determining equality of Objects
export default function deepEqual(
  x: { [x: string]: any; },
  y: { [x: string]: any; }
): boolean {
  const ok = Object.keys, tx = typeof x, ty = typeof y
  return x && y && tx === 'object' && tx === ty ? (
    ok(x).length === ok(y).length &&
      ok(x).every(key => deepEqual(x[key], y[key]))
  ) : (x === y)
}
