// Reducer init state for dialog, in case of new dialog add an element here
export const initialDialogsState = [
  {
    name: 'EditDialog',
    isOpen: false,
    data: null,
  },
  {
    name: 'CreateDialog',
    isOpen: false,
    data: null,
  },
  {
    name: 'PublishDialog',
    isOpen: false,
    data: null,
  },
  {
    name: 'ProjectDialog',
    isOpen: false,
    data: null,
  },
  {
    name: 'SyndicatedDialog',
    isOpen: false,
    data: null,
  },
  {
    name: 'AssignUserGroupDialog',
    isOpen: false,
    data: null,
  },
  {
    name: 'PublishProjectDialog',
    isOpen: false,
    data: null,
  },
  {
    name: 'ActionsGroupDialog',
    isOpen: false,
    data: null,
  },
  {
    name: 'RejectDialog',
    isOpen: false,
    data: null,
  },
] as const // we need const for better typescript completion of 'DialogsName'

export type DialogsName = typeof initialDialogsState[number]['name'] // will get all the available dialogs' name

export type DialogReducerState = {
  name: string
  isOpen: boolean
  data: any
}

export type DialogReducerAction = {
  name: string
  type: string
  payload?: any
}

enum ActionKind {
  Show = 'SHOW',
  Hide = 'HIDE',
}

// only pattern for dispatch -> dispatch(getAction(...))
export const getAction = (
  name: DialogsName,
  type: keyof typeof ActionKind,
  payload: any = null
): DialogReducerAction => {
  return {
    name,
    type: ActionKind[type],
    payload,
  }
}

export const getDialogFromName = (
  state: readonly DialogReducerState[],
  name: DialogsName
) => state.find((dialog) => dialog.name === name) as DialogReducerState

const dialogReducer = (
  state: DialogReducerState[] | typeof initialDialogsState,
  action: DialogReducerAction
) => {
  switch (action.type) {
    case ActionKind.Show:
      return state.map((dialog) => {
        if (dialog.name === action.name) {
          return {...dialog, isOpen: true, data: action.payload}
        } else {
          return dialog
        }
      })
    case ActionKind.Hide:
      return state.map((dialog) => {
        if (dialog.name === action.name) {
          return {...dialog, isOpen: false, data: null}
        } else {
          return dialog
        }
      })
    default:
      return state
  }
}

export default dialogReducer
