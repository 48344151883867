import Section from '../shared/Section'
import {
  InlineTextWithVariables,
  RichtextWithVariables,
} from '../shared/SectionsWithVariables'
import SectionImage from '../shared/SectionImage'

const Closer = (props) => {
  const {
    title,
    subtitle,
    description,
    image,
    color_scheme,
    className,
    css_overrides,
    color_scheme_id_override,
    lucid_page_section_id,
    meta,
    cms,
  } = props

  return (
    <Section
      cms={cms}
      css_overrides={css_overrides}
      color_scheme_id_override={color_scheme_id_override}
      meta={meta}
      lucid_page_section_id={lucid_page_section_id}
      className={className + ' section closer-section'}
      name="closer"
      colorSchemeId={color_scheme}
    >
      <div className="section-container">
        <div className="section-content">
          <div className="row">
            <div className="column closer-image">
              <SectionImage
                name="image"
                src={image?.src}
                alt={image?.alt}
                // width={image?.width}
                // height={image?.height}
                cms={cms}
              />
            </div>
            <div className="column">
              <div className="section-heading">
                <h2>
                  <span classnName="heading-1">
                    <InlineTextWithVariables
                      cms={cms}
                      name="title"
                      value={title}
                    />
                  </span>
                  <span classnName="heading-2">
                    <InlineTextWithVariables
                      cms={cms}
                      name="subtitle"
                      value={subtitle}
                    />
                  </span>
                </h2>
              </div>
              <div className="section-richtext paragraph">
                <RichtextWithVariables
                  cms={cms}
                  name="description"
                  richtext={description}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="section-background"></div>
    </Section>
  )
}

Closer.StyleCore = {
  config: {},
  target: {
    componentName: Object.keys({Closer})[0].toLowerCase(),
  },
  css: '',
}

export default Closer
