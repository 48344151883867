import React, {useEffect, useState} from 'react'
import styled from 'styled-components'
import WizardLayout from '../../../../shared/WizardLayout'

interface NewProjectWizardProps {
  showDialog: boolean
  closeDialog: () => void
  onSave: () => void
}

const StyledSpan = styled.span`
  margin-right: 20px;
  color: ${(props) => props.color || 'black'};
`

const NewProjectWizard = (props: NewProjectWizardProps) => {
  const [source, setSource] = useState('syndicated-vision')
  const [selectedPages, setSelectedPages] = useState<number[]>([])
  const [synPages, setSynPages] = useState<{ [key: string]: any }[]>([])

  useEffect(() => {
    fetch(`/api/get-syndicated-pages?source=${source}`).then((pages) =>
      pages.json().then((res) => setSynPages(res.result.pages))
    )
  }, [source])

  const handleChange = (pageId: number) => {
    if (selectedPages.includes(pageId)) {
      setSelectedPages([...selectedPages.filter((id) => id !== pageId)])
    } else {
      setSelectedPages([...selectedPages, pageId])
    }
  }
  const selectedAllPages = () => {
    if (!selectedPages.length) {
      setSelectedPages(
        synPages.reduce<number[]>((acc, curr) => [...acc, curr.id], [])
      )
    } else {
      setSelectedPages([])
    }
  }

  return (
    <WizardLayout {...props} title="Create new Syndicated Pages">
      <div>
        <label htmlFor="syndicated_source">Choose the source: </label>
        <select
          id="syndicated_source"
          name="syndicated-source"
          onChange={(e) => setSource(e.target.value)}
          defaultValue="syndicated-vision"
        >
          <option value="syndicated-alternative-medicine">
            syndicated-alternative-medicine
          </option>
          <option value="syndicated-cosmetic">syndicated-cosmetic</option>
          <option value="syndicated-cosmetic-james-flux-v2">
            syndicated-cosmetic-james-flux-v2
          </option>
          <option value="syndicated-dentistry">syndicated-dentistry</option>
          <option value="syndicated-dentistry-flux-v2">
            syndicated-dentistry-flux-v2
          </option>
          <option value="syndicated-dermatology">syndicated-dermatology</option>
          <option value="syndicated-law">syndicated-law</option>
          <option value="syndicated-vision">syndicated-vision</option>
        </select>
      </div>
      <div>
        <h4>Selected pages will be copied to the current site</h4>
        <button onClick={selectedAllPages} style={{marginBottom: '10px'}}>
          Select all
        </button>
        {synPages.map((page) => (
          <div key={page.id} style={{marginBottom: '5px'}}>
            <input
              type="checkbox"
              value={page.id}
              checked={selectedPages.includes(page.id)}
              onChange={(e) => handleChange(Number(e.target.value))}
              style={{transform: 'scale(1.2)', margin: '0 10px'}}
            />
            <StyledSpan>{page.name}</StyledSpan>
          </div>
        ))}
      </div>
      <div>
        <h4>Recap</h4>
        <div style={{marginBottom: '10px'}}>
          <span>The source chosen: {source}</span>
        </div>
        {!!selectedPages.length &&
          synPages
            .filter((page) => selectedPages.includes(page.id))
            .reduce<string[]>((acc, curr) => [...acc, curr.name], [])
            .map((name, idx) => (
              <div key={idx}>
                <span>{name}</span>
              </div>
            ))}
      </div>
    </WizardLayout>
  )
}

export default NewProjectWizard
