import {BlocksControls} from '@einsteinindustries/react-tinacms-inline'
import SchemaReview from '../shared/SchemaReview'

// TODO: fix default item not showing

// export const templateName = 'reviews'

// TODO: Use actual `cms`

const schemaValues = {
  nameLocalBusiness: 'Practice Name',
  imageLocalBusiness: 'https://placeimg.com/200/200/tech',
  telephoneLocalBusiness: '(866) 585-2157',
  streetAddressLocalBusiness: '6825 Flanders Dr Ste 110',
  addressLocalityLocalBusiness: 'San Diego',
  addressRegionLocalBusiness: 'CA',
  postalCodeLocalBusiness: '92121',
  addressCountryLocalBusiness: 'USA',
}

// Templates to use in the parent's block config and the InlineBlocks component
const itemTemplates = {
  review: {
    label: 'Review Item',
    defaultItem: {
      name: 'Albert Einstein',
      rating: 5,
      review:
        'Lorem ipsum dolor sit amet consectetur adipisicing elit. Adipisci expedita doloremque temporibus ipsum illo vero aut voluptas laborum ex dignissimos numquam at odio.',
      avatar_url: 'https://ui-avatars.com/api/?size=70&name=Albert+Einstein',
      avatar_alt: 'Albert Einstein',
      source_logo: 'https://s3.amazonaws.com/flextemplates/fragments/review/google-logo.svg',
      source_logo_alt: 'Google',
      source_link_text: 'Google',
      source_link_url: 'https://www.google.com',
    },
    itemProps: (item) => {
      if (!item.name) {
        return
      }

      return {label: `Review: ${item.name}`}
    },
    fields: [
      {
        name: 'name',
        label: 'Name',
        component: 'text',
      },
      // TODO: Upgrade to Tina 0.41 to use new date field
      // {
      //   name: 'date',
      //   label: 'Date',
      //   component: 'date',
      //   dateFormat: 'MMMM DD, YYYY',
      //   timeFormat: false,
      // },
      {
        name: 'date',
        label: 'Date',
        component: 'text',
      },
      {
        name: 'location',
        label: 'Location',
        component: 'text',
      },
      {
        name: 'rating',
        label: 'Rating',
        component: 'radio-group',
        variant: 'radio',
        direction: 'vertical',
        options: [
          {label: '★☆☆☆☆', value: 1},
          {label: '★★☆☆☆', value: 2},
          {label: '★★★☆☆', value: 3},
          {label: '★★★★☆', value: 4},
          {label: '★★★★★', value: 5},
        ],
      },
      {
        name: 'review',
        label: 'Review',
        component: 'html',
      },
      {
        name: 'avatar_url',
        label: 'Avatar Image',
        component: 'image',
        description: 'Image description',
        clearable: true,
        parse: (media) => {
          if (!media) {
            return
          }
          // console.log('image field config - parse:', media)
          return `https://${process.env.NEXT_PUBLIC_IMGIX_READ_URL}/${media.directory}/${media.filename}`
        },
        previewSrc: (src) => {
          return src
        },
      },
      {
        name: 'avatar_alt',
        label: 'Avatar Alt Text',
        component: 'text',
      },
      {
        name: 'source_logo',
        label: 'Source Logo',
        component: 'image',
        clearable: true,
        parse: (media) => {
          if (!media) {
            return
          }
          // console.log('image field config - parse:', media)
          return `https://${process.env.NEXT_PUBLIC_IMGIX_READ_URL}/${media.directory}/${media.filename}`
        },
        previewSrc: (src) => {
          return src
        },
      },
      {
        name: 'source_alt',
        label: 'Source Alt Text',
        component: 'text',
      },
      {
        name: 'source_link_text',
        label: 'Source Link Text',
        component: 'text',
      },
      {
        name: 'source_link_url',
        label: 'Source Link URL',
        component: 'text',
      },
    ],
  },
}

export const REVIEWS_BLOCKS = {
  review: {
    Component({_name, index, data, cms}) {
      return (
        <ItemWrapper index={index}>
          <SchemaReview
            datePublishedReview={data.date}
            descriptionReview={data.description}
            imageReview={data.avatar_url}
            namePerson={data.name}
            bestRating="5"
            worstRating="1"
            ratingValue={data.rating}
            nameLocalBusiness={schemaValues.nameLocalBusiness}
            imageLocalBusiness={schemaValues.imageLocalBusiness}
            telephoneLocalBusiness={schemaValues.telephoneLocalBusiness}
            streetAddressLocalBusiness={schemaValues.streetAddressLocalBusiness}
            addressLocalityLocalBusiness={
              schemaValues.addressLocalityLocalBusiness
            }
            addressRegionLocalBusiness={schemaValues.addressRegionLocalBusiness}
            postalCodeLocalBusiness={schemaValues.postalCodeLocalBusiness}
            addressCountryLocalBusiness={
              schemaValues.addressCountryLocalBusiness
            }
          />
          {/*<div className="reviews-item">*/}
            <div className="reviews-item__header">
              <img
                  src={data.avatar_url}
                  alt={data.avatar_alt}
                className="reviews-item__avatar-wrapper"
              />
              <div className="reviews-item__info">
                <p className="reviews-item__name">
                  {data.name}
                </p>
                <p className="reviews-item__location_id ">
                  {data.location}
                </p>
                <p className="reviews-item__date">
                  {data.date}
                </p>
                <div className="reviews-item__rating">
                  <span className="reviews-item__stars" style={{
                    width: data.rating * 20 + '%',
                  }}></span>
                </div>
              </div>
              <a className="reviews-item__source" href={data.source_link_url} target="_blank" rel="noreferrer">
                <img
                    src={data.source_logo}
                    alt={data.avatar_alt}
                    className="reviews-item__avatar-wrapper"
                />
              </a>
            </div>
            <div className="reviews-item__description">
              <p>
                {data.review}
              </p>
              <a className="reviews-item__link" href={data.source_link_url} target="_blank" rel="noreferrer">
                View on {data.source_link_text}
              </a>
            </div>
          {/*</div>*/}
        </ItemWrapper>
      )
    },
    template: itemTemplates.review,
  },
}

function ItemWrapper(props) {
  return (
      <div className="reviews-item">
      <BlocksControls
        index={props.index}
        focusRing={{offset: 16, borderRadius: 4}}
        insetControls={false}
      >
        {props.children}
      </BlocksControls>
      </div>
  )
}
