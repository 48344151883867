import {GlobalOptions} from './GlobalOptions'

export const FlexBoxAlignmentOptions = [
    {
        label: 'Normal',
        value: 'normal'
    },
    {
        label: 'Center',
        value: 'center'
    },
    {
        label: 'Left',
        value: 'left'
    },
    {
        label: 'Right',
        value: 'right'
    },
    {
        label: 'Start',
        value: 'start'
    },
    {
        label: 'End',
        value: 'end'
    },
    {
        label: 'Flex Start',
        value: 'flex-start'
    },
    {
        label: 'Flex End',
        value: 'flex-end'
    },
    {
        label: 'Space Between',
        value: 'space-between'
    },
    {
        label: 'Space Around',
        value: 'space-around'
    },
    {
        label: 'Space Evenly',
        value: 'space-evenly'
    },
    {
        label: 'Stretch',
        value: 'stretch'
    },
    ...GlobalOptions
]

export const FlexBoxWrapOptions = [
    {
        label: 'Wrap',
        value: 'wrap'
    },
    {
        label: 'No Wrap',
        value: 'nowrap'
    },
    {
        label: 'Wrap Reverse',
        value: 'wrap-reverse'
    }]