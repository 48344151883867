import styled from 'styled-components'
import {useContext, useEffect, useState} from 'react'
import {LucidSiteContext} from '@/src/state/site/Store'
import useSWR, {mutate as globalMutate, SWRResponse} from 'swr'
import {GROUP_MENUS, MENUS} from '@/graphql/queries'
import {lucidDataFetcherV2} from '@/graphql/fetchers'
import {MenuEditor} from '@/components/managers/MenuManager/features/menu/MenuEditor'
import {Menu} from '@/components/shared/types'
import {DnDPayload, onMenuChangeActionProps} from '@/components/managers/MenuManager/utils/types'
import {
  deleteToolbarItem,
  handleDeleteMenu,
  handleNewLink,
  handleNewMenu,
  handleNewMenuGroup,
  menuItemsFromPayload,
  storeMenuItems
} from '@/components/managers/MenuManager/utils/menuStorage'


const MenuManagerContainer = styled.div`
  display: flex;
  flex-flow: row wrap;
  overflow: scroll;
`

export function MenuManager() {
  const [{menus, pages, site_build_id}, siteDispatch] = useContext(LucidSiteContext)

  const [loading, setLoading] = useState(true)
  const [isLocked, setIsLocked] = useState(false)
  const [selectedMenu, setSelectedMenu] = useState<Menu | null>(menus[0] ?? null)
  const [selectedMenuHasChanged, setSelectedMenuHasChanged] = useState(false)

  const {
    data: {data: {templateMenuItems, isDefault}},
    mutate,
  }: SWRResponse = useSWR(
    [GROUP_MENUS, {site_build_id}],
    lucidDataFetcherV2,
    {
      fallbackData: {
        data: {
          templateMenuItems: [],
          isDefault: true
        },
      },
    }
  )

  async function onChangeSelectedMenu(keys: any) {
    const selectedMenu = menus.find(m => m.name === keys.currentKey)
    if (selectedMenu) {
      if (selectedMenuHasChanged) {
        await mutator(selectedMenu.name)
      }
      setSelectedMenuHasChanged(false)
      setSelectedMenu(selectedMenu)
    }
  }

  async function mutator(selectedMenu: string | null) {
    const {data: {templateMenuItems}} = await mutate()
    const {data: {menus}} = await globalMutate([MENUS, {site_build_id}])
    const updatedSelectedMenu = menus.find((m: Menu) => m.name === selectedMenu)
    setSelectedMenu(updatedSelectedMenu ?? menus[0])
  }

  useEffect(() => {
    setLoading(typeof isDefault !== 'undefined')
  }, [isDefault])

  /**
   * TODO: implement the cancel button feature
   *  We will use the previous data in selectedMenu to reconstruct the previous version
   */
  async function onMenuChange({type, payload}: onMenuChangeActionProps & {payload: DnDPayload}) {
    if (!payload) return
    setIsLocked(true)
    switch (type) {
      case 'update':
        if (!selectedMenu) return
        const [menuItems, originalMenuItems] = menuItemsFromPayload(payload, selectedMenu.menu_items)
        // We are updating the state right away before mutating to present a seamless drag/drop UX
        // We then proceed to mutate and just after that, we update the menu with the new version from the server
        setSelectedMenu({
          ...selectedMenu, menu_items: menuItems
        })
        const childrenUpdated = typeof payload.newItems?.[0] !== 'undefined'
          && payload.newItems?.[0].parent_menu_item_id !== null
          && typeof payload.newItems?.[0].parent_menu_item_id !== 'undefined' ||
          typeof payload.originalItems?.[0] !== 'undefined'
          && payload.originalItems?.[0].parent_menu_item_id !== null
          && typeof payload.originalItems?.[0].parent_menu_item_id !== 'undefined'

        await storeMenuItems(menuItems, originalMenuItems, childrenUpdated)
        await mutator(selectedMenu.name)
        break
      case 'delete':
        setLoading(true)
        await deleteToolbarItem(payload)
        await mutator(null)
        setLoading(false)
        break
      case 'deleteMenu':
        setLoading(true)
        await handleDeleteMenu(selectedMenu?.id)
        await mutator(null)
        setLoading(false)
        break
      case 'add':
        setLoading(true)
        await handleNewMenu(payload.name, site_build_id)
        await mutator(payload.name)
        setLoading(false)
        break
      case 'addGroup':
        setLoading(true)
        await handleNewMenuGroup(payload.name, site_build_id)
        await mutator(selectedMenu?.name ?? null)
        setLoading(false)
        break
      case 'addLink':
        setLoading(true)
        await handleNewLink(payload.name, payload.link, site_build_id)
        await mutator(selectedMenu?.name ?? null)
        setLoading(false)
        break
    }
    setIsLocked(false)
  }

  return (
    <MenuManagerContainer>
      <MenuEditor
        isLocked={isLocked}
        hasChanged={selectedMenuHasChanged}
        selectedMenu={selectedMenu}
        siteBuildId={site_build_id}
        pages={pages}
        menus={menus}
        templateMenuItems={templateMenuItems}
        loading={loading}
        onMenuChange={onMenuChange}
        onChangeSelectedMenu={onChangeSelectedMenu}
      />
    </MenuManagerContainer>
  )
}
