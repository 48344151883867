import {
  ListboxInput,
  ListboxButton,
  ListboxPopover,
  ListboxList,
  ListboxOption,
} from '@reach/listbox'
import '@reach/listbox/styles.css'

interface FilterBoxProps {
  options: string[]
  value: string
  onChange: (value: string) => void
  defaultValue?: string
}

const FilterBox = ({
  defaultValue = '',
  options,
  value,
  onChange,
}: FilterBoxProps) => (
  <ListboxInput defaultValue={defaultValue} value={value} onChange={onChange}>
    <ListboxButton />
    <ListboxPopover style={{zIndex: 9001}}>
      <ListboxList>
        {defaultValue ? (
          <ListboxOption key={defaultValue} value={'all'}>
            {defaultValue}
          </ListboxOption>
        ) : (
          <></>
        )}
        {options.map((option) => (
          <ListboxOption key={option} value={option}>
            {option}
          </ListboxOption>
        ))}
      </ListboxList>
    </ListboxPopover>
  </ListboxInput>
)

export default FilterBox
