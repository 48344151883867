import React from 'react'
import {BlockComponentProps, InlineBlocks, InlineBlocksProps} from '@einsteinindustries/react-tinacms-inline'

export type LucidBlockComponentProps = React.FC<BlockComponentProps & { cms: boolean }>

export type LucidTransformedBlock = {
  component: LucidBlockComponentProps,
  data: Record<string, any>,
  name: string,
}

interface LucidInlineBlocksProps extends InlineBlocksProps {
  itemProps: { [key: string]: any }
}

export default function LucidInlineBlocks(props: LucidInlineBlocksProps) {
  if (props.itemProps?.cms) {
    return <InlineBlocks {...props} />
  }

  if (typeof props.itemProps !== 'object') throw new Error(`Missing itemProps for ${props.name}`)

  const components: LucidTransformedBlock[] = []

  for (const [prospectTemplate, data] of Object.entries(props.itemProps)) {
    if (prospectTemplate === props.name) {
      for (const {_template, ...templateBuild} of data) {
        /**
         * In some instances, when working with a front end component, initial data may not be available
         * This is mostly not a problem except for navigation and maybe a couple others
         * that rely on current db data to generate menus and links
         *
         * We might be able to remove this check if we instead pass all db content
         * from the backend as initial state
         */
        if (typeof props.blocks[_template] === 'undefined') continue
        const data = {...templateBuild, _template}
        components.push({component: props.blocks[_template].Component, data, name: _template})
      }
    }
  }

  return (
    <div className={props.className}>
      {components.map((block, i) => {
        const BlockComponent = block.component
        return (
          <BlockComponent
            name={block.name}
            data={block.data}
            key={i}
            index={i}
            cms={false}
          />
        )
      })}
    </div>
  )
}
