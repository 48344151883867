export const UnitOptions = [
    {value: 'px', label: 'px'},
    {value: 'em', label: 'em'},
    {value: 'rem', label: 'rem'},
    {value: '%', label: '%'},
    {value: 'vh', label: 'vh'},
    {value: 'vw', label: 'vw'},
    {value: 'vmin', label: 'vmin'},
    {value: 'vmax', label: 'vmax'},
    {value: 'ex', label: 'ex'},
    {value: 'ch', label: 'ch'},
    {value: 'cm', label: 'cm'},
    {value: 'mm', label: 'mm'},
    {value: 'in', label: 'in'},
    {value: 'pt', label: 'pt'},
    {value: 'pc', label: 'pc'},
    {value: 'q', label: 'q'},
    {value: 'cm', label: 'cm'},
    {value: 'mm', label: 'mm'},
    {value: 'in', label: 'in'},
    {value: 'pt', label: 'pt'},
    {value: 'pc', label: 'pc'},
    {value: 'q', label: 'q'},
    {value: 'cm', label: 'cm'},
    {value: 'mm', label: 'mm'},
    {value: 'fr', label: 'fr'},
    {value: 'deg', label: 'deg'},
    {value: 'grad', label: 'grad'},
    {value: 'rad', label: 'rad'},
    {value: 'turn', label: 'turn'},
]