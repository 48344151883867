/**
 * Set to true to prevent StyleCore warnings from appearing in the console
 * If working on anything related to StyleCore, it is advised to set this to false
 */
import {
  AcceptedFieldContent,
  DefaultValuesForStyles,
  FieldConfig,
  FieldConfigForStyleCore
} from '@/components/shared/externalTypes'
import {TypographyElement, TypographyGroup, TypographyGroupMap} from '@/components/editor/typography/types'
import {Dispatcher} from '@/src/state/site/Store'
import createInitialStyleCoreElementMap from '@/tina/blocks/createInitialStyleCoreMap'
import {useContext, useMemo, useState} from 'react'
import {StyleCoreContext, StyleCoreState} from '@/src/state/site/StyleCoreStore'
import {Font} from 'use-google-fonts/lib/types'
import useGoogleFonts from 'use-google-fonts'
import Sections from '@/components/sections'
import deepEqual from '@/src/utils/deepEqual'
import {ADD_TYPOGRAPHY, DELETE_TYPOGRAPHY, UPDATE_TYPOGRAPHY} from '@/graphql/mutations'
import {lucidDataFetcherV2} from '@/graphql/fetchers'
import {useLucidContext} from '@/src/state/ServerSideStore'
import useSWR, {SWRResponse} from 'swr'
import {GET_SITE_BUILD_TYPOGRAPHY, SITE_BUILD_COLOR_SCHEMES} from '@/graphql/queries'
import {SiteThemeContext, ThemeState} from '@/src/state/site/ThemeStore'
import {updateSiteBuildContent} from '@/components/editor/shared/updateSiteBuildContent'
import {Contents} from '@/components/shared/types'

export const MUTE_WARNINGS = (process.env.NEXT_PUBLIC_MUTE_WARNINGS === 'true') ?? false

export enum OverloadSlots {
  DEFAULT = 0,
}

export const SITE_DEFAULT_FORM_ID = 'site-default'
export type StyleCoreOverload = {
  [key: string]: string | number | boolean | StyleCoreOverload
}
export type TinaFunctionalComponent<P> = React.FunctionComponent<P> & {
  (...args: any): JSX.Element | null,
  StyleCore?: StyleCoreElement
}
export type StyleCoreTarget = {
  componentName: string,
  identifier?: string,
}
export type StyleCorePayload = {
  // Any data that needs to be passed to the component
  custom?: {
    [key: string]: any
  }
}
export type StyleCoreElement = {
  target: StyleCoreTarget,
  css: string,
  config: FieldConfigForStyleCore,
  overloads?: StyleCoreOverload[],
  payload?: StyleCorePayload,
  output?: {
    selectors: string[],
    css: string,
  }
}

export type ThemeAPIRequestBody = {
  theme: string,
  components?: string[]
}


export type StyleDispatcherUpdateType = Partial<StyleCoreElement>
export type StyleCoreMap = Map<string, StyleCoreElement>
export type StyleCoreRenderedMapElement = {
  css: string,
  selectors: string[]
}
export type StyleCoreRenderedMap = Map<string, StyleCoreRenderedMapElement>

type TypographyComponentOptionInput = {
  label: string,
  value: string
}

type TypographyFontHookReturn = {
  fonts: Font[],
  options: {
    [key: string]: string | boolean
  }
}

type TypograpyGQLComponent = {
  name: string,
  primary_font_family: string,
  fallback_font_family?: string,
  options?: TypographyComponentOptionInput[],
  id?: number
}

type TypographyGQL = {
  id?: number,
  site_build_id?: number,
  name: string,
  description: string,
  is_default: boolean,
  components: TypograpyGQLComponent[]
}

/**
 * The following CSS is applied to all components that are not explicitly styled by StyleCore
 * Any component that has styling interference should be targeted here.
 */
const STYLING_RESET_TARGETS = `
  {target} p, blockquote {
        all: unset;
  }
`

/**
 * StyleCore is a system for managing styles in a React app.
 * Extensive documentation is available here https://www.notion.so/einsteinindustries/StyleCore-b9b1fef4352b4e10a523517d6e4b7d62?pvs=4
 *
 */

function extractDefaults(obj: FieldConfigForStyleCore | AcceptedFieldContent): DefaultValuesForStyles | AcceptedFieldContent {
  const structure: DefaultValuesForStyles = {}
  if (typeof obj === 'string' || typeof obj === 'number' || typeof obj === 'boolean') {
    return obj
  }
  for (const [key, value] of Object.entries(obj)) {
    if (key === 'default') {
      return `${value}${(obj as FieldConfigForStyleCore)?.unit ?? ''}` as AcceptedFieldContent
    }
    if (key === 'content') {
      if (typeof value === 'object' && value !== null) {
        return extractDefaults(value as FieldConfigForStyleCore)
      }
    } else {
      structure[key] = extractDefaults(value as FieldConfigForStyleCore)
    }
  }
  return structure
}

function getTargetName(target: string[]) {
  return target.map((item, index) => {
    return `.${item}`
  }).join(' ')
}

function applyAndReplaceTargets(css: string, targetName: string) {
  if (css.match(/{target}/)) {
    css = css.replaceAll(/{target}/g, targetName)
  } else if (process.env.NODE_ENV === 'development' && !MUTE_WARNINGS) {
    console.warn(`Lucid StyleCore: The CSS string for ${targetName} does not contain a {target} and will have a global effect, which may be unintended.`)
  }
  return css
}

function convertTypographyElementToCss(element: TypographyElement) {
  const {fonts, ...rest} = element.configuration
  let css = Object.entries(rest).map(([key, value]) => {
    return `${key.split(/(?=[A-Z])/).map(s => s.toLowerCase()).join('-')}: ${value};`
  }).join(' ')
  if (element.configuration.fonts) {
    css += `
      font-family: ${element.configuration.fonts.primary.name}, ${element.configuration.fonts.fallback.name}, sans-serif;
    `
  }
  return css
}

function RenderTypography(overloads: StyleCoreOverload[], typographyMap: TypographyGroupMap) {
  let typographyGroup: TypographyGroup
  try {
    typographyGroup = typographyMap.entries().next().value[1]
  } catch (e) {
    return ''
  }
  for (const overload of overloads) {
    // TODO: simplify this to make TS shut up
    if (typeof overload?.typography === 'object' && typeof overload?.typography?.typographyOverride === 'string') {
      const foundElement = typographyMap.get(overload?.typography?.typographyOverride)
      if (foundElement) {
        typographyGroup = foundElement as TypographyGroup
      }
    }
  }

  let css = ''
  for (const [, value] of Object.entries(typographyGroup.elements)) {
    css += `
      {target} .${Helpers.selectors.getTypographyBaseSelector(value)} {
         ${convertTypographyElementToCss(value)}
      }
      
    `
  }
  return css
}

export function addCSSReset(currentCSS: string) {
  return `
    ${STYLING_RESET_TARGETS}
    ${currentCSS}
  `
}

export function RenderStyle({target, css, config, overloads}: StyleCoreElement, typographyMap: TypographyGroupMap) {
  const {componentName, identifier} = target

  function replaceVariables(obj: any, path: string) {
    for (const key in obj) {
      let value = obj[key]
      if (typeof value === 'object' && value !== null) {
        replaceVariables(value, (path === '' ? key : path + '.' + key))
      } else {
        if (value === null) {
          continue
        }
        const variable = `\\{${path + '\\.' + key}\\s*\\?\\s*(\\{[^{}]*\\}|[^{}]+)\\s*:\\s*(\\{[^{}]*\\}|[^{}]+)\\}`
        if (css.match(new RegExp(variable, 'g'))) {
          if (typeof value !== 'boolean') {
            value = value === 'true'
          }
          css = css.replaceAll(new RegExp(variable, 'g'), value ? '$1' : '$2')
        } else {
          css = css.replaceAll(new RegExp('{' + path + '.' + key + '}', 'g'), value)
        }
      }
    }
  }

  function applyOverloads(replaceVariables: (obj: any, path: string) => void, overloads: StyleCoreOverload[] | undefined) {
    if (overloads) {
      for (const overload of overloads.reverse()) {
        replaceVariables(overload, '')
      }
    }
  }

  const overloadsGrouped = [extractDefaults({...config}) as DefaultValuesForStyles, ...(overloads ?? [])]

  const fontCSSString = RenderTypography(overloadsGrouped, typographyMap)

  css += fontCSSString

  css += addCSSReset(css)

  css = applyAndReplaceTargets(css, getTargetName([Helpers.selectors.getSelectorID(target)]))

  applyOverloads(replaceVariables, overloadsGrouped)

  if (css.match(/{target}/)) {
    throw new Error(`Lucid StyleCore: The following targets for ${componentName}${typeof identifier !== 'undefined' ? '.' + identifier : ''} were not found in the config or overloads: {target}`)
  }

  if (css.match(/{.*}/) && process.env.NODE_ENV === 'development' && !MUTE_WARNINGS) {
    console.warn(`Lucid StyleCore: The following variables for ${componentName}${typeof identifier !== 'undefined' ? '.' + identifier : ''} 
    were not found in the config or overloads: ${css.match(/{.*}/)}. This line of CSS will be removed.`)
  }

  css = css.replaceAll(/(^.*?{[^{}]+?\.(?!\d)[^{}]+?}.*?$)/gm, '')

  return `${css}`
}

export function convertTargetToKey(target: StyleCoreTarget) {
  return `${target.componentName}${target.identifier ? '.' + target.identifier : ''}`
}

async function fetchSiteTheme(themeName:string, components = []) {
  let body: ThemeAPIRequestBody = {
    theme: themeName,
    components: components
  }
  if (components.length === 0) {
    body = {
      theme: themeName
    }
  }
  const response = await fetch('/api/themes', {
    method: 'POST',
    body: JSON.stringify(body),
  })
  if (response.status === 200) {
    return await response.json()
  }
  return null
}

async function initializeTheme(theme: string, themeState: ThemeState, dispatch: Dispatcher) {
  const themeContents = await fetchSiteTheme(theme)
  if (themeContents) {
    dispatch({
      type: 'UPDATE',
      payload: {
        themeName: theme,
        components: themeContents
      }
    })
  }
}

export function initializeStyleCoreSections(StyleCore: {
  map: StyleCoreMap
}, dispatch: Dispatcher, cms :boolean) {
  if (StyleCore.map.size === 0 && cms) {
    StyleCore.map = createInitialStyleCoreElementMap()
    dispatch({
      type: 'UPDATE',
      payload: {
        StyleCore
      }
    })
  }
}

export function useStyleCoreDispatcher(cms:boolean) {
  const [{StyleCore}, dispatch] = useContext(StyleCoreContext)
  initializeStyleCoreSections(StyleCore, dispatch, cms)
  if (!cms) return (config: StyleDispatcherUpdateType) => {}
  return (config: StyleDispatcherUpdateType) => {
    if (typeof config.target === 'undefined') {
      throw new Error('StyleCore: The target is undefined')
    }

    const targetAsKey = convertTargetToKey(config.target)

    // We check if the target is already registered
    if (StyleCore.map.has(targetAsKey)) {

      // If the target is a global target (no identifier), we update all the targets with the same name, we update the first overload
      if (typeof config.target.identifier === 'undefined' && typeof config.overloads !== 'undefined') {
        for (const [key, value] of StyleCore.map.entries()) {
          if (key === targetAsKey) {
            if (typeof value.overloads === 'undefined') {
              value.overloads = []
            }
            value.overloads[OverloadSlots.DEFAULT] = config.overloads[0]
          }
        }
      }

      // If the target is a local target (with identifier), we add an overload
      if (typeof config.target.identifier !== 'undefined') {

        const recalledTarget = StyleCore.map.get(targetAsKey) as StyleCoreElement

        // We then add the overload if the field is not initialized
        if (typeof recalledTarget?.overloads === 'undefined') {
          recalledTarget!.overloads = []
        }
        // If there is no default overload, we add it
        if (typeof recalledTarget?.overloads[OverloadSlots.DEFAULT] === 'undefined') {
          recalledTarget!.overloads[OverloadSlots.DEFAULT] = {}
        }
        recalledTarget!.overloads[OverloadSlots.DEFAULT] = config.overloads![0]
      }

      // We dispatch these changes
      dispatch({
        type: 'UPDATE',
        payload: {
          StyleCore
        }
      })
    } else {

      if (typeof config.target.identifier !== 'undefined' && (typeof config.config === 'undefined' || typeof config.css === 'undefined')) {
        const targetWithoutIdentifier = StyleCore.map.get(convertTargetToKey({
          componentName: config.target.componentName
        }))
        if (typeof targetWithoutIdentifier === 'undefined') {
          throw new Error('Lucid StyleCore: A new partial target cannot be registered without a global target.')
        }


        config.css = config.css ?? targetWithoutIdentifier.css
        config.config = config.config ?? targetWithoutIdentifier.config
      }

      if (typeof config.css === 'undefined') {
        throw new Error('Lucid StyleCore: A new target cannot be registered without a CSS string.')
      }

      if (typeof config.config === 'undefined') {
        throw new Error('Lucid StyleCore: A new target cannot be registered without a CSS string.')
      }

      // If the target is not registered, we add it
      StyleCore.map.set(targetAsKey, config as StyleCoreElement)
      dispatch({
        type: 'UPDATE',
        payload: {
          StyleCore
        }
      })
    }
  }
}

export function useStyleCoreThemes(component: string) {
  const [theme] = useContext(SiteThemeContext)
  return theme.components[component as keyof typeof theme.components] ?? ''
}

export async function useStyleCoreTheme(themeName: string, forceUpdate: boolean = false) {
  const [theme, dispatch] = useContext(SiteThemeContext)
  if (theme.themeName !== themeName || forceUpdate) {
    await initializeTheme(themeName, theme, dispatch)
  }
}

export function useStyleCoreMap(cms:boolean) {
  const [{StyleCore}, dispatch] = useContext(StyleCoreContext)
  initializeStyleCoreSections(StyleCore, dispatch, cms)
  if (!cms) return {
    get: (target: StyleCoreTarget) => undefined,
    set: (target: StyleCoreTarget, value: StyleCoreElement) => {},
    getRoot: (target: StyleCoreTarget) => '',
    setRoot: (target: StyleCoreTarget, config: StyleCoreElement) => {},
    asArray: () => []
  }
  return {
    get: (target: StyleCoreTarget) => StyleCore.map.get(convertTargetToKey(target)),
    set: (target: StyleCoreTarget, value: StyleCoreElement) => StyleCore.map.set(convertTargetToKey(target), value),
    getRoot: (target: StyleCoreTarget) => StyleCore.map.get(convertTargetToKey({componentName: target.componentName})),
    setRoot: (target: StyleCoreTarget, config: StyleCoreElement) => StyleCore.map.set(convertTargetToKey({componentName: target.componentName}), config),
    asArray: () => Array.from(StyleCore.map.values())
  }
}

export function useStyleCorePayload(target: StyleCoreTarget, cms:boolean) {
  const [{StyleCore}, dispatch] = useContext(StyleCoreContext)
  initializeStyleCoreSections(StyleCore, dispatch, cms)
  return {
    get: () => StyleCore.map.get(convertTargetToKey(target))?.payload,
    set: (value: StyleCorePayload) => {
      const foundTarget = StyleCore.map.get(convertTargetToKey(target)) as StyleCoreElement
      foundTarget.payload = value
    },
    getRoot: () => StyleCore.map.get(convertTargetToKey({componentName: target.componentName}))?.payload,
    setRoot: (value: StyleCorePayload) => {
      const foundTarget = StyleCore.map.get(convertTargetToKey({componentName: target.componentName})) as StyleCoreElement
      foundTarget.payload = value
    }
  }
}

export function useStyleCoreGlobalTheme() {
  const [theme] = useContext(SiteThemeContext)
  return theme.components['global'] ?? ''
}

export function useStyleCorePreRendered() {
  const [{StyleCore}, dispatch] = useContext(StyleCoreContext)
  return StyleCore.rendered.map ?? new Map()
}

export function useStyleCoreRenderer(target: StyleCoreTarget, cms:boolean): [string[], string] {
  const [{StyleCore}, dispatch] = useContext(StyleCoreContext)
  const [themes] = useContext(SiteThemeContext)
  initializeStyleCoreSections(StyleCore, dispatch, cms)

  const targetAsKey = convertTargetToKey(target)

  let foundTarget = StyleCore.map.get(targetAsKey) ? {
    ...StyleCore.map.get(targetAsKey)
  } as StyleCoreElement : undefined

  const captureRender = (data: [string[], string]) => {
    if (typeof target.identifier !== 'undefined') {
      const currentState = StyleCore.rendered.map.get(target.identifier)
      if (typeof currentState === 'undefined' || currentState.css !== data[1]) {
        StyleCore.rendered.map.set(target.identifier, {
          css: data[1],
          selectors: [JSON.stringify(data[0])]
        })
      }
    }
    return data
  }

  const themeCSS = themes.components[target.componentName as keyof typeof themes.components] ?? ''

  if (typeof target.identifier !== 'undefined') {
    const targetWithoutIdentifier = StyleCore.map.get(convertTargetToKey({...target, identifier: undefined}))
    if (typeof targetWithoutIdentifier !== 'undefined') {

      if (typeof foundTarget === 'undefined') {
        foundTarget = targetWithoutIdentifier
      } else {
        const newOverloads = targetWithoutIdentifier.overloads ?? [{}]
        foundTarget.overloads = [newOverloads[0], ...(foundTarget.overloads ?? [])]
      }

    }
  }

  if (typeof foundTarget === 'undefined') {
    if (process.env.NODE_ENV === 'development' && !MUTE_WARNINGS) {
      console.warn(`Lucid StyleCore: The target ${targetAsKey} was not found in the StyleCore map.`)
    }

    return captureRender([[''],themeCSS])
  }
  if (typeof foundTarget.overloads !== 'undefined') {
    foundTarget.overloads = foundTarget.overloads.filter((overload) => Object.entries(overload).length !== 0)
    return captureRender([Helpers.selectors.get(foundTarget.target), themeCSS + RenderStyle(foundTarget, StyleCore.typography.map)])
  }

  if (process.env.NODE_ENV === 'development' && !MUTE_WARNINGS) {
    console.warn(`Lucid StyleCore: The target ${targetAsKey} was not found in the StyleCore map.`)
  }

  return captureRender([[''], themeCSS])

}

export const getFontNamesAsFlatArrayFromStyleCore = ({StyleCore}: StyleCoreState) => {
  const fonts: Font[] = []
  for (const [key, value] of StyleCore.typography.map.entries()) {
    const shouldBeAddedToFonts = (name: string | undefined) => fonts.findIndex((font) => font[0] === name) === -1 && typeof name !== 'undefined'
    value.elements.forEach((element) => {
      if (shouldBeAddedToFonts(element.configuration.fonts.primary.name))
        fonts.push([element.configuration.fonts.primary.name])
      if (shouldBeAddedToFonts(element.configuration.fonts.fallback.name))
        fonts.push([element.configuration.fonts.fallback.name])
    })
  }
  return fonts
}

/**
 * Imports all referenced google fonts
 */
function useStyleCoreTypographyImports(cms: boolean, enabled: boolean): TypographyFontHookReturn {
  const [{StyleCore}, dispatch] = useContext(StyleCoreContext)
  useMemo(() => initializeStyleCoreSections(StyleCore, dispatch, cms), [StyleCore.map.size])
  const [fonts, setFonts] = useState<Font[]>([])

  const fontsGathered = getFontNamesAsFlatArrayFromStyleCore({StyleCore})

  useMemo(() => {
    if (JSON.stringify(fonts) !== JSON.stringify(fontsGathered) && fontsGathered.length > 0) {
      setFonts(fontsGathered)
    }
  }, [JSON.stringify(Array.from(StyleCore.typography.map.entries()))])

  useGoogleFonts(enabled ? fonts : [], {display: 'swap', addBodyClass: true})
  return {
    fonts,
    options: {display: 'swap', addBodyClass: true}
  }
}

export const Helpers = {
  selectors: {
    get: (target: StyleCoreTarget) => {
      const targetClasses = [target.componentName]
      if (typeof target.identifier !== 'undefined') {
        targetClasses.push(`ST${target.identifier}`)
      }
      return targetClasses
    },
    getSelectorID: (target: StyleCoreTarget) => {
      return Helpers.selectors.get(target).join('-')
    },
    getTypographyBaseSelector: (target: TypographyElement) => {
      return target.name.replaceAll(' ', '-').toLowerCase()
    }
  },
  formManager: {
    convertTargetToFormID: (target: StyleCoreTarget) => {
      const targetClasses = Helpers.selectors.get(target)
      return targetClasses.join('::')
    },
    convertFormIDToTarget: (formID: string) => {
      const targetClasses = formID.split('::')
      return {
        componentName: targetClasses[0],
        identifier: targetClasses[1]
      }
    }
  },
  sections: {
    getFormConfigForAllSections: (siteID: number, onSubmit: (values: any) => void) => {
      const dynamicFields: { [key: string]: FieldConfig } = {}
      Object.entries(Sections as unknown as TinaFunctionalComponent<any>).forEach(([, value]) => {
        if (typeof value.StyleCore !== 'undefined' && typeof value.StyleCore.target?.componentName !== 'undefined') {
          dynamicFields[value.StyleCore.target.componentName] = {
            content: value.StyleCore.config
          } as FieldConfig
        }
      })
      return {
        id: SITE_DEFAULT_FORM_ID,
        title: 'Site Style',
        listeners: {
          preventContentTableUpsert: false,
          target: {
            site_id: siteID
          },
          onSubmit
        },
        content: {
          general: {
            content: {
              // global sitewide styles (no section specific)
              nothingHere: {}
            }
          },
          sections: {
            content: dynamicFields

          }
        }
      }
    }
  }
}


function transformGQLRequestToTypographyGroup(input: TypographyGQL) : TypographyGroup {
  return {
    id: `${input.id}`,
    name: input.name,
    description: input.description,
    isDefault: input.is_default,
    elements: input.components.map((component) => {
      return {
        id: `${component.id}`,
        name: component.name,
        configuration: {
          fonts: {
            primary: {
              name: component.primary_font_family
            },
            fallback: {
              name: component.fallback_font_family ?? component.primary_font_family
            }
          },
          ...Object.fromEntries(component.options?.map((option) => {
            return [option.label, option.value]
          }) ?? [])
        }
      } as TypographyElement
    })
  }
}

function useStyleCoreTypographyServerState(cms: boolean, enabled: boolean) {
  const [{StyleCore}, dispatch] = useContext(StyleCoreContext)
  const [SiteStore] = useLucidContext(cms)
  const {
    data,
    mutate,
  }: SWRResponse = useSWR(
    [GET_SITE_BUILD_TYPOGRAPHY, {site_build_id: SiteStore.site_build_id}],
    lucidDataFetcherV2,
    {
      fallbackData: {
        data: {
          ...StyleCore.typography.map,
        },
      },
    }
  )
  if (typeof data.data.typographies === 'undefined' || data.data.typographies === null || !enabled) {
    return
  }
  const typographyMap = new Map<string, TypographyGroup>()
  Object.entries(data.data.typographies).forEach(([key, value]) => {
    const id = (value as TypographyGQL).id
    if (typeof id !== 'undefined') {
      typographyMap.set(String(id), transformGQLRequestToTypographyGroup(value as TypographyGQL))
    }
  })
  let hasChanges = false
  for (const [key, value] of StyleCore.typography.map.entries()) {
    const foundTypographyGroup = typographyMap.get(key)
    if (typeof foundTypographyGroup === 'undefined') {
      hasChanges = true
      break
    }
    if (!deepEqual(foundTypographyGroup, value)) {
      hasChanges = true
      break
    }
  }

  for (const [key, value] of typographyMap.entries()) {
    const foundTypographyGroup = StyleCore.typography.map.get(key)
    if (typeof foundTypographyGroup === 'undefined') {
      hasChanges = true
      break
    }
    if (!deepEqual(foundTypographyGroup, value)) {
      hasChanges = true
      break
    }
  }

  if (!hasChanges) {
    return
  }

  dispatch({
    type: 'UPDATE',
    payload: {
      StyleCore: {
        ...StyleCore,
        typography: {
          map: typographyMap
        }
      }
    }
  })
}

export async function useStyleCoreSiteContents(unmarshalledContents: Contents[], siteID:number) {
  const [{StyleCore}, dispatch] = useContext(StyleCoreContext)
  const [SiteThemeCntxt] = useContext(SiteThemeContext)
  useMemo(async () => {
    await updateSiteBuildContent({StyleCore}, SiteThemeCntxt, unmarshalledContents, siteID)
  }, [unmarshalledContents,StyleCore.typography.map, SiteThemeCntxt])
}

function transformTypographyGroupToGQLRequest(group: TypographyGroup, useSiteBuild: boolean, siteBuildId: number, wrap?: string)  {
  const skipTranslation = ['fonts']
  const addition = useSiteBuild ? {site_build_id: siteBuildId} : {}
  const idField = {
    id: group.id
  }
  if (typeof wrap === 'undefined') {
    return idField
  }
  const transformation =  {
    name: group.name,
    description: group.description,
    is_default: group.isDefault,
    components: group.elements.map((element) => {
      return {
        name: element.name,
        primary_font_family: element.configuration.fonts.primary.name,
        fallback_font_family: element.configuration.fonts.fallback.name,
        options: Object.entries(element.configuration).filter(([label, value]) => {
          return !skipTranslation.includes(label)
        }).map(([label, value]) => {
          return {
            label,
            value
          }
        })
      } as TypograpyGQLComponent
    }),
    ...addition
  }
  if (typeof wrap !== 'undefined' && useSiteBuild) {
    return {
      [wrap]: transformation
    }
  } else {
    return {
      [wrap]: transformation,
      ...idField
    }
  }
}

export function useStyleCoreTypography(cms:boolean, importFonts = false, linkServerState = false) : [TypographyGroup[], (payload: TypographyGroup[]) => Promise<void>, TypographyFontHookReturn] {
  const [{StyleCore}, dispatch] = useContext(StyleCoreContext)
  const [SiteStore] = useLucidContext(cms)
  initializeStyleCoreSections(StyleCore, dispatch, cms)
  useStyleCoreTypographyServerState(cms, true)
  const fontsImported = useStyleCoreTypographyImports(cms, true)
  const StyleCoreTypographyGroupArray: TypographyGroup[] = Array.from(StyleCore.typography.map).map(([, value]) => value)
  return [
    StyleCoreTypographyGroupArray,
    async (payload: TypographyGroup[]) => {
      const functions = {
        add: {
          mutation: ADD_TYPOGRAPHY,
          payload: payload.filter((group) => {
            return StyleCoreTypographyGroupArray.findIndex((element) => element.id === group.id) === -1
          }),
          attachSiteBuildID: true,
          wrap: 'new_typography_input'
        },
        update: {
          mutation: UPDATE_TYPOGRAPHY,
          payload: payload.filter((group) => {
            return StyleCoreTypographyGroupArray.findIndex((element) => element.id === group.id) !== -1
              && !deepEqual(StyleCoreTypographyGroupArray.find((element) => element.id === group.id) ?? {}, group)
          }),
          attachSiteBuildID: false,
          wrap: 'update_typography_input'
        },
        delete: {
          mutation: DELETE_TYPOGRAPHY,
          payload: StyleCoreTypographyGroupArray.filter((group) => {
            return payload.findIndex((element) => element.id === group.id) === -1
          }),
          attachSiteBuildID: false,
          wrap: undefined
        }
      }

      const promises = []
      for (const [, value] of Object.entries(functions)) {
        if (value.payload.length > 0) {
          for (const group of value.payload) {
            promises.push(lucidDataFetcherV2(value.mutation, transformTypographyGroupToGQLRequest(group, value.attachSiteBuildID, SiteStore.site_build_id, value?.wrap)))
          }
        }
      }

      try {
        await Promise.all(promises)
      } catch (e) {
        console.error(e)
      }

    },
    fontsImported
  ]
}
