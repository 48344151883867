import {useRef} from 'react'
import {Button} from '@einsteinindustries/tinacms-styles'
import {BaseTextField, Select} from '@einsteinindustries/tinacms-fields'
import {
  DialogOverlay,
  DialogContent,
  InputLabel,
  FormButtonBar,
} from './styles'
import {REMOVE_ROUTE, UPDATE_ROUTE} from '@/src/graphql/mutations'

function UpdateRouteDialog(props) {
  const {isOpen, close, updateRoute, removeRoute, route} = props

  const urlRef = useRef()
  // const labelRef = useRef()
  // const pageIdRef = useRef()

  // TODO: Clean up styles
  return (
    <DialogOverlay
      isOpen={isOpen}
      onDismiss={close}
    >
      <DialogContent
        aria-label="Edit Route"
        style={{overflow: 'auto'}}
      >
        <h3>Edit Route</h3>
        <form
          onSubmit={(e) => {
            e.preventDefault()

            const variables = {
              id: route.id,
              updateRouteData: {
                url: urlRef.current.value,
              },
            }

            updateRoute(UPDATE_ROUTE, variables)
            close()
          }}
        >
          {/* {route.page_id ? (
            <>
              <InputLabel>Page</InputLabel>
              <Select
                input={{
                  name: 'route-manager-page-select',
                  defaultValue: route.page_id,
                  style: { marginBottom: 'var(--tina-padding-big)' },
                  ref: pageIdRef,
                }}
                options={pages.map((page) => {
                  return {
                    label: page.name,
                    value: page.id,
                  }
                })}
              />
            </>
          ) : null} */}
          <InputLabel>Path</InputLabel>
          <BaseTextField
            style={{marginBottom: 'var(--tina-padding-big)'}}
            type="text"
            defaultValue={route.url}
            ref={urlRef}
          />
          {/* <InputLabel>Label</InputLabel>
          <BaseTextField
            style={{ marginBottom: 'var(--tina-padding-big)' }}
            type="text"
            defaultValue={route.label}
            ref={labelRef}
            disabled
          /> */}
          <FormButtonBar>
            {/* TODO: Should this be a toggle for the "active" attribute? */}
            <Button
              type="button"
              margin={true}
              style={{color: 'var(--tina-color-error)', marginRight: 'auto'}}
              onClick={() => {
                if (window.confirm(`Deleting route: "${route.url}"`)) {
                  removeRoute(REMOVE_ROUTE, {id: route.id})
                  close()
                }
              }}
            >
              Delete Route
            </Button>
            <Button
              type="button"
              onClick={close}
              margin={true}
            >
              <span>Cancel</span>
            </Button>
            <Button
              type="submit"
              primary
              margin={true}
            >
              <span>Save</span>
            </Button>
          </FormButtonBar>
        </form>
      </DialogContent>
    </DialogOverlay>
  )
}

export default UpdateRouteDialog
