import '@reach/dialog/styles.css'
import PageTemplates from '@/src/components/managers/PageManager/components/utils/PageTemplates'
import {useContext, useState} from 'react'
import WizardLayout from '../../../../../components/shared/WizardLayout'
import {PageTemplate} from '@/src/components/shared/types'
import {LucidSiteContext} from '@/src/state/site/Store'

function suggestRoute(name: string, routeURLs: string[]): string {
  const slug = '/' + name.toLowerCase().replace(/[^a-z0-9 ]/g, '').replace(/ /g, '-')
  let route = slug
  let i = 1
  while (routeURLs.includes(route)) {
    route = `${slug}-${i}`
    i++
  }
  return route
}

interface NewPageWizardProps {
  showDialog: boolean
  closeDialog: () => void
  onSave: (name: string, description: string, route: string, template: string) => void
}

const NewPageWizard = ({
  showDialog,
  closeDialog,
  onSave,
}: NewPageWizardProps) => {
  const [name, setName] = useState('')
  const [description, setDescription] = useState('')
  const [route, setRoute] = useState('')
  const [template, setTemplate] = useState('none')

  useContext(LucidSiteContext)
  const [{pages}] = useContext(LucidSiteContext)
  const routeURLs = pages.map((page) => page.route?.url).filter((url) => url !== undefined)

  return (
    <WizardLayout
      title={'Create a new page'}
      closeDialog={closeDialog}
      onSave={() => onSave(name, description, route, template)}
      showDialog={showDialog}
      isNextAllowed={Boolean(name)}
    >
      <div>
        <label htmlFor="new-page-name">
          Page name:
        </label>
        <input
          type="text"
          value={name}
          name="new-page-name"
          onChange={(e) => {
            setName(e.target.value)
            setRoute(suggestRoute(e.target.value, routeURLs))
          }}
          placeholder="required"
          style={{
            width: '100%',
            padding: '12px 20px',
            margin: '8px 0',
            display: 'inline-block',
            border: name ? '1px solid #ccc' : '1px solid red',
            borderRadius: '4px',
            boxSizing: 'border-box',
          }}
        />
        <label htmlFor="new-page-description">
          Description:
        </label>
        <input
          type="text"
          value={description}
          name="new-page-description"
          onChange={(e) => setDescription(e.target.value)}
          placeholder="optional"
          style={{
            width: '100%',
            padding: '12px 20px',
            margin: '8px 0',
            display: 'inline-block',
            border: '1px solid #ccc',
            borderRadius: '4px',
            boxSizing: 'border-box',
          }}
        />
      </div>
      <div>
        <label htmlFor="new-page-route">
          Route:
        </label>
        <input
          type="text"
          value={route}
          name="new-page-route"
          onChange={(e) => setRoute(e.target.value)}
          placeholder="required"
          style={{
            width: '100%',
            padding: '12px 20px',
            margin: '8px 0',
            display: 'inline-block',
            border: route ? '1px solid #ccc' : '1px solid red',
            borderRadius: '4px',
            boxSizing: 'border-box',
          }}
        />
      </div>
      <div>
        <label htmlFor="page-template">
          Would you like to use a page template?
        </label>
        <select
          name="page-template"
          id="page-template"
          onChange={(e) => setTemplate(e.target.value)}
          style={{
            width: '100%',
            padding: '12px 20px',
            margin: '8px 0',
            display: 'inline-block',
            border: '1px solid #ccc',
            borderRadius: '4px',
            boxSizing: 'border-box',
          }}
        >
          <option value="none">None</option>
          {PageTemplates.map((template: PageTemplate, index: number) => (
            <option key={index} value={template.name}>{template.name}</option>
          ))}
        </select>
      </div>
      <div>
        <span>A new page will be created</span>
        <ul>
          <li>Name: {name}</li>
          <li>Description: {description}</li>
          <li>Route: {route}</li>
          <li>Template: {template}</li>
        </ul>
        <p>
          Click "Save" to create the new page.
        </p>
      </div>
    </WizardLayout>
  )
}

export default NewPageWizard
