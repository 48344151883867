// import dynamic from 'next/dynamic'
import SectionContactForm from './shared/SectionContactForm'
import Section from './shared/Section'
import {InlineTextWithVariables, RichtextWithVariables} from './shared/SectionsWithVariables'
import styled from 'styled-components'
import {SectionQuoteFormWrapper} from './shared/SectionQuoteFormWrapper'


export default function ContactCard(props) {
  const {
    title,
    subtitle,
    intro,
    quote,
    author,
    color_scheme,
    className,
    css_overrides,
    color_scheme_id_override, lucid_page_section_id, meta,
    cms,
  } = props


  return (
    <Section
      cms={cms}
      css_overrides={css_overrides}
      color_scheme_id_override={color_scheme_id_override} meta={meta}
      lucid_page_section_id={lucid_page_section_id}
      className={className + ' section stack contact-card-section'}
      name="contactcard"
      colorSchemeId={color_scheme}
    >
      <SectionQuoteFormWrapper
          cms={cms}
          title={title}
          subtitle={subtitle}
          intro={intro}
          quote={quote}
          author={author}
          className={'contact-form'}>
        <SectionContactForm cms={cms} />
      </SectionQuoteFormWrapper>
      <div className="section-background"/>
    </Section>
  )
}

ContactCard.StyleCore = {
  config: {},
  target: {
    componentName: Object.keys({ContactCard})[0].toLowerCase(),
  },
  css: ''
}
