import StyledHeading from './shared/StyledHeading'
import {InlineTextWithVariables, RichtextWithVariables} from './shared/SectionsWithVariables'
import Section from './shared/Section'
import styled from 'styled-components'
import SectionButton from './shared/SectionButton'

export default function HeroTagline(props) {
  const {
    title,
    subtitle,
    caption,
    button,
    image,
    image_mobile,
    color_scheme,
    className,
    css_overrides,
    color_scheme_id_override,
    lucid_page_section_id, meta,
    cms,
  } = props

  let CaptionElement = null
    if (caption) {
      CaptionElement = <div className="row hero-caption flexbox-alignment bottom-right mobile-bottom">
        <div className="column">
          <p className="caption">{caption}</p>
        </div>
      </div>
    }
    let HeroButton = null
    if (button?.url && button?.label) {
      HeroButton = <SectionButton label={button?.label} href={button?.url} cms={cms} name={'button'} />
    }

  return (
    <Section
      cms={cms}
      css_overrides={css_overrides}
      color_scheme_id_override={color_scheme_id_override} meta={meta}
      lucid_page_section_id={lucid_page_section_id}
      className={className + ' section hero-tagline-section back-stack bar-enabled'}
      name="herotagline"
      heroImage={image?.src}
      heroImageMobile={image_mobile?.src}
      colorSchemeId={color_scheme}
    >
      <div className="top-bar show-for-large site section back-stack hero-inner-section">
        <div className="section-background"></div>
      </div>
      <div className="section-container">
        <div className="section-content">
          <div className="row hero-tagline flexbox-alignment middle mobile-middle">
            <div className="column">
              <div className="section-heading">
                <h2>
                  <span className="title heading-1"><RichtextWithVariables richtext={title} cms={cms} name={'title'}/></span>
                  <span className="subtitle heading-2"><RichtextWithVariables richtext={subtitle} cms={cms} name={'subtitle'}/></span>
                </h2>
              </div>
              {HeroButton}
            </div>
          </div>
          {CaptionElement}
        </div>
      </div>
      <div className="section-background"></div>
    </Section>
  )
}

HeroTagline.StyleCore = {
  config: {},
  target: {
    componentName: Object.keys({HeroTagline})[0].toLowerCase(),
  },
  css: ''
}
