import {
  AllowedFieldConfigComponents,
  allowedScopes,
  DynamicListProps,
  EventUpdateAcceptedFieldContent,
  FieldConfig,
  FieldConfigDataGeneralGroup,
  fieldDataPoint,
  FormConfig,
  GroupListField,
  initialValueType,
  InterchangeableConfig,
  Listeners,
  ListenerTarget,
  ListField
} from '@/components/shared/externalTypes'
import {Field} from '@einsteinindustries/tinacms'
import {lucidDataFetcherV2} from '@/graphql/fetchers'
import {CONTENTS} from '@/graphql/queries'
import Dexie from 'dexie'
import {ADD_CONTENT, UPDATE_CONTENT} from '@/graphql/mutations'
import {Contents} from '@/components/shared/types'
import CopyToClipboard from '@/src/utils/shared/CopyToClipboard'

const TINA_FORM_DATABASE_NAME = 'lucid-form'
const CONTENT_VARIABLE_COPY_FIELD_NAME = 'copyvar'
const CONFIG_TREE_CONTENT_DEPTH = 2

export const HELPER_CONSTANTS = {
  tina: {
    forms: {
      DATABASE_NAME: TINA_FORM_DATABASE_NAME,
      DELIMITER: '>',
      SCRUB_FIELDS: {
        content: undefined,
        asGroupList: undefined,
        onChange: undefined,
        onSubmit: undefined,
        preventContentTableUpsert: undefined,
        formatted: undefined,
        attributes: undefined,
        preventDefault: undefined,
        target: undefined
      }
    }
  },
  IndexedDB: {
    databases: [{
      name: TINA_FORM_DATABASE_NAME,
      structure: {
        storedContent: '++id, target_type, target_id, content_id, name, value, timestampUNIX',
        fetchTable: '++id, target_type, target_id, content_id, timestampUNIX',
        debounceTable: '++id, last_send'
      }
    }],
    staleTimeMs: 30000
  }
}

const HiddenHelpers = {
  IndexedDB: {
    init: async () => {
      for (const database of HELPER_CONSTANTS.IndexedDB.databases) {
        if (await Dexie.exists(database.name)) {
          continue
        }
        const db = new Dexie(database.name)
        db.version(1).stores(database.structure)
        await db.open()
        await db.close()
      }
    },
    purge: async (databases: string[] = []) => {
      if (databases.length === 0)
        databases = HELPER_CONSTANTS.IndexedDB.databases.map((database) => database.name)
      for (const database of databases) {
        const db = new Dexie(database)
        await db.delete()
      }
    }
  },
  tina: {
    forms: {
      createLabelFromName: (label: string) => {
        return [...label.split(/(?=[A-Z_-])/)].map((word) => (word.charAt(0).toUpperCase() + word.slice(1))).join(' ')
      },
      handleGroupList: (fieldConfig: (GroupListField | ListField), label: string) => {
        return {
          itemProps: ({id: key, name: label}: Record<string, any>) => {
            return {key, label}
          },
          defaultItem: {
            name: `New ${fieldConfig.newItemName ?? HiddenHelpers.tina.forms.createLabelFromName(label)}`,
            ...(fieldConfig.defaultItem ?? {})
          }
        }
      },
      handleGroup: (fieldConfig: FieldConfigDataGeneralGroup, groupName: string, prefix: string, showVariables: boolean = false) => {
        fieldConfig.asGroupList = fieldConfig.asGroupList ?? false
        return Object.assign({
          label: HiddenHelpers.tina.forms.createLabelFromName(groupName),
          name: `${prefix}${HELPER_CONSTANTS.tina.forms.DELIMITER}${groupName}`,
          component: fieldConfig.asGroupList ? 'group-list' : 'group',
          fields: HiddenHelpers.tina.forms.createConfigTree(fieldConfig.content as FieldConfig, `${prefix}${HELPER_CONSTANTS.tina.forms.DELIMITER}${groupName}`, showVariables) as Field[],
          ...({...fieldConfig, ...{content: undefined, asGroupList: undefined}})
        }, fieldConfig.asGroupList ? HiddenHelpers.tina.forms.handleGroupList(fieldConfig as FieldConfigDataGeneralGroup & DynamicListProps, groupName) : {}) as Field
      },
      createConfigTree: (config: FieldConfig, prefix:string = HELPER_CONSTANTS.tina.forms.DATABASE_NAME, showVariables: boolean = false): Field[] => {
        const configTree: Field[] = []
        for (const [label, field] of Object.entries(config)) {
          if (field) {
            if (typeof field.content === 'object')
            {
              configTree.push(HiddenHelpers.tina.forms.handleGroup(field as FieldConfigDataGeneralGroup, label, prefix, showVariables))
            }
            else if (field.formatted) configTree.push(field.formatted as Field)
            else {
              if (showVariables) {
                configTree.push({
                  name: label + CONTENT_VARIABLE_COPY_FIELD_NAME,
                  label: 'Copy Variable Name',
                  component: () => CopyToClipboard({
                    textToCopy: Helpers.tina.forms.getVariableName(prefix, label)
                  }),
                })
              }
              configTree.push({
                label: HiddenHelpers.tina.forms.createLabelFromName(label),
                name: `${prefix}${HELPER_CONSTANTS.tina.forms.DELIMITER}${label}`,
                component: (field.content ?? 'text') as AllowedFieldConfigComponents,
                ...field
              } as Field)
            }
          }
        }
        return configTree
      },
      // TODO: Need a method `retrieveForm` to only retrieve a form rather than
      // relying on the cached response from `createForm`.
      getFormValues: async (config: FormConfig, isStale: boolean = false) => {
        const form = await HiddenHelpers.tina.forms.createForm(config, isStale)
        return HiddenHelpers.tina.forms.externalEventListenerPreprocess(form.initialValues)
      },
      getVariableName: (prefix: string, label: string) => '$' + prefix.split(HELPER_CONSTANTS.tina.forms.DELIMITER).splice(CONFIG_TREE_CONTENT_DEPTH).join('.') + '.' + label,
      GraphQL: {
        markBatchAsFetched: async (targetType: allowedScopes, targetId: string) => {
          await HiddenHelpers.IndexedDB.init()
          const db = new Dexie(HELPER_CONSTANTS.tina.forms.DATABASE_NAME)
          await db.open()
          await db.table('fetchTable').add({
            target_type: targetType,
            target_id: targetId,
            timestampUNIX: Date.now()
          })
          await db.close()
        },
        fetchValues: async (listenerConfig: Listeners & Required<ListenerTarget>, field: string, skip:number = 0, take:number = 200, batchFetchAll: boolean = true, isStale:boolean = false) => {
          await HiddenHelpers.tina.forms.IndexedDB.cleanFetchTable()
          if (!isStale) {
            if (!(await HiddenHelpers.tina.forms.IndexedDB.checkIfContentIsStale(
              listenerConfig?.target?.page_section_id ? 'PAGE' : 'SITE',
              `${listenerConfig?.target?.page_section_id ?? listenerConfig.target.site_id}`)))
              return
          }
          const baseQuery = {
            skip,
            take
          }
          const {data} = await lucidDataFetcherV2<Contents[]>(CONTENTS, {
            ...baseQuery,
            ...listenerConfig.target
          })
          if (data.contents.length > 0) {
            for (const content of data.contents as {
              scope: allowedScopes,
              name: string,
              value: string,
              id: string
            }[]) {
              await HiddenHelpers.tina.forms.IndexedDB.storeField(content.scope, content.name, content.value, `${listenerConfig.target.page_section_id ?? listenerConfig.target.site_id}`, content.id)
            }
          }
          if (data.contents.length === take && batchFetchAll) {
            await HiddenHelpers.tina.forms.GraphQL.fetchValues(listenerConfig, field, skip + take, take)
          }
          await HiddenHelpers.tina.forms.GraphQL.markBatchAsFetched(listenerConfig.target.page_section_id ? 'PAGE' : 'SITE', `${listenerConfig.target.page_section_id ?? listenerConfig.target.site_id}`)
        },
        upsertContentTableRow: async ({target}: Listeners & Required<ListenerTarget>, field: string, value: string) => {
          // if field ends in 'copyvar' then it is a copy variable button and should not be stored in the database
          if (field.endsWith(CONTENT_VARIABLE_COPY_FIELD_NAME)) return
          const targetType = target?.page_section_id ? 'PAGE' : 'SITE'
          const existingData = await HiddenHelpers.tina.forms.IndexedDB.retrieveField(targetType, `${target?.page_section_id ?? target?.site_id}`, field)
          let query = null
          if (!existingData) {
            query = [ADD_CONTENT, {
              newContent: {
                ...target,
                name: field,
                value,
                scope: targetType
              }
            }]
          } else if (existingData.value !== value) {
            query = [UPDATE_CONTENT, {
              id: existingData.content_id,
              updateContentData: {
                value
              }
            }]
          } else return
          const {data} = await lucidDataFetcherV2<Contents>(query[0], query[1])
          if (!(data?.addContent?.id ?? data?.updateContent?.id)) {
            throw new Error('Failed to update/create content')
          }
          await HiddenHelpers.tina.forms.IndexedDB.storeField(targetType, field, value, `${target.page_section_id ?? target.site_id}`, data?.addContent?.id ?? data?.updateContent?.id)
        },
      },
      IndexedDB: {
        checkIfContentIsStale: async (target_type: allowedScopes, target_id: string) => {
          await HiddenHelpers.IndexedDB.init()
          const db = new Dexie(HELPER_CONSTANTS.tina.forms.DATABASE_NAME)
          await db.open()
          const fetchTable = await db.table('fetchTable').where({
            target_type,
            target_id
          }).toArray()
          if (fetchTable.length > 0) {
            const lastFetch = fetchTable[fetchTable.length - 1]
            if (Date.now() - lastFetch.timestampUNIX < HELPER_CONSTANTS.IndexedDB.staleTimeMs) {
              await db.close()
              return false
            }
          }
          await db.close()
          return true
        },
        cleanFetchTable: async () => {
          await HiddenHelpers.IndexedDB.init()
          const db = new Dexie(HELPER_CONSTANTS.tina.forms.DATABASE_NAME)
          await db.open()
          await db.table('fetchTable').where('timestampUNIX').below(Date.now() - HELPER_CONSTANTS.IndexedDB.staleTimeMs).delete()
          await db.close()
        },
        decodeStringToActualValue: (value: string) => {
          try {
            return JSON.parse(value)
          } catch (e) {
            if (value === 'true') return true
            if (value === 'false') return false
            if (!isNaN(Number(value))) return Number(value)
            return value
          }
        },
        retrieveField: async (type: allowedScopes, target_id: string, field: string) => {
          await HiddenHelpers.IndexedDB.init()
          const db = new Dexie(HELPER_CONSTANTS.tina.forms.DATABASE_NAME)
          await db.open()
          const data = await db.table('storedContent').where({
            type,
            name: field,
            target_id
          }).toArray()
          await db.close()
          if (data.length > 0) return data[0]
          return null
        },
        storeField: async (type: allowedScopes, field: string, value: string, target_id: string, content_id:string) => {
          await HiddenHelpers.IndexedDB.init()
          const db = new Dexie(HELPER_CONSTANTS.tina.forms.DATABASE_NAME)
          await db.open()
          const data = await db.table('storedContent').where({
            type,
            name: field,
            target_id,
            content_id
          })
          if (await data.count() > 0) {
            await db.table('storedContent').update((await data.first()).id, {value})
            return
          }
          await db.table('storedContent').put({type, name: field, value, target_id, content_id})
        },
        reset: async () => {
          await HiddenHelpers.IndexedDB.init()
          await HiddenHelpers.IndexedDB.purge([HELPER_CONSTANTS.tina.forms.DATABASE_NAME])
        }
      },
      initialValues: {
        gatherInitialValues: async (config: FormConfig, field: string = '', target: Listeners & Required<ListenerTarget>, isStale:boolean = false, formID = 'none') => {
          await HiddenHelpers.tina.forms.GraphQL.fetchValues(target, field, 0, 200, isStale)
          const configFormID = formID === 'none' ? config.id : formID
          if (typeof config.content === 'object') {

            const initialValues:initialValueType = {}
            for (const [label, fieldConfig] of Object.entries(config.content ?? config)) {
              initialValues[`lucid-form${HELPER_CONSTANTS.tina.forms.DELIMITER}${configFormID}${field}${HELPER_CONSTANTS.tina.forms.DELIMITER}${label}` as keyof typeof initialValues] = await HiddenHelpers.tina.forms.initialValues.gatherInitialValues(fieldConfig as FormConfig, `${field}${HELPER_CONSTANTS.tina.forms.DELIMITER}${label}`, target, isStale, configFormID)
            }
            return initialValues
          }
          return (await HiddenHelpers.tina.forms.IndexedDB.retrieveField(
            target.target.page_section_id ? 'PAGE' : 'SITE',
            `${target.target.page_section_id ?? target.target.site_id}`,
            `lucid-form${HELPER_CONSTANTS.tina.forms.DELIMITER}${configFormID}${field}`))?.value ?? null
        }
      },
      processChangeRecursively: async (field: string[], value: fieldDataPoint, listenerConfig: Listeners & Required<ListenerTarget>) => {
        if (listenerConfig.preventContentTableUpsert) return
        if (typeof value === 'object' && value !== null) {
          for (const [label, fieldData] of Object.entries(value as object)) {
            await HiddenHelpers.tina.forms.processChangeRecursively([...field, label], fieldData, listenerConfig)
          }
        } else {
          const fieldName = field.join(HELPER_CONSTANTS.tina.forms.DELIMITER)
          await HiddenHelpers.tina.forms.GraphQL.upsertContentTableRow(listenerConfig, fieldName.substring(fieldName.lastIndexOf(HELPER_CONSTANTS.tina.forms.DATABASE_NAME)), value ? `${value}` : '')
        }
      },
      externalEventListenerPreprocess: (values: fieldDataPoint) => {
        const processedValues: fieldDataPoint = {}
        if (typeof values === 'object' && values !== null) {
          for (const [key, value] of Object.entries(values as object)) {
            let keyModified = key.substring(key.lastIndexOf(HELPER_CONSTANTS.tina.forms.DELIMITER) + 1, key.length)
            processedValues[keyModified as keyof initialValueType] = HiddenHelpers.tina.forms.externalEventListenerPreprocess(value)
          }
        } else {
          return values === '' ? null : values
        }
        return processedValues
      },
      handleChange: async ({values}: {
        values: fieldDataPoint
      }, config: FormConfig) => {
        if (config.listeners.onChange) {
          config.listeners.onChange(HiddenHelpers.tina.forms.externalEventListenerPreprocess(values) as EventUpdateAcceptedFieldContent)
        }
        if (config.listeners.contentTableUpsertOn && ['all', 'change'].includes(config.listeners.contentTableUpsertOn)) {
          await HiddenHelpers.tina.forms.processChangeRecursively([HELPER_CONSTANTS.tina.forms.DATABASE_NAME], values, config.listeners)
        }
      },
      handleSubmit: async (values: any, config: FormConfig) => {
        console.log('submitting', values, config)
        if (config.listeners.onSubmit) {
          config.listeners.onSubmit(HiddenHelpers.tina.forms.externalEventListenerPreprocess(values) as EventUpdateAcceptedFieldContent)
        }
        if (!config.listeners.contentTableUpsertOn || ['all', 'submit'].includes(config.listeners.contentTableUpsertOn)) {
          await HiddenHelpers.tina.forms.processChangeRecursively([HELPER_CONSTANTS.tina.forms.DATABASE_NAME], values, config.listeners)
        }
      },
      createForm: async (config: FormConfig, isStale: boolean = true, showVariables: boolean = false) => {
        const initialValues = async () => {return await HiddenHelpers.tina.forms.initialValues.gatherInitialValues(config as InterchangeableConfig, '', config.listeners as Listeners & Required<ListenerTarget>, isStale)}

        return {
          id: config.id,
          label: config.title,
          initialValues: await initialValues(),
          fields: HiddenHelpers.tina.forms.createConfigTree(config.content, `${HELPER_CONSTANTS.tina.forms.DATABASE_NAME}${HELPER_CONSTANTS.tina.forms.DELIMITER}${config.id}`, showVariables),
          onSubmit: async (values: any) => {
            await HiddenHelpers.tina.forms.handleSubmit(values, config)
          },
          onChange: async (values: any) => {
            await HiddenHelpers.tina.forms.handleChange(values, config)
          }
        }
      }
    }
  }
}
const Helpers = {
  tina: {
    forms: {
      create: HiddenHelpers.tina.forms.createForm,
      clearCache: HiddenHelpers.tina.forms.IndexedDB.reset,
      getVariableName: HiddenHelpers.tina.forms.getVariableName,
      only: {
        fieldMap: HiddenHelpers.tina.forms.createConfigTree,
        getInitialValues: HiddenHelpers.tina.forms.getFormValues,
        convertFormManagerToObject: HiddenHelpers.tina.forms.externalEventListenerPreprocess,
      }
    }
  },
  format: {
    capitalize: {
      /**
       * This function takes a string and returns a string with the first letter capitalized.
       * @param stringToCapitalize String to capitalize.
       * @returns A string with the first letter capitalized.
       * @example
       * // returns "Twitter"
       * Helpers.format.capitalize.firstLetter('twitter')
       */
      firstLetter: (stringToCapitalize: string): string => stringToCapitalize.charAt(0).toUpperCase() + stringToCapitalize.slice(1),
      /**
       * This function takes a string and returns a string with the first letter of each word capitalized.
       * @param stringToCapitalize String to capitalize.
       * @returns A string with the first letter of each word capitalized.
       * @example
       * // returns "Twitter"
       * Helpers.format.=capitalize.firstLetterOfEachWord('twitter')
       * @example
       * // returns "Twitter Is Awesome"
       * Helpers.format.capitalize.firstLetterOfEachWord('twitter is awesome')
       */
      firstLetterOfEachWord: (stringToCapitalize: string): string => stringToCapitalize.split(' ').map(w => w.charAt(0).toUpperCase() + w.substring(1).toLowerCase()).join(' ')
    },
    human: {
      date: {
        /***
         * This function takes a date and returns a string in a human-readable format, such as "2 days ago"
         * If the date is more than one day ago, it will return "on Month/DD/YYYY at HH:MM AM/PM"
         * @param dateToFormat Date string to format. Can be either a date string or a date object.
         */
        relative: (dateToFormat: string | Date): string => {
          const date = (typeof dateToFormat === 'string') ? new Date(Date.parse(dateToFormat)) : dateToFormat
          const dayOfMonth: number | string = date.getDate()
          const month: number | string = date.getMonth() + 1
          const year: number | string = date.getFullYear().toString().slice(-2)
          const hour: number | string = date.getHours()
          const minutes: number | string = date.getMinutes()
          const diffMs = Date.now() - date.getTime()
          const diffSec = Math.round(diffMs / 1000)
          const diffMin = Math.round(diffSec / 60)
          const diffHour = Math.round(diffMin / 60)
          const diffDay = Math.round(diffHour / 24)
          const AMPM = (hour < 12 ? 'AM' : 'PM')

          if (diffSec < 1) {
            return 'just now'
          } else if (diffMin < 1) {
            return `${diffSec} second${diffSec === 1 ? '' : 's'} ago`
          } else if (diffHour < 1) {
            return `${diffMin} minute${diffMin === 1 ? '' : 's'} ago`
          } else if (diffDay < 1) {
            return `${diffHour} hour${diffHour === 1 ? '' : 's'} ago`
          } else {
            return `on ${month}/${dayOfMonth}/${year} at ${hour % 12}:${(minutes < 10 ? '0' + minutes : minutes)} ${AMPM}`
          }
        },
        /***
         * This function takes a date and returns a string in a human-readable format, such as "January 1, 2020"
         * @param dateToFormat Date string to format. Can be either a date string or a date object.
         * @param includeTime Whether or not to include the time in the string.
          */
        long: (dateToFormat: string | Date, includeTime: boolean = false): string => {
          const date = (typeof dateToFormat === 'string') ? new Date(Date.parse(dateToFormat)) : dateToFormat
          const dayOfMonth: number | string = date.getDate()
          const month: number | string = date.getMonth() + 1
          const year: number | string = date.getFullYear().toString()
          const hour: number | string = date.getHours()
          const minutes: number | string = date.getMinutes()
          const AMPM = (hour < 12 ? 'AM' : 'PM')

          // return string in format "January 1, 2020"
          if (includeTime) {
            return `${Helpers.format.human.date.long(dateToFormat)} at ${hour % 12}:${(minutes < 10 ? '0' + minutes : minutes)} ${AMPM}`
          }
          return `${Helpers.format.human.date.month(month)} ${dayOfMonth}, ${year}`
        },
        /***
         * This function takes a month number and returns a string in a human-readable format, such as "January"
         * @param monthNumber Month number to format.
         * @returns A string in a human-readable format, such as "January"
         */
        month: (monthNumber: number): string => {
          const monthNames = ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December']
          return monthNames[monthNumber - 1]
        }
      },
      count: {
        /**
         * This function takes a count, and a singular noun, and returns a string with the count and the noun, pluralized if necessary.
         * @param count The count to be formatted.
         * @param noun The noun to be formatted.
         * @param asZero The string to be returned if the count is zero, defaults to the number 0.
         * @returns A string with the count and the noun, pluralized if necessary.
         */
        pluralSingular: (count: number | undefined, nounSingular: string, asZero?: string): string => {
          if (typeof count === 'undefined') {
            count = 0
          }
          return `${(count === 0 && asZero) ? asZero : count} ${nounSingular}${count === 1 ? '' : 's'}`
        }
      },
      phone: {
        /**
         * This function takes a phone number (partial or complete) and returns a string in a human-readable format, such as "(123) 456-7890" or "(123) 456-"
         * @param phoneToFormat Phone number string to format. Can be either a phone number string or a phone number object.
         * @returns A string in a human-readable format, such as "(123) 456-7890"
         * @example
         * // returns "(123) 456-7890"
         * Helpers.format.human.phone.format('(123)4567890')
         * @example
         * // returns "(123) 456-7890"
         * Helpers.format.human.phone.format('1234567890')
         * @example
         * // returns "(123) "
         * Helpers.format.human.phone.format('123')
         * @example
         * // returns "(123) 456-"
         * Helpers.format.human.phone.format('123456')
         * @example
         * // returns "(123) 456-7890"
         * Helpers.format.human.phone.format({areaCode: '123', prefix: '456', lineNumber: '7890'})
         */
        format: (phoneToFormat: string | {areaCode: string, prefix: string, lineNumber: string}): string => {
          if (phoneToFormat === null || typeof phoneToFormat === 'undefined') return ''
          const phone = (typeof phoneToFormat === 'string') ? phoneToFormat.replace(/[^0-9]/g,'') : `${phoneToFormat.areaCode}${phoneToFormat.prefix}${phoneToFormat.lineNumber}`
          const areaCode = phone.slice(0, 3)
          const prefix = phone.slice(3, 6)
          const lineNumber = phone.slice(6, 10)
          
          if (phone.length > 6) {
            return `(${areaCode}) ${prefix}-${lineNumber}`
          } else if (phone.length > 3) {
            return `(${areaCode}) ${prefix}`
          } else if (phone.length > 0) {
            return `(${areaCode}`
          }
          return ''
        },
        /**
         * This function limits the length of a phone number to 10 digits.
         * @param phoneToFormat Phone number string to format. Can be either a phone number string or a phone number object. If the phone number is longer than 10 digits, it will be truncated.
         * @returns A string with the phone number limited to 10 digits.
         * @example
         * // returns "1234567890"
         * Helpers.format.human.phone.limit('(123)4567890')
         * @example
         * // returns "1234567890"
         * Helpers.format.human.phone.limit('1234567890')
         * @example
         * // returns "123"
         * Helpers.format.human.phone.limit('123')
         * @example
         * // returns "1234567890"
         * Helpers.format.human.phone.limit({areaCode: '123', prefix: '456', lineNumber: '7890'})
         * @example
         * // returns "1234567890"
         * Helpers.format.human.phone.limit({areaCode: '123', prefix: '456', lineNumber: '78901234567890'})
         * @example
         * // returns "1234567890"
         * Helpers.format.human.phone.limit({areaCode: '1234567890', prefix: '456', lineNumber: '7890'})
         * @example
         * // returns "1234567890"
         * Helpers.format.human.phone.limit({areaCode: '123', prefix: '456', lineNumber: '78901234567890'})
         * @example
         * // returns "1234567890"
         * Helpers.format.human.phone.limit('123456789012345')
         */
        limit: (phoneToFormat: string | {areaCode: string, prefix: string, lineNumber: string}): string => {
          if (phoneToFormat === null || typeof phoneToFormat === 'undefined') return ''
          const phone = (typeof phoneToFormat === 'string') ? phoneToFormat.replace(/[^0-9]/g,'') : `${phoneToFormat.areaCode}${phoneToFormat.prefix}${phoneToFormat.lineNumber}`
          if (phone.length === 0) {
            return ''
          }
          return phone.substring(0,10)
        },
        /**
         * This function takes a phone number (partial or complete) and returns a boolean indicating whether or not the phone number is valid.
         * @param maybePhone Phone number string to validate. Can be either a phone number string or a phone number object.
         * @returns A boolean indicating whether or not the phone number is valid.
         * @example
         * // returns true
         * Helpers.format.human.phone.validate('(123)4567890')
         * @example
         * // returns true
         * Helpers.format.human.phone.validate('1234567890')
         * @example
         * // returns false
         * Helpers.format.human.phone.validate('123')
         */
        is: (maybePhone: string | {areaCode: string, prefix: string, lineNumber: string}): boolean => {
          if (maybePhone === null || typeof maybePhone === 'undefined') return false
          if (typeof maybePhone === 'string') {
            return (maybePhone.replace(/[^0-9]/g,'').length === 10)
          } else {
            return (maybePhone.areaCode.length === 3 && maybePhone.prefix.length === 3 && maybePhone.lineNumber.length === 4)
          }
        }
      }
    },
    validate: {
      /**
       * This function takes a twitter handle and returns a string in a human-readable format, such as "@twitter"
       * This also will remove any non-alphanumeric characters or non-underscores from the handle.
       * Finally, this limits the handle to 15 characters.
       * @param twitterHandle Twitter handle to format. Can be either a twitter handle string or a twitter handle object.
       * @returns A string in a human-readable format, such as "@twitter"
       * @example
       * // returns "@twitter"
       * Helpers.format.human.twitter.format('twitter')
       * @example
       * // returns "@twitter"
       * Helpers.format.human.twitter.format({handle: 'twitter'})
       * @example
       * // returns "@twitter"
       * Helpers.format.human.twitter.format({handle: '@twitter'})
       * @example
       * // returns "@t"
       * Helpers.format.human.twitter.format('t')
       * @example
       * // returns "@twitter"
       * Helpers.format.human.twitter.format('1234twitter--')
       * @example
       * // returns "@123456789012345"
       * Helpers.format.human.twitter.format('123456789012345')
       */
      twitter: (twitterHandle: string | {handle: string}): string => {
        if (twitterHandle === null || typeof twitterHandle === 'undefined') return ''
        const handle = (typeof twitterHandle === 'string') ? twitterHandle.replace(/[^a-zA-Z0-9_]/g,'') : twitterHandle.handle.replace(/[^a-zA-Z0-9_]/g,'')
        if (handle.length === 0) {
          return ''
        }
        return `@${handle.slice(0, 15)}`
      },
      /**
       * This function takes an instagram handle and returns a string in a human-readable format, such as "@instagram"
       * This also will remove any non-alphanumeric characters or non-periods from the handle.
       * Finally, this limits the handle to 30 characters.
       * @param instagramHandle Instagram handle to format. Can be either an instagram handle string or an instagram handle object.
       * @returns A string in a human-readable format, such as "@instagram"
       * @example
       * // returns "@instagram"
       * Helpers.format.human.instagram.format('instagram')
       * @example
       * // returns "@instagram"
       * Helpers.format.human.instagram.format({handle: 'instagram'})
       * @example
       * // returns "@instagram"
       * Helpers.format.human.instagram.format({handle: '@instagram'})
       */
      instagram: (instagramHandle: string | {handle: string}): string => {
        if (instagramHandle === null || typeof instagramHandle === 'undefined') return ''
        const handle = (typeof instagramHandle === 'string') ? instagramHandle.replace(/[^a-zA-Z0-9\._]/g,'') : instagramHandle.handle.replace(/[^a-zA-Z0-9\._]/g,'')
        if (handle.length === 0) {
          return ''
        }
        return `@${handle.slice(0, 30)}`
      },
      /**
       * This function takes a phone number (partial, complete, or invalid) and returns an error string or undefined if the phone number is valid.
       * @param phoneToValidate Phone number string to validate. Can be either a phone number string or a phone number object.
       * @returns An error string or undefined if the phone number is valid.
       * @example
       * // returns undefined
       * Helpers.format.human.phone.validate('(123)4567890')
       * @example
       * // returns undefined
       * Helpers.format.human.phone.validate('1234567890')
       * @example
       * // returns 'Phone number must be 10 digits'
       * Helpers.format.human.phone.validate('123')
       * @example
       * // returns 'Phone number must be 10 digits'
       * Helpers.format.human.phone.validate('123456')
       * @example
       * // returns undefined
       * Helpers.format.human.phone.validate({areaCode: '123', prefix: '456', lineNumber: '7890'})
       * @example
       * // returns 'Phone number must be 10 digits'
       * Helpers.format.human.phone.validate({areaCode: '123', prefix: '456', lineNumber: '78901234567890'})
       */
      phone: (phoneToValidate: string | {areaCode: string, prefix: string, lineNumber: string}): string | undefined => {
        if (phoneToValidate === null || typeof phoneToValidate === 'undefined') return
        const phone = (typeof phoneToValidate === 'string') ? phoneToValidate.replace(/[^0-9]/g,'') : `${phoneToValidate.areaCode}${phoneToValidate.prefix}${phoneToValidate.lineNumber}`
        if (phone && phone.length !== 10) {
          return 'Error: Phone number must be 10 digits'
        }
      },
    }
  },
  contents: {
    getContentIDFromOriginalContents(key: string | undefined, originalContents: Contents[]): number | undefined {
      const ID = Number(originalContents.find((e) => e.name === key)?.id)
      return isNaN(ID) || typeof key === 'undefined' ? undefined : ID
    },
    getContentValueFromOriginalContents(key: string | undefined, originalContents: Contents[]): string | undefined {
      const value = originalContents.find((e) => e.name === key)?.value
      return typeof key === 'undefined' ? undefined : value
    }
  }
}

export default Helpers
