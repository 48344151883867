import LoadingSpinner from '../../LoadingSpinner'
import styled from 'styled-components'
import {Button, Dropdown, FormElement, Grid, Input, Modal, Text} from '@nextui-org/react'
import {useRouter} from 'next/router'
import {
  itemCategoryAndColorMap,
  MENU_EDITOR_GROUP_NAME,
  MenuEditorHeaderProps,
  MenuEditorProps,
  onMenuChangeAction,
  SortableListItem,
  specialPages
} from '@/components/managers/MenuManager/utils/types'
import {ChangeEvent, Dispatch, Key, SetStateAction, useState} from 'react'
import {BuildVersion, Menu, MenuItem, Page, RouteType} from '@/components/shared/types'
import SortableList from '@/components/managers/MenuManager/SortableList'
import MenuFilter from '@/components/managers/MenuManager/features/menuFilter/MenuFilter'

const ScrollContainer = styled.div<{ flexBasis: string }>`
  flex: 1 0 ${(props) => props.flexBasis || 'auto'};
  height: 100%;
  overflow-y: scroll;
  background-color: #fff;
`

export const MenuContainer = styled.div`
  min-height: 100%;

  > .sortable-list {
    position: relative;
    height: 100%;
    padding: 0.5rem 1rem;
  }

  > .sortable-list .sortable-list-draggable {
    margin: 0.5rem 0;
    color: black;
    border-radius: 4px;
    overflow: hidden;
    border: 1px solid lightgrey;
    background-color: white;
  }

  > .sortable-list > .smooth-dnd-container:after {
    display: block;
    position: relative;
    content: '';
    height: 40vh;
    width: 100%;
    bottom: 0;
    left: 0;
  }

  .sortable-list .draggable-box span {
    margin-top: 10px;
  }

  .sortable-list .badge-override > span {
    border: 0;
    border-radius: 3px 0 0 3px;
  }

  .sortable-list-draggable[data-children-count='0']
  + .sortable-list
  > .smooth-dnd-container {
    border: 1px dashed rgba(65, 105, 225, 0.4);
  }

  .sortable-list-drop-placeholder:after {
    display: block;
    position: absolute;
    content: '';
    top: 0.5rem;
    bottom: 0.5rem;
    width: 100%;
    border: 1px dashed rgba(65, 105, 225, 0.4);
    background-color: rgba(65, 105, 225, 0.1);
  }

  .sortable-list-empty .sortable-list-drop-placeholder:after {
    border: 0;
  }
`

function isMenuItem(item: MenuItem | Page): item is MenuItem {
  return 'label' in item && typeof item.label !== 'undefined'
}

function processSortableItemUses(menus: Menu[], url: string): number {
  let count = 0
  for (const menu of menus) {
    for (const item of menu.menu_items) {
      if (url === item.route?.url) {
        count += 1
        continue
      }
      if (!item.route && item.group_menu_items?.length > 0) {
        count += item.group_menu_items.reduce((a, b) => {
          if (b.route && b.route.url === url) {
            return a + 1
          }
          return a
        }, 0)
      }
    }
  }
  return count
}

function getPageStatus(page: Page, buildVersion: BuildVersion): 'draft' | 'live' {
  const pageBuild = page[`${buildVersion}_page_build`]
  if (pageBuild && pageBuild.published_date) {
    return 'live'
  }
  return 'draft'
}

function formatSelectedMenuItems(
  sortableItems: MenuItem[] | Page[],
  menus: Menu[],
  siteBuildId: number,
  addUses: boolean,
  pageVersion: BuildVersion,
  allPages: Page[] = [],
  setAsNew = false
): SortableListItem[] {
  return sortableItems.map(item => {
    let sort_order = 0
    let original_sort_order = 0
    let category: keyof typeof itemCategoryAndColorMap = 'live'
    let parent_menu_item_id: string | undefined
    let itemSiteBuildId: string | undefined
    let uses: number | undefined = undefined
    let group_menu_items: SortableListItem[] | undefined = undefined
    let id = item.id
    let menu_id = null
    let isPage = false
    const label = isMenuItem(item) ? item.label : item.name

    if (isMenuItem(item)) {
      id = 'menu-item-' + item.id
      menu_id = item.menu_id
      parent_menu_item_id = item.parent_menu_item_id as string
      itemSiteBuildId = item.site_build_id
      sort_order = item.sort_order
      original_sort_order = item.sort_order
      group_menu_items = formatSelectedMenuItems(
        item.group_menu_items ?? [],
        menus,
        siteBuildId,
        addUses,
        pageVersion,
        allPages,
      )
      if (item.route?.route_type === RouteType[RouteType.EXTERNAL]) {
        category = 'link'
      } else if (Number(item.site_build_id) === Number(siteBuildId)) {
        category = 'group'
      } else {
        for (const page of allPages) {
          if (Number(item.route?.page_id) === Number(page.id)) {
            category = getPageStatus(page, pageVersion)
          }
        }
      }
    } else {
      id = 'page-item-' + item.id
      category = getPageStatus(item, pageVersion)
      isPage = true
    }
    if (addUses && item.route) {
      uses = processSortableItemUses(menus, item.route.url)
    }

    return {
      id,
      menu_id,
      site_build_id: itemSiteBuildId,
      is_page: isPage,
      is_new: setAsNew,
      label,
      category,
      sort_order,
      route: item.route,
      updated_at: item.updated_at,
      parent_menu_item_id,
      uses,
      group_menu_items,
      original_sort_order
    }
  }).filter(item => {
    if (item.is_page) {
      return typeof specialPages.find(sp => item.label.toLowerCase().includes(sp)) === 'undefined'
    }
    return true
  })
}

export function MenuEditor(
  {
    onChangeSelectedMenu,
    hasChanged,
    selectedMenu,
    siteBuildId,
    loading,
    menus,
    templateMenuItems,
    onMenuChange,
    pages,
    isLocked
  }: MenuEditorProps) {

  const router = useRouter()
  if (loading) {
    return <LoadingSpinner/>
  }

  const pageBuild = router.asPath.split('/').pop() as BuildVersion

  const menuFilterItems = formatSelectedMenuItems(
    templateMenuItems,
    menus,
    siteBuildId,
    true,
    pageBuild,
    [],
    true
  ).concat(
    formatSelectedMenuItems(
      pages,
      menus,
      siteBuildId,
      true,
      pageBuild,
      [],
      true
    )
  )
  return (
    <>
      <ScrollContainer flexBasis="58%">
        <MenuContainer id='menu-container'>
          <MenuHeader
            selectedMenu={selectedMenu?.name ?? 'No Menus Yet'}
            onMenuChange={onMenuChange}
            hasChanged={hasChanged}
            onChangeSelectedMenu={onChangeSelectedMenu}
            menu_options={menus.map(m => m.name)}
          />
          <SortableList
            acceptDrops={true}
            parentInfo={{menu_id: selectedMenu?.id ?? '0'}}
            onMenuChange={onMenuChange}
            group_name={MENU_EDITOR_GROUP_NAME}
            isLocked={isLocked}
            sortable_items={
              formatSelectedMenuItems(
                selectedMenu?.menu_items ?? [],
                menus,
                siteBuildId,
                false,
                pageBuild,
                pages
              )}
          />
        </MenuContainer>
      </ScrollContainer>
      <ScrollContainer flexBasis="42%">
        <MenuFilter
          isLocked={isLocked}
          menus={menus}
          onMenuChange={onMenuChange}
          sortable_items={menuFilterItems}
          parentInfo={{menu_id: selectedMenu?.id ?? '0'}}
        />
      </ScrollContainer>
    </>
  )
}

const MenuHeader = (
  {
    hasChanged,
    menu_options,
    selectedMenu,
    onMenuChange,
    onChangeSelectedMenu
  }: MenuEditorHeaderProps) => {


  const [newMenu, setNewMenu] = useState(false)
  const [deleteMenu, setDeleteMenu] = useState(false)

  function createNewMenu() {
    setNewMenu(true)
  }

  function onDelete() {
    setDeleteMenu(true)
  }

  return (
    <Grid.Container gap={2} alignItems='center'>
      <Grid xs><Text weight='bold' b>{selectedMenu}</Text></Grid>
      <Grid xs>
        <Button.Group flat bordered>
          <Dropdown>
            <Dropdown.Button size='sm'>
              <span aria-hidden>{selectedMenu}</span>
            </Dropdown.Button>
            <Dropdown.Menu
              selectedKeys={[selectedMenu] as Iterable<Key>}
              selectionMode='single'
              onSelectionChange={onChangeSelectedMenu}>
              {menu_options.map((name) => (
                <Dropdown.Item key={name}>{name}</Dropdown.Item>
              ))}
            </Dropdown.Menu>
          </Dropdown>
          <Button size='sm' onPress={createNewMenu}>New Menu</Button>
        </Button.Group>
      </Grid>
      <Grid xs>
        <Button.Group flat bordered>
          <Button
            size='sm'
            disabled={!hasChanged}
            onPress={() => window.alert('Feature not available')}>
            Cancel
          </Button>
          <Button
            size='sm'
            onPress={onDelete}>
            Delete Menu
          </Button>
        </Button.Group>
      </Grid>
      <DeleteMenuModal
        display={deleteMenu}
        setDeleteMenu={setDeleteMenu}
        onMenuChange={onMenuChange}
        selectedMenu={selectedMenu}
      />
      <CreateMenuModal
        display={newMenu}
        onMenusChange={onMenuChange}
        setNewMenu={setNewMenu}
      />
    </Grid.Container>
  )
}

function DeleteMenuModal(
  {display, selectedMenu, onMenuChange, setDeleteMenu}:
    {
      display: boolean,
      selectedMenu: string | null,
      onMenuChange: onMenuChangeAction,
      setDeleteMenu: Dispatch<SetStateAction<boolean>>
    }) {

  function onCancel() {
    setDeleteMenu(false)
  }

  function onConfirm() {
    onMenuChange({type: 'deleteMenu', payload: true})
  }


  return (
    <div>
      <Modal
        closeButton
        aria-labelledby="delete menu"
        open={display}
        onClose={onCancel}
      >
        <Modal.Header>
          <Text id="modal-title" size={18}>
            <Text b size={18}>
              {selectedMenu}
            </Text>
          </Text>
        </Modal.Header>
        <Modal.Body>
          Are you sure you wish to delete the selected menu?
        </Modal.Body>
        <Modal.Footer>
          <Button auto flat color="error" onPress={onCancel}>
            Cancel
          </Button>
          <Button auto onPress={onConfirm}>
            OK
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  )
}


function CreateMenuModal(
  {display, onMenusChange, setNewMenu}:
    {
      display: boolean,
      onMenusChange: onMenuChangeAction,
      setNewMenu: Dispatch<SetStateAction<boolean>>
    }) {

  const [name, setName] = useState<string>('')
  function onCancel() {
    setNewMenu(false)
  }

  function onConfirm() {
    onMenusChange({type: 'add', payload: {name}})
  }

  function onInputChanged({target}: ChangeEvent<FormElement>) {
    setName(target.value)
  }

  return (
    <div>
      <Modal
        closeButton
        aria-labelledby="delete menu"
        open={display}
        onClose={onCancel}
      >
        <Modal.Header>
          <Text id="modal-title" size={18}>
            <Text b size={18}>
              New Menu
            </Text>
          </Text>
        </Modal.Header>
        <Modal.Body>
          <Input
            aria-label='new menu name'
            placeholder='New Menu name'
            onChange={onInputChanged}
          />
        </Modal.Body>
        <Modal.Footer>
          <Button auto flat color="error" onPress={onCancel}>
            Cancel
          </Button>
          <Button disabled={name.trim() === ''} auto onPress={onConfirm}>
            OK
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  )
}
