/**
 * parseContentVariables replaces variables of the form `$variable.prop ` (note the trailing space) in content with values of the form `{variable: {prop: value}}`
 * from contentVariables without using regex. If the variable is not found in contentVariables, it is left as is
 */
export function parseContentVariables(content: string, contentVariables: {[key: string]: string}, previouslyParsedString: string = ''): string {
    if (content == null || content.length === 0) return previouslyParsedString
    const variableStart = content.indexOf('$')
    if (variableStart === -1) {
        return previouslyParsedString + content
    }

    const endingChars = [' ', ',', '\'', ';', '!', '?', ')', ']', '}', ':', '\t', '\n', '<']
    const variableEnd = endingChars.reduce((acc, char) => {
        const index = content.indexOf(char, variableStart)
        if (index === -1) return acc
        if (acc === -1) return index
        return Math.min(acc, index)
    }, -1)

    const variable = content.substring(variableStart + 1, variableEnd === -1 ? content.length : variableEnd)
    const variableValue = getVariableValue(variable, contentVariables)
    if (typeof variableValue === 'undefined') {
        return parseContentVariables(content.substring(variableStart + 1), contentVariables, previouslyParsedString + content.substring(0, variableStart + 1))
    }

    const newAccumlator = previouslyParsedString + content.substring(0, variableStart) + variableValue
    return parseContentVariables(content.substring(variableEnd === -1 ? content.length : variableEnd), contentVariables, newAccumlator)
}

function getVariableValue(variable: string, contentVariables: {[key: string]: string | object}): string | undefined {
    const delimiter: number = variable.indexOf('.')
    const currentKey: string = delimiter > -1 ? variable.substring(0, delimiter) : variable.substring(0)
    const rest = variable.substring(delimiter + 1)
    const value: string | object = contentVariables?.[currentKey]

    if (typeof value === 'object') {
        return getVariableValue(rest, value as {[key: string]: string | object})
    }

    return value
}
