import {StyleCoreState} from '@/src/state/site/StyleCoreStore'
import {ThemeState} from '@/src/state/site/ThemeStore'
import {Contents} from '@/components/shared/types'
import {marshalItems} from '@/components/editor/shared/marshalItems'
import {getFontNamesAsFlatArrayFromStyleCore} from '@/components/shared/StyleCore'
import Helpers from '@/src/utils/shared/helpers'
import {lucidDataFetcherV2} from '@/graphql/fetchers'
import {ADD_CONTENT, UPDATE_CONTENT} from '@/graphql/mutations'

export const updateSiteBuildContent = async ({StyleCore}: StyleCoreState, themeState: ThemeState, unmarshalledContents: Contents[], siteID: number) => {
  if (StyleCore.typography.map.size === 0) {
    return
  }
  const contentItems = marshalItems({
    meta: {
      styles: {
        fonts: JSON.stringify(getFontNamesAsFlatArrayFromStyleCore({StyleCore}) ?? {}),
        css: themeState.components['global' as keyof typeof themeState.components] ?? ''
      }
    }
  }, '>')
  for (const dataPoint of contentItems) {
    const existingDataPoint = Helpers.contents.getContentIDFromOriginalContents(dataPoint.name, unmarshalledContents)
    if (typeof existingDataPoint !== 'undefined') {
      if (Helpers.contents.getContentValueFromOriginalContents(dataPoint.name, unmarshalledContents) === dataPoint.value) {
        continue
      }
      await lucidDataFetcherV2(UPDATE_CONTENT, {
        id: existingDataPoint,
        updateContentData: {
          value: dataPoint.value,
        }
      })
    } else {
      await lucidDataFetcherV2(ADD_CONTENT, {
        newContent: {
          name: dataPoint.name,
          value: dataPoint.value,
          site_id: Number(siteID),
          scope: 'SITE'
        }
      })
    }
  }
}