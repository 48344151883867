import {PaddingFields} from '../fields/PaddingFields'
import {MarginFields} from '../fields/MarginFields'
import {PositionOptions} from '../options/PositionOptions'
import {PositioningFields} from '../fields/PositioningFields'
import {DisplayFields} from '../fields/DisplayFields'
import {BorderOptions} from '../options/BorderOptions'
import {BorderFields} from '../fields/BorderFields'
import {BoxShadowFields} from '../fields/BoxShadowFields'
import {BackgroundFields} from '../fields/BackgroundFields'

export const Box = {
    createFields(name = 'box', defaults = {}) {
        return {
            [name]: {
                content: {
                    spacing: {
                        content: {
                            ...PaddingFields.createFields(defaults.spacing?.padding ?? {}),
                            ...MarginFields.createFields(defaults.spacing?.margin ?? {}),
                        }
                    },
                    ...DisplayFields.createFields(defaults.display ?? {}),
                    decoration: {
                        content: {
                            ...BorderFields.createFields(defaults.decoration?.border ?? {}),
                            ...BoxShadowFields.createFields(defaults.decoration?.boxShadow ?? {}),
                            ...BackgroundFields.createFields(defaults.decoration?.background ?? {}),
                        }
                    }
                }
            }
        }
    },
    createCSS(selector, prefix = '') {
        return `
            {target} .${selector} {
                ${PaddingFields.createPartialCSS(prefix + 'spacing.')}
                ${MarginFields.createPartialCSS(prefix + 'spacing.')}
                ${DisplayFields.createPartialCSS(prefix)}
                ${BorderFields.createPartialCSS(prefix + 'decoration.')}
                ${BoxShadowFields.createPartialCSS(prefix + 'decoration.')}
                ${BackgroundFields.createPartialCSS(prefix + 'decoration.')}
            }
         `
    }
}