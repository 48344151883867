import React from 'react'
import {useForm} from 'react-hook-form'
import {yupResolver} from '@hookform/resolvers/yup'
import * as yup from 'yup'

const schema = yup
  .object({
    contact_name: yup.string().required('Name is required'),
    contact_email: yup
      .string()
      .email('Must be a valid email')
      .required('Email is required'),
    contact_phone: yup.string().required('Phone is required'),
    contact_message: yup.string().required('Message is required'),
  })
  .required()

export default function SectionContactForm() {
  const {
    register,
    handleSubmit,
    formState: {errors},
  } = useForm({
    resolver: yupResolver(schema),
  })

  const onSubmit = (data) => console.log(data)

  return (
    <>
      <form onSubmit={handleSubmit(onSubmit)} className="section-form">
        <div className="field-group">
          <label htmlFor="name">Name</label>
          <input
            {...register('contact_name')}
            id="name"
          />
          <div className="field-error">
            <p>{errors.contact_name?.message}</p>
          </div>
        </div>
        <div className="field-group">
          <label htmlFor="email">Email</label>
          <input
            {...register('contact_email')}
            id="email"
          />
          <div className="field-error">
            <p>{errors.contact_email?.message}</p>
          </div>
        </div>
        <div className="field-group">
          <label htmlFor="phone">Phone Number</label>
          <input
            {...register('contact_phone')}
            id="phone"
          />
          <div className="field-error">
            <p>{errors.contact_phone?.message}</p>
          </div>
        </div>
        <div className="field-group">
          <label htmlFor="message">Message</label>
          <textarea
            {...register('contact_message')}
            rows="5"
            id="message"
          />
          <div className="field-error">
            <p>{errors.contact_message?.message}</p>
          </div>
        </div>
        <div className="form-group section-button">
          <button type="submit" className="button">Submit</button>
        </div>
      </form>
    </>
  )
}

