import Sections from '@/components/sections'
import {convertTargetToKey} from '@/components/shared/StyleCore'

const typographyGroupOverrideField = {
    typography: {
        content: {
            typographyOverride: {
                label: 'Typography Group',
                content: 'typography',
                default: 'default',
            }
        }
    },
}


export default function createInitialStyleCoreElementMap() {
    const styleCoreMap = new Map()
    for (const [, section] of Object.entries(Sections)) {
        if (typeof section.StyleCore !== 'undefined') {
            section.StyleCore.config = {
                ...section.StyleCore.config, ...typographyGroupOverrideField
            }
            styleCoreMap.set(convertTargetToKey(section.StyleCore.target), section.StyleCore)
        }
    }
    return styleCoreMap
}
