import Section from './shared/Section'
import StyledHeading from './shared/StyledHeading'
import {InlineTextWithVariables, RichtextWithVariables} from './shared/SectionsWithVariables'

export default function Wildcard(props) {
  const {
    richtext,
    color_scheme,
    className,
    css_overrides,
    color_scheme_id_override, lucid_page_section_id, meta,
    cms,
  } = props
  return (
    <Section
      cms={cms}
      css_overrides={css_overrides}
      color_scheme_id_override={color_scheme_id_override} meta={meta}
      lucid_page_section_id={lucid_page_section_id}
      className={className + ' stack'}
      name="wildcard"
      colorSchemeId={color_scheme}>
        <div className="section-container">
            <div className="section-content">
                <div className="row expanded">
                    <div className="column">
                        <div className="section-richtext paragraph">
                            <RichtextWithVariables richtext={richtext} cms={cms} name={'richtext'}/>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div className="section-background"></div>
    </Section>
  )
}

Wildcard.StyleCore = {
  config: {},
  target: {
    componentName: Object.keys({Wildcard})[0].toLowerCase(),
  },
  css: ''
}


