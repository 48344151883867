import Section from './shared/Section'
import {InlineTextWithVariables} from './shared/SectionsWithVariables'

export default function Cliffhanger(props) {
  const {
    text,
    color_scheme,
    className,
    css_overrides,
    color_scheme_id_override,
    lucid_page_section_id,
    meta,
    cms,
  } = props
  return (
    <Section
      cms={cms}
      css_overrides={css_overrides}
      color_scheme_id_override={color_scheme_id_override}
      meta={meta}
      lucid_page_section_id={lucid_page_section_id}
      className={className + ' section cliffhanger-section stack'}
      name="cliffhanger"
      colorSchemeId={color_scheme}
    >
      <div className="section-container">
        <div className="section-content">
          <div className="row">
            <div className="column">
              <div className="section-richtext paragraph">
                <p>
                  <InlineTextWithVariables
                    name="text"
                    value={text}
                    cms={cms}
                  />
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="section-background"></div>
    </Section>
  )
}

Cliffhanger.StyleCore = {
  config: {},
  target: {
    componentName: Object.keys({Cliffhanger})[0].toLowerCase(),
  },
  css: '',
}
