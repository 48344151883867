import {BlockContents, Contents} from '@/components/shared/types'

function isContentValue(value: any): value is string {
  return typeof value !== 'object' || value === null
}

function isObject(obj: any): obj is object {
  return obj && typeof obj === 'object'
}

function deflateItems(name: string, value: any, separator = '.'): Partial<Contents>[] {
  const items: Partial<Contents>[] = []
  let iterableItems = Array.isArray(value) ? value.entries() : Object.entries(value)

  for (const [i, valueElement] of iterableItems) {
    if (typeof valueElement !== 'object') {
      items.push({name: name + separator + i, value: valueElement})
      continue
    }
    for (const [k, v] of Object.entries(valueElement)) {
      const key = name + separator + i + separator + k
      if (typeof v === 'string') {
        items.push({name: key, value: v})
      } else {
        const nestedItems = deflateItems(key, v, separator)
        for (const nestedItem of nestedItems) {
          items.push({name: nestedItem.name, value: nestedItem.value})
        }
      }
    }
  }
  return items
}

function inflateItems(item: string[], value: Record<string, any> | string | number): Record<string, any> {
  const [f, s, ...rest] = item
  if (!f) {
    return value as Record<string, any>
  }
  if (!s) {
    return {[f]: value}
  }
  return {[f]: {[s]: inflateItems(rest, value)}}
}

function mergeInflatedItems(...items: Record<string, any>[]) {
  return items.reduce((prev, item) => {
    Object.keys(item).forEach((key) => {
      let prevVal = prev[key]
      const itemVal = item[key]

      if (!isNaN(Number(key))) {
        if (!prevVal) {
          prevVal = []
        }
        if (!Array.isArray(prev)) {
          prev = []
        }
        if (isObject(prevVal) && isObject(itemVal)) {
          prev[Number(key)] = mergeInflatedItems(prevVal, itemVal)
        } else {
          prev[Number(key)] = prevVal.concat(...[itemVal])
        }
      } else if (isObject(prevVal) && isObject(itemVal)) {
        prev[key] = mergeInflatedItems(prevVal, itemVal)
      } else {
        prev[key] = itemVal
      }
    })
    return prev
  }, {})
}

export const marshalItems = (blockContents: BlockContents, separator = '.') => {
  const ignoredContentKeys = ['className', 'cms', 'color_scheme_id_override', 'key']
  const itemsArray: Partial<Contents>[] = []
  for (const [name, value] of Object.entries(blockContents)) {
    if (ignoredContentKeys.filter(ick => name.endsWith(ick)).length > 0) continue
    if (value === null) continue
    if (!isContentValue(value)) {
      itemsArray.push(...deflateItems(name, value, separator))
      continue
    }
    itemsArray.push({name, value})
  }
  return itemsArray
}

export const unmarshalItems = (blocksContent: BlockContents[], separator = '.'): BlockContents[] => {
  const items = []
  for (const blockContent of blocksContent) {
    const unflattenedItems = []
    const content: BlockContents = {}
    for (const [key, value] of Object.entries(blockContent)) {
      if (key.indexOf(separator) === -1) {
        content[key] = value
        continue
      }
      unflattenedItems.push(inflateItems(key.split(separator), value as string))
    }
    items.push({...content, ...mergeInflatedItems(...unflattenedItems)})
  }
  return items
}
