import React, {CSSProperties, FC, useEffect, useState} from 'react'
import styled from 'styled-components'
import WizardLayout from '../../../../shared/WizardLayout'
import {PageBuild} from '../../../../shared/types'
import {stagesColor} from '../../constants'
import PagesFilter from '../PagesFilter'
import {applyFiltering, genericSearch} from '../../utils'
import CSS from 'csstype'

interface NewProjectWizardProps {
  showDialog: boolean
  closeDialog: () => void
  onSave: () => void
  pages: PageBuild[]
}

interface Options {
  copySection: boolean
  copySite: boolean
  copyRoutes: boolean
  copyMenu: boolean
}

const StyledSpan = styled.span`
  margin-right: 20px;
  color: ${(props) => props.color || 'black'};
`

const StyledInput = styled.input`
  width: 100%;
  padding: 12px 20px;
  margin: 8px 0 25px 0;
  display: inline-block;
  border: ${(props) => (props.value ? '1px solid #ccc' : '1px solid red')};
  border-radius: 4px;
  box-sizing: border-box;
`

const Tags = styled.span`
  background-color: var(--tina-color-primary);
  color: white;
  border-radius: 3px;
  padding: 4px 14px;
  margin: 5px;
  font-size: 0.7em;
`

const NewProjectWizard = ({pages, ...other}: NewProjectWizardProps) => {
  const [projectName, setProjectName] = useState(
    new Date().toDateString().replaceAll(' ', '-') + '-Project'
  )
  const [selectedPages, setSelectedPages] = useState<string[]>([])
  const [options, setOptions] = useState<Options>({
    copySection: false,
    copySite: false,
    copyRoutes: false,
    copyMenu: false,
  })

  return (
    <WizardLayout
      {...other}
      isNextAllowed={!!projectName && !!selectedPages.length}
      title="Create new Project"
    >
      <div>
        <label htmlFor="new-page-name">
          How would you like to name this new page?
        </label>
        <StyledInput
          type="text"
          value={projectName}
          name="new-page-name"
          onChange={(e) => setProjectName(e.target.value)}
        />
        <PagesArrayWithHeader
          pages={pages}
          dispatchSelectedPages={setSelectedPages}
        />
      </div>
      <div>
        <CopyOptions
          options={options}
          setOptions={setOptions}
        />
      </div>
      <div>
        <span>
          Summary for project: <strong>{projectName}</strong>
        </span>
        <br />
        <br />
        <div
          style={{
            display: 'flex',
            justifyContent: 'space-evenly',
          }}
        >
          <div>
            <span>Pages to be moved:</span>
            <br />
            <br />
          </div>
          <div>
            {pages
              .filter((page) => selectedPages.includes(page.id))
              .reduce<string[]>((acc, curr) => [...acc, curr.page?.name], [])
              .map((name, idx) => (
                <div key={idx}>
                  <span>{name}</span>
                </div>
              ))}
          </div>
          <div>Elements copied over:</div>
          <div>
            <CopyOptions
              options={options}
              readOnly
            />
          </div>
        </div>
      </div>
    </WizardLayout>
  )
}

// if readOnly is defined then setOptions cannot be passed
interface CopyOptionsProps1 {
  options: Options
  setOptions: (options: Options) => void
  readonly?: never
}

interface CopyOptionsProps2 {
  options: Options
  readOnly: true
  setOptions?: never
}

type CopyOptionsProps = CopyOptionsProps1 | CopyOptionsProps2

const CopyOptions = (props: CopyOptionsProps) => {
  const {options} = props
  const readOnly = 'readOnly' in props

  const handleChange = (key: keyof Options) => {
    if ('setOptions' in props && props.setOptions) {
      options[key] = !options[key]
      props.setOptions({...options})
    }
  }

  return (
    <>
      <div>
        <input
          type="checkbox"
          name="copy-section"
          value="copy-section"
          checked={options.copySection}
          onChange={() => handleChange('copySection')}
          disabled={readOnly}
        />
        <label htmlFor="copy-section">Sections</label>
      </div>
      <div>
        <input
          type="checkbox"
          name="copy-site"
          value="copy-site"
          checked={options.copySite}
          onChange={() => handleChange('copySite')}
          disabled={readOnly}
        />
        <label htmlFor="copy-site">Site content</label>
      </div>
      <div>
        <input
          type="checkbox"
          name="copy-routes"
          value="copy-routes"
          checked={options.copyRoutes}
          onChange={() => handleChange('copyRoutes')}
          disabled={readOnly}
        />
        <label htmlFor="copy-routes">Routes</label>
      </div>
      <div>
        <input
          type="checkbox"
          name="copy-menu"
          value="copy-menu"
          checked={options.copyMenu}
          onChange={() => handleChange('copyMenu')}
          disabled={readOnly}
        />
        <label htmlFor="copy-menu">Menu items</label>
      </div>
    </>
  )
}

interface PagesArrayWithHeaderProps {
  pages: PageBuild[]
  dispatchSelectedPages: (pages: string[]) => void
  initSelectedPages?: string[]
  filterAction?: { filterStatus: string; filterAssignee: string }
  style?: CSS.Properties
}

export const PagesArrayWithHeader = ({
  pages,
  initSelectedPages,
  dispatchSelectedPages,
  filterAction,
  style,
}: PagesArrayWithHeaderProps) => {
  const [searchInput, setSearchInput] = useState('')
  const [filterState, setFilterState] = useState(filterAction || null)
  const [selectedPages, setSelectedPages] = useState(initSelectedPages || [])

  useEffect(() => {
    dispatchSelectedPages(selectedPages)
  }, [dispatchSelectedPages, selectedPages])

  const filteredPages = pages.filter((page) =>
    applyFiltering(page, filterState)
  )

  const searchedPages = filteredPages.filter((page) =>
    genericSearch(page, searchInput)
  )

  const handleChange = (pageId: string) => {
    if (selectedPages.includes(pageId)) {
      setSelectedPages([...selectedPages.filter((id) => id !== pageId)])
    } else {
      setSelectedPages([...selectedPages, pageId])
    }
  }

  const selectAllPages = () => {
    if (!selectedPages.length) {
      setSelectedPages(
        pages.reduce<string[]>((acc, curr) => [...acc, curr.id], [])
      )
    } else {
      setSelectedPages([])
    }
  }

  return (
    <div style={style}>
      <FilterHeader
        pages={pages}
        setFilterState={setFilterState}
        searchInput={searchInput}
        setSearchInput={setSearchInput}
        style={{paddingTop: '15px'}}
        initialFilterState={filterAction}
      />
      <br />
      <span>
        Select the pages to be moved:{' '}
        <button onClick={selectAllPages}>
          {selectedPages.length ? 'Unselect All' : 'Select All'}
        </button>
      </span>

      <PagesArray
        selectedPages={selectedPages}
        handleChange={handleChange}
        searchedPages={searchedPages}
      />
    </div>
  )
}

interface PagesArrayProps {
  searchedPages: PageBuild[]
  selectedPages: string[]
  handleChange: (id: string) => void
  style?: CSSProperties
}

export const PagesArray: FC<PagesArrayProps> = ({
  searchedPages,
  selectedPages,
  handleChange,
  style,
}: PagesArrayProps) => (
  <div
    style={{
      ...style,
      marginLeft: '20px',
    }}
  >
    {searchedPages.map((pageBuild) => (
      <div
        key={pageBuild.id}
        style={{
          padding: '20px 0',
          borderBottom: '1px solid #ccc',
          display: 'flex',
          alignItems: 'center',
        }}
      >
        <input
          type="checkbox"
          value={pageBuild.id}
          name="new-project-select"
          checked={selectedPages.includes(pageBuild.id)}
          onChange={() => handleChange(pageBuild.id)}
          style={{transform: 'scale(1.2)', margin: '0 10px'}}
        />
        <StyledSpan>{pageBuild.page?.name}</StyledSpan>
        <StyledSpan color={stagesColor[pageBuild.status]}>
          {pageBuild.status}
        </StyledSpan>
        <StyledSpan>
          {pageBuild.assigned_to_user?.name || 'unassigned'}
        </StyledSpan>
        <div>
          {pageBuild.page?.attributes?.page_tags.map((tag) => (
            <Tags key={tag}>{tag}</Tags>
          ))}
        </div>
      </div>
    ))}
  </div>
)

interface FilterState {
  filterStatus: string
  filterAssignee: string
}

interface FilterHeaderProps {
  pages: PageBuild[]
  searchInput: string
  setSearchInput: (input: string) => void
  initialFilterState?: { filterStatus: string; filterAssignee: string }
  setFilterState: (input: FilterState) => void
  style?: { [k: string]: string }
}

export const FilterHeader = ({
  pages,
  searchInput,
  setSearchInput,
  initialFilterState,
  setFilterState,
  style,
}: FilterHeaderProps) => {
  return (
    <div
      style={{
        display: 'flex',
        alignItems: 'center',
        position: 'sticky',
        top: 0,
        backgroundColor: 'white',
        zIndex: 10,
        ...style,
      }}
    >
      <input
        placeholder="Search"
        value={searchInput}
        onChange={(e) => setSearchInput(e.target.value)}
        style={{
          height: '40px',
          marginRight: '12px',
          padding: '12px',
          border: '1px solid var(--tina-color-grey-2)',
        }}
      />
      <div style={{display: 'flex', marginRight: '12px'}}>
        <span style={{marginRight: '12px', color: '#757575'}}>Filters:</span>
        <PagesFilter
          pageBuilds={pages}
          onChange={setFilterState}
          filters={['user']}
          initialFilterState={initialFilterState}
        />
      </div>
    </div>
  )
}

export default NewProjectWizard
