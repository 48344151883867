import styled from 'styled-components'

export const TableContainer = styled.div`
  padding: 0 var(--tina-padding-big);
`
// TODO: Had to override Tina global/reset styles... should we replace them with our own?
export const Table = styled.table`
  border-collapse: collapse;
  border-spacing: 0;
  width: 100%;

  &.route-manager-table {
    td:first-child {
      width: 1px;
    }
    td {
      width: auto;
      white-space: nowrap;
      text-align: left;
      font-size: var(--tina-font-size-2);
      padding: var(--tina-padding-small) var(--tina-padding-big);
    }
    tbody {
      box-shadow: var(--tina-shadow-big);
      overflow: none;
    }
    tbody tr {
      background-color: var(--tina-color-grey-0);
      cursor: pointer;
      &:hover,
      &:active {
        background-color: var(--tina-color-grey-1);
      }
    }
    tbody td {
      border-bottom: 1px solid #ececec;
    }
  }
`

// export const TableHead = styled.thead``
// export const TableCheckbox = styled.td``
// export const TableBody = styled.tbody``
// export const TableRow = styled.tr``
// export const TableColumn = styled.td``
