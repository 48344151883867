import {toast} from 'react-toastify'

export interface ColorSchemeValues {
  rootColorSchemeId: number
  colorSchemeID: number
  scheme_name: string
  name: string,
  active: boolean,
  [key: symbol]: ColorSchemeValuesValue
}

type ColorSchemeValuesValue = ColorSchemeValues | string | number | boolean

export interface ColorSchemeLayoutItem {
  label: string
  name: string
  description?: string
  component: 'color' | 'toggle' | 'group' | 'number' | 'text' | 'group-list' | 'image'
}

export interface ColorSchemeLayoutColor extends ColorSchemeLayoutItem {
  itemProps: any
  defaultItem: any
}

export type ColorSchemeLayoutParent = ColorSchemeLayoutItem

export type ColorSchemeLayoutText = ColorSchemeLayoutItem

export interface ColorSchemeLayoutToggle extends ColorSchemeLayoutItem {
  toggleLabels: {
    true: string,
    false: string
  }
}

export interface ColorSchemeLayoutNumber extends ColorSchemeLayoutItem {
  step: number
}

export interface ColorSchemeLayoutGroup extends ColorSchemeLayoutItem {
  fields: ColorSchemeLayoutItem[]
}

export type ColorSchemeLayoutElement = (ColorSchemeLayoutParent | ColorSchemeLayoutItem | ColorSchemeLayoutColor | ColorSchemeLayoutText | ColorSchemeLayoutToggle | ColorSchemeLayoutNumber | ColorSchemeLayoutGroup)

export interface GraphQLColorSchemeComponentContentResponse {
  hex?: string,
  URL?: string,
  range?: number,
  checkbox?: boolean,
  SVG?: string
}

export interface GraphQLColorSchemeComponentResponse {
  id: number,
  label: string,
  type: 'RANGE' | 'HEX' | 'URL' | 'CHECKBOX' | 'SVG',
  component_content: GraphQLColorSchemeComponentContentResponse,
  component_attributes?: {
    min?: number,
    max?: number,
  }
}

export interface GraphQLColorSchemeResponse {
  id: string,
  name: string,
  active: boolean,
  parent_color_scheme_id?: number,
  site_build_id: number,
  components: GraphQLColorSchemeComponentResponse[],
  children_color_schemes: GraphQLColorSchemeResponse[]
}

const computeInitialValues = (incomingColorSchemes: GraphQLColorSchemeResponse, appendIDs = true) => {
  const addition: ColorSchemeValues | any = {}
  if (appendIDs) addition.colorSchemeID = incomingColorSchemes.id
  for (const additionElement of incomingColorSchemes.components) {
    const componentType = reformatComponentTypeKey(additionElement.type)
      addition[additionElement.label as keyof ColorSchemeValues] = additionElement.component_content[componentType as keyof GraphQLColorSchemeComponentContentResponse] as ColorSchemeValuesValue
  }

  if (typeof incomingColorSchemes.children_color_schemes !== 'undefined') {
    for (const childColorScheme of incomingColorSchemes.children_color_schemes) {
        addition[childColorScheme.name as keyof ColorSchemeValues] = computeInitialValues(childColorScheme, appendIDs)
    }
  }
  return addition
}

export const computeColorSchemeValues = (colorSchemes: GraphQLColorSchemeResponse[]) => {
  return colorSchemes.map((scheme) => {
    return {
      rootColorSchemeId: scheme.id,
      scheme_name: scheme.name,
      name: scheme.name,
      active: scheme.active,
      ...computeInitialValues(scheme)
    }
  }) as ColorSchemeValues[]
}
export const getFormConfig = (colorSchemes: GraphQLColorSchemeResponse[], onSubmit: (values: { schemes: ColorSchemeValues[], activeScheme: string }) => void) => {
  return {
    id: 'color-schemes',
    label: 'Schemes',
    initialValues: {
      'schemes': computeColorSchemeValues(colorSchemes),
      'activeScheme': (colorSchemes.find((scheme: GraphQLColorSchemeResponse) => scheme.active) ?? colorSchemes[0])?.id
    },
    fields: [
      {
        name: 'schemes',
        label: 'Color Schemes',
        component: 'group-list',
        itemProps: ({id: key, name: label}: Record<string, any>) => {
          return {key, label}
        },
        defaultItem: {
          ...computeInitialValues(colorSchemes[0], false),
          name: 'New Color Scheme'
        },
        fields: [
          {
            label: 'Name',
            name: 'scheme_name',
            component: 'text',
          },
          ...computeInternalStructure(colorSchemes[0])
        ]
      },
      {
        component: 'select',
        name: 'activeScheme',
        label: 'Site Color Scheme',
        description: 'Select an active site color scheme to be used on the site.',
        options: colorSchemes.sort(a => a.active ? -1 : 1).map((scheme) => ({label: scheme.name, value: scheme.id})),
      },
    ],
    onSubmit,
  }
}

const computeInternalStructure = (incomingColorScheme: GraphQLColorSchemeResponse) => {
  if (typeof incomingColorScheme === 'undefined') return []
  const addition: any[] = []
  const remapping = {
    'RANGE': 'number',
    'HEX': 'color',
    'URL': 'text',
    'CHECKBOX': 'toggle',
    'SVG': 'image'
  }
  for (const additionElement of incomingColorScheme.components) {
    addition.push({
      name: additionElement.label,
      component: remapping[additionElement.type] as ColorSchemeLayoutElement['component'],
      label: additionElement.label.replaceAll('_', ' ').toLowerCase()
        .split(' ')
        .map((s) => s.charAt(0).toUpperCase() + s.substring(1))
        .join(' ')
    })
  }

  if (typeof incomingColorScheme.children_color_schemes !== 'undefined') {
    for (const childColorScheme of incomingColorScheme.children_color_schemes) {
      addition.push({
        name: childColorScheme.name,
        label: childColorScheme.name.replaceAll('_', '').toLowerCase()
          .split(' ')
          .map((s) => s.charAt(0).toUpperCase() + s.substring(1))
          .join(' '),
        component: 'group',
        fields: computeInternalStructure(childColorScheme)
      })
    }
  }
  return addition
}

export const computeChanges = (values: ColorSchemeValues[], activeSchemeValue: string, previous: GraphQLColorSchemeResponse[], siteBuild: number): [any[], any[], any[], any[], any, boolean] => {
  const schemes: { color_scheme_id: number, name: string }[] = []
  const components: any[] = []
  const newSchemes: any[] = []
  let activeScheme: number | undefined = undefined
  let removableSchemes: any[] = []
  let hasError = false

  const processColorScheme = (
    colorScheme: ColorSchemeValues,
    initialColorScheme?: GraphQLColorSchemeResponse,
    originalChildrenColorScheme?: GraphQLColorSchemeResponse['children_color_schemes'][number],
  ) => {
    const currentColorSchemeID = colorScheme.colorSchemeID
    if (typeof currentColorSchemeID === 'undefined' && !initialColorScheme) {
        newSchemes.push(Object.assign({site_build_id: parseInt(String(siteBuild))}, createNewColorScheme(colorScheme)))
      return
    }
    if (!initialColorScheme) {
      initialColorScheme = previous.find((scheme) => Number(scheme.id) === Number(currentColorSchemeID))
    }
    if (!initialColorScheme) return
    for (const key in colorScheme) {
      if (key === 'colorSchemeID') continue
      if (key === 'scheme_name' && initialColorScheme.name !== colorScheme.scheme_name) {
        schemes.push({color_scheme_id: currentColorSchemeID as number, name: colorScheme.scheme_name as string})
        continue
      }
      const currentElementValue = colorScheme[key as keyof ColorSchemeValues]
      if (typeof currentElementValue === 'object') {
        const existingChildrenColorSchemes = initialColorScheme.children_color_schemes.find(c => c.name === key)
        processColorScheme(currentElementValue, initialColorScheme, existingChildrenColorSchemes)
        continue
      }
      let originalComponent = originalChildrenColorScheme?.components?.find(c => c.label === key)
      if (!originalComponent && !originalChildrenColorScheme) {
        originalComponent = initialColorScheme?.components?.find(c => c.label === key)
      }
      if (!originalComponent) continue
      const accessKey = reformatComponentTypeKey(originalComponent.type) as keyof typeof originalComponent.component_content
      if (originalComponent.component_content[accessKey] !== currentElementValue) {
        components.push({
          color_scheme_id: originalChildrenColorScheme?.id ?? initialColorScheme.id,
          component_id: originalComponent.id,
          component_content: {
            [accessKey]: currentElementValue
          }
        })
      }
    }
  }
  for (const colorScheme of values) {
    if (verifyColorSchemeHasName(colorScheme.scheme_name)) {
      processColorScheme(colorScheme)
    } else {
      toast('Color scheme name is required', {type: 'error'})
      hasError = true
    }
  }
  if (activeSchemeValue) {
    const activeSchemeID = parseInt(activeSchemeValue)
    if (Number(previous.find(scheme => scheme.active)?.id) !== activeSchemeID) {
      activeScheme = activeSchemeID
    }
  }
  if (values.length < previous.length) {
    removableSchemes = removableSchemes.concat(findRemovableColorSchemes(previous, values))
  }
  return [schemes, components, newSchemes, removableSchemes, activeScheme, hasError]
}

const verifyColorSchemeHasName = (name: string|undefined) => {
  if (typeof name === 'undefined') {
    return false
  }
  return name.length > 0
}
const findRemovableColorSchemes = (colorSchemes: GraphQLColorSchemeResponse[], colorSchemeValues: ColorSchemeValues[]) => {
    const deletableColorSchemes: GraphQLColorSchemeResponse[] = []
    for (const colorScheme of colorSchemes) {
        if (colorSchemeValues.find(c => c.colorSchemeID === Number(colorScheme.id))) continue
        deletableColorSchemes.push(colorScheme)
    }
    return deletableColorSchemes
}

const createNewColorScheme = (colorScheme: ColorSchemeValues): any => {
  return {
    name: colorScheme.name === 'New Color Scheme' ? colorScheme.scheme_name : colorScheme.name,
    components: Object.entries(colorScheme)
        .filter(([key, value]) => key !== 'name' && key !== 'scheme_name' && typeof value !== 'object')
        .map(([key, value]) => {
          return {
            label: key,
            type: findComponentType(value).toUpperCase(),
            component_content: {
              [reformatComponentTypeKey(findComponentType(value))]: value
            }
          }
        }),
    children_color_schemes: Object.entries(colorScheme)
        .filter(([, value]) => typeof value === 'object')
        .map(([key, value]) => {
          if (typeof value === 'object') {
            return createNewColorScheme(Object.assign(value, {name: key}))
          }
        })
  }
}

const findComponentType = (componentContent: any) => {
  if (typeof componentContent === 'number') return 'RANGE'
  if (typeof componentContent === 'boolean') return 'CHECKBOX'
  if (String(componentContent).startsWith('http')) return 'URL'
  if (typeof componentContent === 'string' && String(componentContent).startsWith('#')) return 'HEX'
  return 'SVG'
}

const reformatComponentTypeKey = (key: string): string => {
  const uc = key.toUpperCase()
  if (['URL', 'SVG'].includes(uc)) {
    return uc
  }
  return key.toLowerCase()
}
