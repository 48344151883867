import {Button, Modal, Text} from '@nextui-org/react'
import React, {useState} from 'react'
import {CloseCircle, InfoCircle, TickCircle, Warning2} from 'iconsax-react'

type SentimentType = 'success' | 'error' | 'warning' | 'default';

export type AlertDialogProps = {
  title: string
  message: string
  sentiment?: SentimentType
  confirmText?: string
  setIsOpenState?: boolean
}

export type ConfirmDialogProps = {
  cancelText?: string
  asConfirm?: boolean
} & AlertDialogProps

export type ConfirmDialogCustomCallback = {
  callback: (result: boolean) => void
}

type ConfirmationDialogContextType = {
  openDialog: (options: ConfirmDialogProps) => void
}

const SentimentIcon = ({sentiment}: { sentiment: SentimentType }) => {
  switch (sentiment) {
    case 'success':
      return <TickCircle size={36} />
    case 'error':
      return <CloseCircle size={36} />
    case 'warning':
      return <Warning2 size={36} />
    default:
      return <InfoCircle size={36} />
  }
}

const BaseDialog = ({title, message, callback, sentiment, confirmText, cancelText, setIsOpenState, asConfirm}: ConfirmDialogProps & ConfirmDialogCustomCallback) => {
  const [isOpen, setIsOpen] = useState(setIsOpenState)
  const open = () => setIsOpen(true)
  const close = () => setIsOpen(false)
  const confirm = () => {
    callback(true)
    close()
  }
  const cancel = () => {
    callback(false)
    close()
  }
  if (!sentiment) {
    sentiment = 'default'
  }
  return (
    <Modal
      preventClose={true}
      aria-labelledby="modal-title"
      open={isOpen}
      onClose={close}
    >
      <Modal.Header></Modal.Header>
      <Modal.Body>
        <Text color={sentiment}>
          <SentimentIcon sentiment={sentiment} />
        </Text>
        <Text h4 color={sentiment}>
          {title}
          <Text color={sentiment}>
            {message}
          </Text>
        </Text>

      </Modal.Body>
      <Modal.Footer>
        <Button auto style={{
          width: '100%',
        }} color={sentiment} onPress={confirm}>
          {confirmText || 'Confirm'}
        </Button>
        {asConfirm ? <Button style={{
          width: '100%',
        }} color={sentiment} flat auto onPress={cancel}>
          {cancelText || 'Cancel'}
        </Button> : null}
      </Modal.Footer>
    </Modal>
  )
}

const AlertDialog = ({title, message, callback, sentiment, confirmText, setIsOpenState}: AlertDialogProps & ConfirmDialogCustomCallback) => {
  return (
    <BaseDialog
      title={title}
      message={message}
      callback={callback}
      sentiment={sentiment}
      confirmText={confirmText}
      setIsOpenState={setIsOpenState}
    />
  )
}

const ConfirmDialog = ({title, message, callback, sentiment, confirmText, cancelText, setIsOpenState}: ConfirmDialogProps & ConfirmDialogCustomCallback) => {
  return (
    <BaseDialog
      title={title}
      message={message}
      callback={callback}
      asConfirm={true}
      sentiment={sentiment}
      confirmText={confirmText}
      cancelText={cancelText}
      setIsOpenState={setIsOpenState}
    />
  )
}

const ContextualizedBaseDialog = ({title, message, callback, sentiment, confirmText, cancelText, asConfirm, setIsOpenState}: ConfirmDialogProps & ConfirmDialogCustomCallback) => {
  return (
    <BaseDialog
      title={title}
      message={message}
      callback={callback}
      sentiment={sentiment}
      confirmText={confirmText}
      cancelText={cancelText}
      setIsOpenState={setIsOpenState}
      asConfirm={asConfirm}
    />
  )
}

const ConfirmationDialogContext = React.createContext<ConfirmationDialogContextType>({
  openDialog: (options: ConfirmDialogProps) => {}
})

const ConfirmationDialogProvider = ({children}: any) => {
  const [dialogOpen, setDialogOpen] = React.useState<boolean>(false)
  const [dialogConfig, setDialogConfig] = React.useState<ConfirmDialogProps & ConfirmDialogCustomCallback | undefined>(undefined)

  const openDialog = ({title, message, callback, sentiment, confirmText, cancelText, asConfirm}: ConfirmDialogProps & ConfirmDialogCustomCallback) => {
    setDialogOpen(true)
    setDialogConfig({title, message, callback, sentiment, confirmText, cancelText, asConfirm})
  }

  const resetDialog = () => {
    setDialogOpen(false)
    setDialogConfig(undefined)
  }

  const onConfirm = () => {
    resetDialog()
    dialogConfig?.callback(true)
  }

  const onDismiss = () => {
    resetDialog()
    dialogConfig?.callback(false)
  }


  return (
    <ConfirmationDialogContext.Provider value={{openDialog} as any}>
      {dialogConfig && <ContextualizedBaseDialog
          setIsOpenState={dialogOpen}
          title={dialogConfig.title}
          message={dialogConfig.message}
          confirmText={dialogConfig.confirmText}
          cancelText={dialogConfig.cancelText}
          sentiment={dialogConfig.sentiment}
          callback={(result: boolean) => result ? onConfirm() : onDismiss()}
          asConfirm={dialogConfig.asConfirm}
      />}
      {children}
    </ConfirmationDialogContext.Provider>
  )
}

const useConfirmationDialog = () => {
  const {openDialog} = React.useContext(ConfirmationDialogContext)

  const getConfirmation = (options: ConfirmDialogProps) =>
    new Promise((res) => {
      openDialog({callback: res, ...options} as ConfirmDialogProps & ConfirmDialogCustomCallback)
    })

  return {getConfirmation}
}

export {AlertDialog, ConfirmDialog, useConfirmationDialog, ConfirmationDialogProvider}
