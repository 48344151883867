import WizardLayout from '../../../../components/shared/WizardLayout'
import {PageBuild, Route} from '@/components/shared/types'
import styled from 'styled-components'
import {useCallback, useState} from 'react'

export const StyledTable = styled.table`
  border-collapse: collapse;
  border-spacing: 0;
  width: 100%;

  td {
    padding: 1rem;
    width: auto;
    white-space: nowrap;
    text-align: center;
  }

  thead tr {
    font-weight: bold;
  }

  tbody td {
    border-bottom: 1px solid #ececec;
    border-top: 1px solid #ececec;
  }
`

export type NewPagesWithRoutes = {
  id: string
  name: string
  route: string
  validate: boolean
  conflict: boolean
  archive: boolean
  conflictPage: { pageId: string; route: string } | null
}

export const getNewPublishPages = (
  pageBuilds: PageBuild[]
): NewPagesWithRoutes[] => {
  return pageBuilds.map((page) => ({
    id: page.id,
    route: page.page.route.url,
    name: page.page?.name!,
    validate: false,
    conflict: !!page.page?.live_page_build_id,
    archive: false,
    conflictPage: !!page.page?.live_page_build_id
      ? {
          pageId: page.page_id,
          route: page.page!.route.url,
        }
      : null,
  }))
}

interface PublishPagesWizardProps {
  showDialog: boolean
  pageBuilds: PageBuild[]
  onSave: (pageBuilds: PageBuild[]) => void
  closeDialog: () => void
}

/*
 * 1. Recap of pageBuilds to be live -> If pages have routes then show them -> else ask for route input
 * 2. Recap of pageBuilds and new routes -> Conflict route (pages sharing route i.e. dev page and live page sharing same route)
 * or new routes -> if dev pageBuild.page has a route we have 2 options -> replace the live page ("archive") or have this dev pageBuild use
 * a completely different route (create new page -> attach this page build to new page -> create new route -> assign route to new page -> set page build to live)
 * 3. Do the actions from 2
 * 4. Redirects can be omitted
 * 5. Recap
 * */
const PublishPagesWizard = ({
  showDialog,
  pageBuilds,
  onSave,
  closeDialog,
}: PublishPagesWizardProps) => {
  const [newPagesWithRoutes, setNewPagesWithRoutes] = useState(
    getNewPublishPages(pageBuilds)
  )
  const [currentPage, setCurrentPage] = useState(1)

  const handleChangeConflict = useCallback((id: string, route: string) => {
    setNewPagesWithRoutes((prevState) => {
      let tmp = prevState.find((page) => page.id === id)
      console.log(tmp, route)
      if (tmp) {
        tmp = {
          ...tmp,
          conflictPage: {...tmp.conflictPage!, route},
        }
        return [...prevState.filter((page) => page.id !== id), tmp]
      }
      return [...prevState]
    })
  }, [])

  const handleChangeNewPagesWithRoutes = useCallback(
    (idx: number, route: string) => {
      setNewPagesWithRoutes((prevState) => {
        prevState[idx] = {
          ...prevState[idx],
          route,
        }
        return [...prevState]
      })
    },
    []
  )

  const toggleValidate = useCallback(
    (idx: number) => {
      // if no routes, we cannot validate
      if (!newPagesWithRoutes[idx].route) return false
      setNewPagesWithRoutes((prevState) => {
        prevState[idx] = {
          ...prevState[idx],
          validate: !prevState[idx].validate,
        }
        return [...prevState]
      })
    },
    [newPagesWithRoutes]
  )

  const isNext = () => {
    switch (currentPage) {
      case 1:
        return newPagesWithRoutes.every((page) => page.validate)
      case 3: {
        const conflicts = newPagesWithRoutes.filter(
          (page) => page.conflict && !page.archive
        )
        // do we still have conflicts?
        return conflicts.every(
          (conflict) => conflict.route !== conflict.conflictPage!.route
        )
      }
      default:
        return true
    }
  }

  const toggleArchive = useCallback((id: string) => {
    setNewPagesWithRoutes((prevState) => {
      let tmp = prevState.find((page) => page.id === id)
      if (tmp) {
        tmp = {
          ...tmp,
          archive: !tmp.archive,
        }
        return [...prevState.filter((page) => page.id !== id), tmp]
      }
      return [...prevState]
    })
  }, [])

  return (
    <>
      <WizardLayout
        title={'Publish Pages'}
        showDialog={showDialog}
        closeDialog={closeDialog}
        onSave={() =>
          onSave(
            pageBuilds.filter(
              (page) => !!newPagesWithRoutes.find((p) => p.id === page.id)
            )
          )
        }
        isNextAllowed={isNext()}
        getCurrentPage={(currentPage) => setCurrentPage(currentPage)}
      >
        <div>
          <NewPagesPage
            newPagesWithRoutes={newPagesWithRoutes}
            handleChangeNewPagesWithRoutes={handleChangeNewPagesWithRoutes}
            toggleValidate={toggleValidate}
          />
        </div>
        <div>
          <h3>Summary of route actions</h3>
          {/*TODO: Make sure to show ALL Conflicts!!*/}
          {newPagesWithRoutes.map((page) => {
            return (
              <div
                key={page.id}
                style={{display: 'flex', marginBottom: '20px'}}
              >
                <div style={{marginRight: '20px'}}>{page.name}</div>
                <div style={{fontWeight: 'bold'}}>
                  {page.conflict ? 'Conflict' : 'New'}
                </div>
              </div>
            )
          })}
        </div>
        <div>
          <ConflictPage
            newPagesWithRoutes={newPagesWithRoutes}
            handleChangeConflict={handleChangeConflict}
            toggleArchive={toggleArchive}
          />
        </div>
        <div>
          <h3>New published pages summary:</h3>
          <StyledTable>
            <thead>
              <tr>
                <td>Name</td>
                <td>Route</td>
              </tr>
            </thead>
            <tbody>
              {newPagesWithRoutes.map((page) => {
                return (
                  <tr key={page.id}>
                    <td>
                      <span>{page.name}</span>
                    </td>
                    <td>
                      <span>{page.route}</span>
                    </td>
                  </tr>
                )
              })}
            </tbody>
          </StyledTable>
          {newPagesWithRoutes.filter((page) => page.conflict).length > 0 && (
            <>
              <h3 style={{marginTop: '70px'}}>
                Pages that will be updated due to route conflict:
              </h3>
              <StyledTable>
                <thead>
                  <tr>
                    <td>Name</td>
                    <td>New Route</td>
                  </tr>
                </thead>
                <tbody>
                  {newPagesWithRoutes
                    .filter((page) => page.conflict)
                    .map((page) => {
                      return (
                        <tr key={page.id}>
                          <td>
                            <span>{page.name}</span>
                          </td>
                          {page.archive ? (
                            <>
                              <td>This page will be Archived</td>
                            </>
                          ) : (
                            <>
                              <td>
                                <span>{page.conflictPage!.route}</span>
                              </td>
                            </>
                          )}
                        </tr>
                      )
                    })}
                </tbody>
              </StyledTable>
            </>
          )}
        </div>
      </WizardLayout>
    </>
  )
}

/*const StyledButton = styled.button`
  text-align: center;
  border-radius: var(--tina-radius-big);
  box-shadow: var(--tina-shadow-small);
  border: 1px solid var(--tina-color-grey-2);
  font-weight: var(--tina-font-weight-regular);
  background-color: ${(props) =>
    props.disabled ? '#adc3d7' : 'var(--tina-color-primary)'};
  cursor: ${(props) => (props.disabled ? 'not-allowed' : 'pointer')};
  font-size: var(--tina-font-size-1);
  height: 40px;
  padding: 0 var(--tina-padding-big);
  transition: all 85ms ease-out;
  opacity: 1;
  color: var(--tina-color-grey-0);
  margin-right: 12px;
`*/

/*
export const RedirectPage = ({
  redirects,
  selectOptions,
  allRoutes,
  setRedirects,
}: RedirectPageProps) => {
  const availableRoute = allRoutes.find((route) =>
    redirects.every((redirect) => route.id !== redirect.route.id)
  )

  const addRedirect = () => {
    if (availableRoute) {
      setRedirects([
        ...redirects,
        {
          route: availableRoute,
          redirectTo: selectOptions[0],
        },
      ])
    }
  }

  const deleteRedirect = (routeId: string) => {
    const newRedirects = redirects.filter(
      (redirect) => redirect.route.id !== routeId
    )
    setRedirects([...newRedirects])
  }

  const onRouteChange = (id: string, value: string) => {
    const newRoute = allRoutes.find((route) => route.url === value)
    const redirect = redirects.find((red) => red.route.id === id)
    if (newRoute && redirect) {
      const filteredRedirects = redirects.filter((red) => red.route.id !== id)
      redirect.route = newRoute
      setRedirects([...filteredRedirects, redirect])
    }
  }

  const onRedirectChange = (id: string, value: string) => {
    const redirect = redirects.find((red) => red.route.id === id)
    if (redirect) {
      const filteredRedirects = redirects.filter((red) => red.route.id !== id)
      redirect.redirectTo = value
      setRedirects([...filteredRedirects, redirect])
    }
  }

  return (
    <div>
      <div
        style={{
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
        }}
      >
        <h3>Redirects</h3>
        <StyledButton onClick={() => addRedirect()} disabled={!availableRoute}>
          Add Redirect
        </StyledButton>
      </div>
      <StyledTable>
        <thead>
          <tr>
            <td>Route</td>
            <td>Redirect to</td>
            <td>Delete</td>
          </tr>
        </thead>
        <tbody>
          {redirects.length
            ? redirects.map((redirect) => (
                <tr key={redirect.route.id}>
                  <td>
                    <select
                      name="redirect-source"
                      id="redirect-source"
                      onChange={(e) =>
                        onRouteChange(redirect.route.id, e.target.value)
                      }
                      value={redirect.route.url}
                    >
                      {allRoutes.map((route) => (
                        <option key={route.id} value={route.url}>
                          {route.url}
                        </option>
                      ))}
                    </select>
                  </td>
                  <td>
                    <select
                      name="redirect"
                      id="redirect-routes"
                      onChange={(e) =>
                        onRedirectChange(redirect.route.id, e.target.value)
                      }
                      value={redirect.redirectTo}
                    >
                      {selectOptions.map((route, idx) => (
                        <option key={idx} value={route}>
                          {route}
                        </option>
                      ))}
                    </select>
                  </td>
                  <td>
                    <button onClick={() => deleteRedirect(redirect.route.id)}>
                      delete
                    </button>
                  </td>
                </tr>
              ))
            : 'No redirects'}
        </tbody>
      </StyledTable>
    </div>
  )
}
*/

interface NewPagesPageProps {
  newPagesWithRoutes: NewPagesWithRoutes[]
  handleChangeNewPagesWithRoutes: (idx: number, route: string) => void
  toggleValidate: (id: number) => void
}

const NewPagesPage = ({
  newPagesWithRoutes,
  handleChangeNewPagesWithRoutes,
  toggleValidate,
}: NewPagesPageProps) => {
  console.log(newPagesWithRoutes)
  return (
    <StyledTable>
      <thead>
        <tr>
          <td>Name</td>
          <td>Route</td>
          <td>Validate</td>
        </tr>
      </thead>
      <tbody>
        {newPagesWithRoutes.map((page, idx) => {
          const route = page.route ? page.route : ''
          return (
            <tr key={page.id}>
              <td>
                <span>{page.name}</span>
              </td>
              <td>
                <input
                  type="text"
                  value={route}
                  onChange={(e) =>
                    handleChangeNewPagesWithRoutes(idx, e.target.value)
                  }
                  placeholder="no route for this page"
                  disabled={false}
                />
              </td>
              <td>
                <input
                  type="checkbox"
                  checked={page.validate}
                  onChange={() => toggleValidate(idx)}
                />
              </td>
            </tr>
          )
        })}
      </tbody>
    </StyledTable>
  )
}

interface ConflictPageProps {
  newPagesWithRoutes: NewPagesWithRoutes[]
  handleChangeConflict: (id: string, route: string) => void
  toggleArchive: (id: string) => void
}

const ConflictPage = ({
  newPagesWithRoutes,
  handleChangeConflict,
  toggleArchive,
}: ConflictPageProps) => {
  const conflicts = newPagesWithRoutes.filter((page) => page.conflict)
  return (
    <>
      {conflicts.length ? (
        <>
          <h3>
            Conflict with existing pages (pages sharing the same route is not
            permitted). Please update the route or archive the page.
          </h3>
          <StyledTable>
            <thead>
              <tr>
                <td>Name</td>
                <td>Route To Update</td>
                <td>Archive</td>
              </tr>
            </thead>
            <tbody>
              {conflicts.map((page) => {
                return (
                  <tr key={page.id}>
                    <td>
                      <span>{page.name}</span>
                    </td>
                    <td>
                      <input
                        type="text"
                        value={page.conflictPage!.route}
                        onChange={(e) =>
                          handleChangeConflict(page.id, e.target.value)
                        }
                        disabled={page.archive}
                      />
                    </td>
                    <td>
                      <input
                        type="checkbox"
                        checked={page.archive}
                        onChange={() => toggleArchive(page.id)}
                      />
                    </td>
                  </tr>
                )
              })}
            </tbody>
          </StyledTable>
        </>
      ) : (
        <div>
          <h3 style={{textAlign: 'center'}}>No Conflicts</h3>
        </div>
      )}
    </>
  )
}

export default PublishPagesWizard
