import Section from '../shared/Section'
import styled from 'styled-components'
import SectionImage from '../shared/SectionImage'
import {LINK, MENU} from './config'
import LucidInlineBlocks from '../shared/LucidInlineBlocks'

const StyledSection = styled(Section)`
  .section-content {
    z-index: 2;
    grid-template-columns: repeat(5, auto);
    align-items: center;
  }

  .logo {
    width: auto;
  }

  .navigation-items {
    align-items: center;
  }
`

const StyledInlineBlocks = styled(LucidInlineBlocks)`
  display: grid;
  justify-items: center;
  align-items: flex-start;

  @media (min-width: 640px) {
    grid-template-columns: repeat(4, 1fr);
  }
`

export default function Navigation(props
) {
  const {
    image,
    color_scheme,
    className,
    css_overrides,
    color_scheme_id_override,
    lucid_page_section_id,
    cms,
    meta
  } = props

  return (
    <>
      <StyledSection
        cms={cms}
        className={className}
        name="navigation"
        colorSchemeId={color_scheme}
        css_overrides={css_overrides}
        color_scheme_id_override={color_scheme_id_override} meta={meta}
        lucid_page_section_id={lucid_page_section_id}
      >
        <SectionImage
          className="logo"
          name='image'
          src={image?.src}
          alt={image?.alt}
          width={image?.width}
          height={image?.height}
          link={image?.link}
          cms={cms}
        />
        <StyledInlineBlocks
          className='navigation-items'
          name="navigation_items"
          blocks={{menu: MENU, link: LINK}}
          direction='horizontal'
          itemProps={{...props}}
        />
      </StyledSection>
    </>
  )
}

