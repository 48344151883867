import styled from 'styled-components'

const StyledHeading = styled.div`
  h2 {
    text-align: center;
  }
  .title {
    font-size: 3rem;
    display: block;
  }
  .subtitle {
    font-size: 2rem;
    display: block;
  }
`

export default StyledHeading
