import styled from 'styled-components'

export const NavBar = styled.div`
  display: flex;
  margin: var(--tina-padding-big);
  justify-content: space-between;

  .routes-manager-nav {
    display: flex;
    flex-flow: row nowrap;
    align-items: flex-start;
  }
`

export const NavButton = styled.div`
  position: relative;
  border: none;
  background-color: transparent;
  border-radius: 0;
  font-size: var(--tina-font-size-2);
  height: 40px;
  padding: 0 var(--tina-padding-big);
  cursor: ${({isActive}) => (isActive ? 'initial' : 'pointer')};
  border-bottom: ${({isActive}) => (isActive ? '2px solid' : null)};
  border-color: var(--tina-color-primary);
  box-sizing: content-box;
  &:hover {
    color: ${({isActive}) => (isActive ? null : 'var(--tina-color-primary)')};
  }
`
