import StyledHeading from './shared/StyledHeading'
import {InlineTextWithVariables, RichtextWithVariables} from './shared/SectionsWithVariables'
import SectionText from './shared/SectionText'
import SectionImage from './shared/SectionImage'
import Section from './shared/Section'
import styled from 'styled-components'
import SectionButton from './shared/SectionButton'

export default function TitleAndImageCollage(props) {
  const {
    title,
    subtitle,
    image_1,
    image_2,
    intro,
    tagline,
    color_scheme,
    className,
    css_overrides,
    color_scheme_id_override, lucid_page_section_id, meta,
    cms,
  } = props
  return (
    <Section
      cms={cms}
      css_overrides={css_overrides}
      color_scheme_id_override={color_scheme_id_override} meta={meta}
      lucid_page_section_id={lucid_page_section_id}
      className={`${className} title-and-image-collage-section stack full-width custom-max-width`}
      name="titleandimagecollage"
      colorSchemeId={color_scheme}>
      <div className="section-container title-and-image-collage-section-container">
        <div className="section-content">
          <div className="section-heading">
            <h2>
              <span className="title heading-1"><RichtextWithVariables richtext={title} cms={cms} name={'title'}/></span>
              <span className="subtitle heading-2"><RichtextWithVariables richtext={subtitle} cms={cms} name={'subtitle'}/></span>
            </h2>
          </div>
          <p className="description section-richtext section-description paragraph"><RichtextWithVariables richtext={intro}
                                                                                                           cms={cms}
                                                                                                           name={'intro'}/></p>
          <div className="row">
            <div className="columns">
              <div className="title-wrapper">
                <p className="h3 title heading-3"><RichtextWithVariables richtext={tagline} cms={cms} name={'tagline'}/></p>
              </div>
              <a href={image_1?.url} className="image left-image">
                <SectionImage src={image_1?.src ?? 'https://tools--dev.cms.eiidev.com/placeholder/480/480'} name="image_1" alt={image_1?.alt} cms={cms}/>
              </a>
              <a href={image_2?.url} className="image right-image">
                <SectionImage src={image_2?.src ?? 'https://tools--dev.cms.eiidev.com/placeholder/600/400'} name="image_2" alt={image_2?.alt} cms={cms}/>
              </a>
            </div>
          </div>
        </div>
      </div>
    </Section>
  )
}

TitleAndImageCollage.StyleCore = {
  config: {},
  target: {
    componentName: Object.keys({TitleAndImageCollage})[0].toLowerCase(),
  },
  css: ''
}
