import {UnitOptions} from '../options/UnitOptions'

export const MarginFields = {
    createFields(defaults = {}) {
        let defaultFields = {
            unit: 'px',
            topMargin: 0,
            rightMargin: 0,
            bottomMargin: 0,
            leftMargin: 0,
        }
        defaultFields = Object.assign(defaultFields, defaults)
        return {
            margin: {
                content: {
                    unit: {
                        content: 'select',
                        default: defaultFields.unit,
                        options: UnitOptions
                    },
                    topMargin: {
                        content: 'number',
                        default: defaultFields.topMargin,
                    },
                    rightMargin: {
                        content: 'number',
                        default: defaultFields.rightMargin,
                    },
                    bottomMargin: {
                        content: 'number',
                        default: defaultFields.bottomMargin,
                    },
                    leftMargin: {
                        content: 'number',
                        default: defaultFields.leftMargin,
                    }
                }
            }
        }
    },
    createPartialCSS(prefix = '') {
        return `
            margin-top: {${prefix}margin.topMargin}{${prefix}margin.unit};
            margin-right: {${prefix}margin.rightMargin}{${prefix}margin.unit};
            margin-bottom: {${prefix}margin.bottomMargin}{${prefix}margin.unit};
            margin-left: {${prefix}margin.leftMargin}{${prefix}margin.unit};
        `
    }
}