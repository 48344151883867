import {TableContainer, Table} from './styles'

function RoutesTable({
  routes,
  pages,
  routesFilters,
  activeRoutesFilter,
  searchFilter,
  openDialog,
}) {
  const [
    ALL_ROUTES_FILTER,
    PAGE_ROUTES_FILTER,
    REDIRECT_ROUTES_FILTER,
  ] = routesFilters.map(({name}) => name)

  if (!routes || !routes?.length) {
    return <div>No Route! :(</div>
  }

  return (
    <TableContainer>
      <Table
        className="route-manager-table"
        data-testid="route-manager-table"
      >
        <thead>
          <tr role="row">
            <td>
              <input type="checkbox" />
            </td>
            <td className="slug">Path</td>
            <td className="page-name">Name</td>
          </tr>
        </thead>
        {routes ? (
          <tbody>
            {routes
              .filter(({route_type, page, url}) => {
                const searchFilterElements = [url.toLowerCase()]

                if (route_type.toUpperCase() === 'PAGE') {
                  searchFilterElements.push(page.name.toLowerCase())
                }

                return searchFilterElements.some((element) =>
                  element.includes(searchFilter.toLowerCase())
                )
              })
              .filter(({route_type}) => {
                const routeType = route_type.toUpperCase()
                switch (activeRoutesFilter) {
                  case PAGE_ROUTES_FILTER:
                    return routeType === 'PAGE'
                  case REDIRECT_ROUTES_FILTER:
                    return routeType === 'ROUTE'
                  case ALL_ROUTES_FILTER:
                  default:
                    return true
                }
              })
              .map((route) => {
                return (
                  <tr
                    key={route.id}
                    onClick={() => openDialog('UPDATE_ROUTE_DIALOG', {route})}
                  >
                    <td>
                      <input type="checkbox" />
                    </td>
                    <td className="slug">{route.url}</td>
                    <td className="page-name">{route.page.name}</td>
                  </tr>
                )
              })}
          </tbody>
        ) : null}
        {/* <tbody>
          {routes.map((route) => {
            return (
              <tr
                key={route.id}
                onClick={() => openDialog('UPDATE_ROUTE_DIALOG', { route })}
              >
                <td>
                  <input type="checkbox" />
                </td>
                <td className="slug">{route.url}</td>
                <td className="page-name">{route.page.name}</td>
              </tr>
            )
          })}
        </tbody> */}
      </Table>
    </TableContainer>
  )
}

export default RoutesTable
