import {Container, Draggable} from 'react-smooth-dnd'
import React, {Dispatch, SetStateAction, useState} from 'react'
import {DropResult} from 'smooth-dnd'
import styled from 'styled-components'
import {Badge, Button, Modal, Text} from '@nextui-org/react'
import {
  itemCategoryAndColorMap,
  onMenuChangeAction,
  SortableListItem,
  SortableListProps,
} from '@/components/managers/MenuManager/utils/types'
import Helpers from '@/src/utils/shared/helpers'
import applyDrag from '@/components/managers/MenuManager/utils/applyDrag'
import {Trash} from 'iconsax-react'

const Item = styled.div`
  display: flex;
`

const ItemMain = styled.div`
  width: 100%;
  padding: 0 1.1rem 0.6rem;

  > div {
    display: flex;
    justify-content: space-between;
  }

  span {
    margin-top: 5px;
  }
`

const ItemFooter = styled.div`
  display: flex;
  justify-content: space-between;
  background: linear-gradient(180deg,
  rgba(0, 0, 0, 0.05) 0%,
  rgba(0, 0, 0, 0) 99.98%);
  box-shadow: inset 0 1px 3px 0 rgb(0 0 0 / 10%);
  color: gray;
  margin: 0;
  padding: 0.2rem 1.1rem;
  font-size: 0.8rem;
`

export default function SortableList(
  {
    sortable_items,
    style,
    group_name,
    acceptDrops,
    parentInfo,
    behaviour = 'move',
    level = 0,
    onMenuChange,
    isLocked = false
  }: SortableListProps) {

  const onDrop = (dropResult: DropResult & {payload?: SortableListItem}) => {
    const {removedIndex, addedIndex, payload} = dropResult
    const hasChanged = removedIndex !== null || addedIndex !== null
    if (!payload || !hasChanged) return

    onMenuChange({
      type: 'update',
      payload: {newItems: applyDrag(removedIndex, addedIndex, payload, sortable_items, parentInfo), originalItems: sortable_items}
    })
  }

  const [deleteMenuItem, setDeleteMenuItem] = useState<SortableListItem | undefined>()

  function onDeleteMenuItem(item: SortableListItem) {
    setDeleteMenuItem(item)
  }

  return (
    <div className={'sortable-list'} style={{...style}}>
      <Container
        groupName={group_name}
        getChildPayload={(i) => sortable_items[i]}
        onDrop={onDrop}
        // For now, we're just going to allow one level of grouping
        shouldAcceptDrop={(source, payload) => {
          return acceptDrops && !isLocked &&
            !(parentInfo.menu_id && payload.parent_menu_item_id) &&
            !(parentInfo.parent_menu_item_id && (payload.category === 'group' || payload.menu_id))
        }}
        removeOnDropOut={!isLocked}
        dropPlaceholder={{
          className: 'sortable-list-drop-placeholder',
          showOnTop: true,
          animationDuration: 250,
        }}
        behaviour={behaviour}
        // needed to fix position bug on dragged element
        getGhostParent={() => {
          return document.querySelector('#menu-container > .sortable-list')!
        }}
      >
        {sortable_items.sort((a, b) => {
          return a.sort_order - b.sort_order
        }).map((item) => {
          return (
            <Draggable key={`${item.id}-${item.sort_order}`}>
              <Item
                key={item.id}
                className='sortable-list-draggable'
                data-children-count={sortable_items?.length}
              >
                <Badge className='badge-override' size='xs' variant='flat' isSquared color={itemCategoryAndColorMap[item.category]}>
                  <div style={{
                    width: '34px',
                    alignItems: 'center',
                    justifyContent: 'center',
                    display: 'flex',
                    transform: 'rotate(270deg)'
                  }}>
                    <span>{item.category}</span>
                  </div>
                </Badge>
                <div
                  style={{
                    width: '100%',
                    paddingTop: '1rem',
                  }}
                >
                  <ItemMain aria-label={item.label}>
                    <div className='draggable-box'>
                      <div style={{display: 'flex', flexDirection: 'column'}}>
                        <span>{item.label}</span>
                        <span style={{color: 'grey'}}>{'-> ' + (item?.route?.url ?? '[]')}</span>
                      </div>
                      {(item.category === 'group' || item.category === 'link') && !acceptDrops && (
                        <Trash
                          style={{cursor: 'pointer'}}
                          color={'#F31260'}
                          aria-label={`Delete ${item.label}`}
                          onClick={() => onDeleteMenuItem(item)}
                        />
                      )}
                    </div>
                  </ItemMain>
                  <ItemFooter>
                    {item.uses &&
                      <span>
                          Uses
                          <strong>{` (${item.uses})`}</strong>
                        </span>}
                    <span>{Helpers.format.human.date.relative(item.updated_at)}</span>
                  </ItemFooter>
                </div>
              </Item>
              {acceptDrops && item.category === 'group' && (
                <SortableList
                  isLocked={isLocked}
                  key={`${item.id}-${item.sort_order}-${level}`}
                  style={{marginLeft: `${level + 2}rem`}}
                  level={level + 2}
                  onMenuChange={onMenuChange}
                  acceptDrops={true}
                  parentInfo={{parent_menu_item_id: item.id?.split('-').pop()}}
                  sortable_items={item.group_menu_items ?? []}
                  group_name={group_name}
                />
              )}
            </Draggable>
          )
        })}
      </Container>
      <DeleteMenuItemModal
        setDeleteMenuItem={setDeleteMenuItem}
        onMenuChange={onMenuChange}
        item={deleteMenuItem as SortableListItem}
        display={typeof deleteMenuItem !== 'undefined'}
      />
    </div>
  )
}

function DeleteMenuItemModal(
  {display, onMenuChange, setDeleteMenuItem, item}:
    {
      display: boolean,
      onMenuChange: onMenuChangeAction,
      item?: SortableListItem
      setDeleteMenuItem: Dispatch<SetStateAction<SortableListItem | undefined>>
    }) {

  function onCancel() {
    setDeleteMenuItem(undefined)
  }

  function onConfirm() {
    onMenuChange({type: 'delete', payload: item})
  }

  return (
    <div>
      <Modal
        closeButton
        aria-labelledby="delete menu"
        open={display}
        onClose={onCancel}
      >
        <Modal.Header>
          <Text id="modal-title" size={18}>
            <Text b size={18}>
              {`Delete ${item?.label ?? 'Menu Item'}`}
            </Text>
          </Text>
        </Modal.Header>
        <Modal.Body>
          {`Are you sure you wish to delete this ${item?.category ?? ''} menu item?`}
        </Modal.Body>
        <Modal.Footer>
          <Button auto flat color="error" onPress={onCancel}>
            Cancel
          </Button>
          <Button auto onPress={onConfirm}>
            OK
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  )
}
