import Section from '../shared/Section'
import StyledHeading from '../shared/StyledHeading'
import {InlineTextWithVariables, RichtextWithVariables} from '../shared/SectionsWithVariables'
import {MEGA_MENU_BLOCK_ITEM_BLOCKS} from './config'
import styled from 'styled-components'
import LucidInlineBlocks from '../shared/LucidInlineBlocks'
import SectionButton from '../shared/SectionButton'

const StyledSection = styled(Section)`
  .section-content {
    width: 100%;
    max-width: unset;
    padding: 0;
  }
`

const StyledInlineBlocks = styled(LucidInlineBlocks)`
  display: grid;
  justify-items: center;
  align-items: center;

  @media (min-width: 640px) {
    grid-template-columns: repeat(5, 1fr);
  }

  .section-item-wrapper {
    position: relative;

    &:hover {
      ul {
        height: 100%;
        visibility: visible;
        top: 0;
      }
    }
  }

  ul {
    list-style-type: none;
    height: 0;
    margin: 0;
    padding: 0;
    visibility: hidden;
    transition: top 0.4s ease;
    top: 100vh;
  }

  h3 {
    margin-bottom: 0;
  }

  .item-content {
    position: absolute;
    z-index: 1;
    height: 100%;
    width: 100%;
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: center;
  }
`

// TO-DO: Add links
export default function MegaMenuBlock(props) {
  const {
    title,
    subtitle,
    intro,
    // items,
    outro,
    button,
    color_scheme,
    className,
    css_overrides,
    color_scheme_id_override, lucid_page_section_id, meta,
    cms,
  } = props
  return (
    <StyledSection
      cms={cms}
      css_overrides={css_overrides}
      color_scheme_id_override={color_scheme_id_override} meta={meta}
      lucid_page_section_id={lucid_page_section_id}
      className={className}
      name="megamenublock"
      colorSchemeId={color_scheme}>
      <StyledHeading>
        <h2>
          <InlineTextWithVariables cms={cms} name="title" value={title}/>
        </h2>
        <InlineTextWithVariables cms={cms} name="subtitle" value={subtitle}/>
      </StyledHeading>
      <RichtextWithVariables cms={cms} name="intro" richtext={intro}/>
      <StyledInlineBlocks
        itemProps={props}
        name="mega_menu_block_items"
        blocks={MEGA_MENU_BLOCK_ITEM_BLOCKS}
      />
      <RichtextWithVariables cms={cms} name="outro" richtext={outro}/>
      <SectionButton name='button' href={button?.href} label={button?.label} cms={cms} />
    </StyledSection>
  )
}
