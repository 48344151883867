import {
  ArrowRightIcon,
  CopyIcon,
  EditIcon,
  LockIcon,
  TrashIcon,
} from '../../../../icons'
import {stagesColor} from '../../constants'
import {Page, PageBuild} from '@/components/shared/types'
import dayjs from 'dayjs'

interface ListItemProps {
  pageBuild: PageBuild
  isChecked: boolean
  onChange: (id: string) => void
  actions: { [key: string]: () => void }
}

const ListItem = ({
  pageBuild,
  isChecked,
  onChange,
  actions,
}: ListItemProps) => {
  const {page_tags} = pageBuild.page?.attributes!
  return (
    <div
      style={{
        fontSize: '20px',
        padding: '24px',
        borderBottom: '1px solid var(--tina-color-grey-2)',
      }}
    >
      <div style={{display: 'flex'}}>
        <div style={{display: 'flex', alignItems: 'center'}}>
          <input
            type="checkbox"
            style={{scale: '1.2'}}
            onChange={() => onChange(pageBuild.id)}
            checked={isChecked}
          />
        </div>
        <div style={{width: '100%', marginLeft: '20px'}}>
          <div
            style={{
              display: 'flex',
              justifyContent: 'flex-start',
              alignItems: 'center',
              width: '100%',
              marginBottom: '0.5rem',
            }}
          >
            <strong>{pageBuild.page?.name}</strong>
            {/* <span style={{ marginLeft: '30px' }}>ID: {page.id} </span> */}
            <span
              style={{
                marginLeft: '30px',
                backgroundColor: stagesColor[pageBuild.status],
                padding: '6px 12px',
                fontSize: '0.7em',
                borderRadius: '500px',
                color: 'white',
              }}
            >
              {pageBuild.status
                .toLowerCase()
                .split('_')
                .map((word) => {
                  return word.charAt(0).toUpperCase() + word.slice(1)
                })
                .join(' ')}
            </span>
            <div style={{marginLeft: '2rem'}}>
              {page_tags ? (
                page_tags.map((tag, idx) => (
                  <span
                    key={idx}
                    style={{
                      backgroundColor: 'var(--tina-color-primary)',
                      color: 'white',
                      borderRadius: ' 3px',
                      padding: ' 4px 14px',
                      margin: '5px',
                      fontSize: '0.7em',
                    }}
                  >
                    {tag}{' '}
                  </span>
                ))
              ) : (
                <></>
              )}
            </div>
          </div>
          <div
            style={{
              display: 'flex',
              justifyContent: 'space-between',
              alignItems: 'center',
            }}
          >
            <div
              style={{
                display: 'flex',
                fontSize: '1rem',
                lineHeight: 1.5,
              }}
            >
              <div>
                <span>Last update: </span>
                {/* {new Date(page.updatedAt)
                .toString()
                .split(' ')
                .splice(0, 5)
                .join(' ')} */}
                {dayjs(pageBuild.updated_at).format('MM/DD/YYYY - h:mm A')}
              </div>
              <div style={{padding: '0 1rem'}}>|</div>
              {
                <div>
                  <span>Route: </span>
                  {pageBuild.page?.route ? pageBuild.page.route.url : 'None'}
                </div>
              }
              <div style={{padding: '0 1rem'}}>|</div>
              <div>
                <span>Assignee: </span>
                {pageBuild.assigned_to_user?.name || 'None'}
              </div>
            </div>
            <div
              style={{
                display: 'flex',
                justifyContent: 'space-between',
                alignItems: 'center',
              }}
            >
              {!pageBuild.locked ? (
                <>
                  <TrashIcon
                    style={{marginRight: '25px', cursor: 'pointer'}}
                    height="20pt"
                    width="20pt"
                    title="Archived"
                    onClick={actions.trash}
                  />
                  <CopyIcon
                    style={{marginRight: '25px', cursor: 'pointer'}}
                    height="20pt"
                    width="20pt"
                    title="Copy"
                    onClick={actions.copy}
                  />
                  <EditIcon
                    style={{marginRight: '25px', cursor: 'pointer'}}
                    height="20pt"
                    width="20pt"
                    title="Edit"
                    onClick={actions.edit}
                  />
                </>
              ) : (
                <LockIcon
                  style={{marginRight: '25px', cursor: 'pointer'}}
                  width="20pt"
                  height="20pt"
                  title="Unlock"
                  onClick={actions.unlock}
                />
              )}

              <ArrowRightIcon
                style={{marginRight: '25px', cursor: 'pointer'}}
                width="20pt"
                height="20pt"
                title="Load Page"
                onClick={actions.arrow}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default ListItem
