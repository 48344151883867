import {PagesArrayWithHeader} from './newWizards/NewProjectWizard'
import {DialogContent, DialogOverlay} from '@reach/dialog'
import {PageBuild} from '../../../shared/types'
import React, {useState} from 'react'
import {CTA} from '../../Toolbar/WorkflowTasks'

interface ActionsGroupDialogProps {
  showDialog: boolean
  closeDialog: () => void
  selectedPagesProps: string[]
  pages: PageBuild[]
  action: string
  onSave: (pages: string[]) => void
}

const ActionsGroupDialog = ({
  showDialog,
  closeDialog,
  onSave,
  selectedPagesProps,
  pages,
  action,
}: ActionsGroupDialogProps) => {
  const [selectedPages, setSelectedPages] = useState(selectedPagesProps)
  return (
    <DialogOverlay
      isOpen={showDialog}
      onDismiss={closeDialog}
      style={{zIndex: 9000}}
    >
      {' '}
      <DialogContent
        style={{
          borderRadius: '3px',
          boxShadow:
            'rgba(9, 30, 66, 0.08) 0px 0px 0px 1px, rgba(9, 30, 66, 0.08) 0px 2px 1px, rgba(9, 30, 66, 0.31) 0px 0px 20px -6px',
          overflow: 'scroll',
          height: '70vh',
          width: '70vw',
          padding: '0 2rem',
        }}
        aria-labelledby={'label--12'}
      >
        <div
          style={{
            height: '100%',
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'space-between',
          }}
        >
          <div style={{marginTop: '15px'}}>
            <span>{action}</span>
          </div>
          <PagesArrayWithHeader
            pages={pages}
            dispatchSelectedPages={setSelectedPages}
            initSelectedPages={selectedPages}
            style={{paddingBottom: '55px'}}
          />
          <div
            style={{
              display: 'flex',
              justifyContent: 'space-between',
              alignItems: 'baseline',
              position: 'sticky',
              bottom: 0,
              backgroundColor: 'white',
              paddingBottom: '10px',
            }}
          >
            <CTA onClick={closeDialog}>Cancel</CTA>
            <CTA
              style={{
                backgroundColor: 'var(--tina-color-primary)',
                color: 'var(--tina-color-grey-0)',
              }}
              onClick={() => onSave(selectedPages)}
            >
              Save
            </CTA>
          </div>
        </div>
      </DialogContent>{' '}
    </DialogOverlay>
  )
}

export default ActionsGroupDialog
