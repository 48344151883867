import {BuildVersion, Page, PageBuild} from '@/components/shared/types'
import styled from 'styled-components'
import {useEffect, useState} from 'react'
import {useRouter} from 'next/router'

export const buildReverser = {
  live: 'dev',
  dev: 'live'
}

const Container = styled.div`
  .switch {
    position: relative;
    display: inline-block;
    width: 140px;
    height: 34px;
  }

  .switch input {
    display: none;
  }

  .slider {
    position: absolute;
    cursor: pointer;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: #9fc4e1;
    transition: 0.4s;
  }

  .slider:before {
    position: absolute;
    content: '';
    height: 26px;
    width: 26px;
    left: 4px;
    bottom: 4px;
    background-color: white;
    transition: 0.4s;
  }

  input:checked + .slider {
    background-color: var(--tina-color-primary);
  }

  input:focus + .slider {
    box-shadow: 0 0 1px #2196f3;
  }

  input:checked + .slider:before {
    transform: translateX(106px);
  }

  .on {
    display: none;
  }

  .on,
  .off {
    letter-spacing: 2px;
    color: white;
    position: absolute;
    transform: translate(-50%, -50%);
    top: 50%;
    left: 50%;
    font-size: 12px;
    font-family: Verdana, sans-serif;
  }

  input:checked + .slider .on {
    display: block;
  }

  input:checked + .slider .off {
    display: none;
  }

  /* Rounded sliders */

  .slider.round {
    border-radius: 34px;
  }

  .slider.round:before {
    border-radius: 50%;
  }
`

interface PageLiveToDevToggleProps {
  page: Page,
}

const PageLiveToDevToggle = ({page}: PageLiveToDevToggleProps) => {
  const router = useRouter()
  const {asPath} = router
  const [, , siteBuildVersion, siteId, pageId, pageBuildVersion] = asPath.split('/')
  const {live_page_build_id, dev_page_build_id} = page!
  const [isLive, setIsLive] = useState(pageBuildVersion === 'live')

  const buildLabel = isLive ? 'dev' : 'live'

  useEffect(() => {
    const accessKey = `${pageBuildVersion}_page_build_id` as keyof Page
    if (page[accessKey] && pageBuildVersion === buildLabel) {
      const siteBuildVersionOverride = pageBuildVersion === 'dev' ? buildReverser[pageBuildVersion] : siteBuildVersion
      router.push(
          `/edit/${siteBuildVersionOverride}/${siteId}/${pageId}/${buildReverser[pageBuildVersion as BuildVersion]}`
      )
    }
  })

  const handleSwitch = () => {
    const page_build_id = isLive ? dev_page_build_id : live_page_build_id
    if (!page_build_id) {
      router.push(
          `/edit/${siteBuildVersion}/${siteId}/new?pageId=${pageId}&type=page&build=${buildLabel}&pageName=${page.name}`
      )
    } else {
      setIsLive(!isLive)
    }
  }

  return (
    <Container>
      <label className="switch">
        <input
            type="checkbox"
            id="togBtn"
            checked={isLive}
            onChange={handleSwitch}
        />
        <div className="slider round">
          <span className="on">Live Page</span>
          <span className="off">Dev Page</span>
        </div>
      </label>
    </Container>
  )
}

export default PageLiveToDevToggle
