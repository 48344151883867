import {RichtextWithVariables} from './shared/SectionsWithVariables'
import SectionText from './shared/SectionText'
import Section from './shared/Section'
import SectionButton from './shared/SectionButton'
import React, {useState, useEffect} from 'react'
import RSSReader from './shared/RSSReader'
import BlogItem from './BlogItem'
import SectionImage from './shared/SectionImage'

export default function BlogBlock(props) {
  const {
    title,
    subtitle,
    intro,
    outro,
    button,
    image,
    feed,
    color_scheme,
    className,
    css_overrides,
    color_scheme_id_override,
    lucid_page_section_id, meta,
    cms
  } = props
  const [items, setItems] = useState([])
  const [isLoading, setIsLoading] = useState(true)
  
  useEffect(() => {
    const fetchData = async () => {
      setIsLoading(true)
      try {
        const data = await RSSReader(feed, 3)
        if (data && data.length > 0) {
          setItems(data)
        }
      } catch (err) {
        console.error(err)
      }
      setIsLoading(false)
    }
  
    fetchData()
  }, [feed])

  return (
    <Section
      cms={cms}
      css_overrides={css_overrides}
      color_scheme_id_override={color_scheme_id_override} meta={meta}
      lucid_page_section_id={lucid_page_section_id}
      className={className + ' section blogblock-section stack'}
      name="blogblock"
      colorSchemeId={color_scheme}>
      <div className="section-container">
        <div className="section-content blog_gutter_row_2 blog_gutter_column_2">
          {image?.src && (
            <div className="row">
              <div className="column">
                <div className="author-image">
                  <SectionImage
                    name='image'
                    src={image?.src}
                    alt={image?.alt}
                    width={image?.width}
                    height={image?.height}
                    cms={cms}
                  />
                </div>
              </div>
            </div>
          )}
          <div className="section-heading">
            <h1>
              <span className="title heading-1">
                <SectionText name='title' text={title} cms={cms} />
              </span>
              <span className="subtitle heading-2">
                <SectionText name='subtitle' text={subtitle} cms={cms} />
              </span>
            </h1>
          </div>
          <div className="section-description">
            <div className="row">
              <div className="column">
                <div className="section-richtext paragraph">
                  <RichtextWithVariables
                    name='intro'
                    richtext={intro}
                    cms={cms}
                  />
                </div>
              </div>
            </div>
          </div>
          <div className="row blog-posts section-richtext">
            {isLoading && (
              <div className="column">
                <div className="blog-post">
                  <span className="blog-post-title paragraph">Loading...</span>
                </div>
              </div>
            )}
            {!isLoading && items.length === 0 && (
              <div className="column">
                <div className="blog-post">
                  <span className="blog-post-title paragraph">No posts found.</span>
                </div>
              </div>
            )}
            {!isLoading && items.length > 0 && (
              items.map((item) => (
                <BlogItem
                  key={item.id}
                  title={item.title}
                  subtitle={item.subtitle}
                  description={item.description}
                  date={item.date || item.pubDate}
                  url={item.url || item.link}
                />
              ))
            )}
          </div>
          <div className="section-outro">
            <div className="row">
              <div className="column section-richtext">
                <RichtextWithVariables
                  name='outro'
                  richtext={outro}
                  cms={cms}
                />
              </div>
            </div>
          </div>
          {button?.url && button?.label && (
            <div className="button-container">
              <SectionButton
                name='button'
                href={button?.url}
                label={button?.label}
                cms={cms}
              />
            </div>
          )}
        </div>
      </div>
      <div className="section-background"></div>
    </Section>
  )
}

BlogBlock.StyleCore = {
  config: {},
  target: {
    componentName: Object.keys({BlogBlock})[0].toLowerCase(),
  },
  css: ''
}
