import styled from 'styled-components'

export const SearchBoxContainer = styled.div`
  margin: var(--tina-padding-big);
  padding: var(--tina-padding-big) var(--tina-padding-big);
  box-shadow: var(--tina-shadow-big);
  background-color: var(--tina-color-grey-0);
`

export const SearchBox = styled.div`
  display: flex;
  position: relative;
  flex: 1 0 auto;
  align-items: stretch;
  height: 40px;
  line-height: 40px;
  label {
    margin-right: var(--tina-padding-big);
  }
`
