import React, {useState} from 'react'
import {PageBuild, User} from '@/components/shared/types'
import {updatePagesWorkflow} from './AssignUserGroup'
import useUserSearch from '@/src/utils/useUserSearch'
import UserComboBox from '@/components/shared/UserComboBox'
import DialogLayout from '@/components/shared/DialogLayout'

interface RejectDialogProps {
  showDialog: boolean
  closeDialog: () => void
  payload: [PageBuild[], User]
  onSave: () => void
}

const RejectDialog = ({
  showDialog,
  closeDialog,
  payload,
  onSave,
}: RejectDialogProps) => {
  const [assigneeInput, setAssigneeInput] = useState(payload[1].name)
  const [comment, setComment] = useState('')
  const [pages] = payload
  const users = useUserSearch(assigneeInput)

  const handleSave = async () => {
    await updatePagesWorkflow(pages, users[0], 'IN_PROGRESS')
    closeDialog()
    onSave()
  }

  return (
    <DialogLayout
      title={`Reject Dialog - ${!users.length ? 'User required' : ''} ${
        !comment ? 'Comment required' : ''
      }`}
      showDialog={showDialog}
      closeDialog={closeDialog}
      onSave={handleSave}
      isSaveAllowed={!!(users.length && comment)}
    >
      <div style={{margin: '0 1rem'}}>
        <span>Assign to User:</span>
        <UserComboBox
          users={users}
          assigneeInput={assigneeInput}
          setAssigneeInput={setAssigneeInput}
        />

        <label
          htmlFor="story"
          style={{display: 'block'}}
        >
          Reasons for reject:
        </label>
        <br />
        <textarea
          id="story"
          rows={7}
          style={{
            width: '100%',
            border: '1px solid #ccc',
            borderRadius: '4px',
          }}
          value={comment}
          onChange={(e) => setComment(e.target.value)}
        />
      </div>
    </DialogLayout>
  )
}

export default RejectDialog
