import {useContext, useEffect, useState} from 'react'
import WizardLayout from './TempWizardLayout'
import PageRouteForm from './PageRouteForm'
import RedirectRouteForm from './RedirectRouteForm'
import {Select} from '@einsteinindustries/tinacms-fields'
import {ADD_ROUTE} from '@/graphql/mutations'
import {InputLabel} from './styles'
import {useRouter} from 'next/router'
import {LucidSiteContext} from '../../../state/site/Store'

export default function NewRouteWizard({
  showDialog,
  closeDialog,
  pages,
  routes,
  addRoute,
}) {

  const initialState = {
    pageId: pages[0].id,
    routeType: 'ROUTE',
    targetRouteId: null,
    url: '',
    targetUrl: '',
    fromUrl: ''
  }
  const [formValues, setFormValues] = useState(initialState)
  const [allowNext, setAllowNext] = useState(true)

  const router = useRouter()

  const [{site_build_id}] = useContext(LucidSiteContext)

  useEffect(() => {
    console.log('formValues', formValues)
  }, [formValues])

  const handleFormChange = (e) => {
    setFormValues({
      ...formValues,
      [e.target.name]: e.target.value,
    })
  }

  const serverSideRefresh = () => {
    router.replace(router.asPath)
  }

  const handleCloseDialog = () => {
    closeDialog()
    setFormValues(initialState)
  }

  const handleSave = async () => {
    const {pageId, routeType, url, fromUrl, targetRouteId} = formValues

    const type = routeType.toUpperCase()

    let variables = {}
    let input = {
      route_type: type,
      url,
    }

    if (type === 'PAGE') {
      variables['newRoute'] = {
        ...input,
        page_id: pageId,
      }
    }

    if (type === 'ROUTE') {
      variables['newRoute'] = {
        ...input,
        url: fromUrl,
        target_route_id: targetRouteId,
        site_build_id
      }
    }

    await addRoute(ADD_ROUTE, variables)
    closeDialog()
    // We need to fetch server side props to show button to sync proxy
    serverSideRefresh()
    setFormValues(initialState)
  }

  return (
    <WizardLayout
      title="New Route"
      showDialog={showDialog}
      closeDialog={handleCloseDialog}
      onSave={handleSave}
      isNextAllowed={allowNext}
    >
      {/* Wizard Page 1 */}
      <NewRouteTypeForm
        routeType={formValues.routeType}
        handleFormChange={handleFormChange}
      />
      {/* Wizard Page 2 */}
      <NewRouteConfigForm
        renderForm={() => {
          const type = formValues.routeType.toUpperCase()

          if (type === 'PAGE') {
            return (
              <PageRouteForm
                pages={pages}
                pageId={formValues.pageId}
                path={formValues.url}
                handleFormChange={handleFormChange}
                setAllowNext={setAllowNext}
                // onPageChange={(e) => setPageId(e.target.value)}
                // onPathChange={(e) => setUrl(e.target.value)}
              />
            )
          }

          // if (type === 'EXTERNAL') {
          //   return <ExternalRouteForm />
          // }

          if (type === 'ROUTE') {
            return <RedirectRouteForm
              handleFormChange={handleFormChange}
              routes={routes}
              setAllowNext={setAllowNext}
            />
          }
        }}
      />
      {/* Wizard Page 3 */}
      <NewRouteConfirmation
        renderRouteData={() => {
          const {routeType, pageId, fromUrl, targetUrl} = formValues
          const type = routeType.toUpperCase()

          if (type === 'PAGE') {
            const {name} = pages.find((page) => Number(page.id) === Number(pageId))
            return (
              <div>
                <ul>
                  <li>Page: {name}</li>
                  <li>Path: {formValues.url}</li>
                </ul>
              </div>
            )
          }
          if (type === 'ROUTE') {
            const {name, route} = pages.find(page => page.route?.url === targetUrl)
            if (formValues.targetRouteId !== route.id) {
              setFormValues({...formValues, targetRouteId: route.id})
            }
            return (
              <div>
                <ul>
                  <li>Redirect From: {fromUrl}</li>
                  <li>To: {route.url} - ({name})</li>
                </ul>
              </div>
            )
          }
        }}
      ></NewRouteConfirmation>
    </WizardLayout>
  )
}

function NewRouteTypeForm({routeType, handleFormChange}) {
  return (
    <div>
      <InputLabel>Route Type</InputLabel>
      <Select
        input={{
          name: 'routeType',
          value: routeType,
          onChange: (e) => handleFormChange(e),
          style: {marginBottom: 'var(--tina-padding-big)'},
        }}
        options={[{Redirect: 'ROUTE'}, {Page: 'PAGE'}].map((opt) => {
          const [label, value] = Object.entries(opt)[0]
          return {
            label,
            value,
          }
        })}
      />
    </div>
  )
}

function NewRouteConfigForm({renderForm}) {
  return renderForm()
}

function NewRouteConfirmation({renderRouteData}) {
  return (
    <div>
      <h5>Save new route?</h5>
      {renderRouteData()}
    </div>
  )
}
