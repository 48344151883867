// import dynamic from 'next/dynamic'
import {useState} from 'react'
import Section from './shared/Section'
import StyledHeading from './shared/StyledHeading'
import {InlineTextWithVariables, RichtextWithVariables} from './shared/SectionsWithVariables'
import styled from 'styled-components'
import {SectionQuoteFormWrapper} from './shared/SectionQuoteFormWrapper'
import {SectionConsultationForm} from './shared/SectionConsultationForm'

const StyledSection = styled(Section)`
  .field {
    position: relative;
    margin-bottom: 0.5rem;
  }

  .field-textarea {
    height: 180px;
  }

  label {
    position: absolute;
    left: 0.5rem;
    top: 0.5rem;
  }

  input,
  textarea,
  select {
    padding: 1.75rem 0.5rem 0.5rem;
  }

  form {
    max-width: 460px;
    margin: 0 auto 2rem;
    width: 100%;
  }

  input,
  textarea,
  select {
    font-family: Arial, Helvetica, sans-serif;
    width: 100%;
  }

  textarea {
    height: 100%;
  }

  button {
    width: 100%;
    padding: 1rem;
  }
`

const inputNameFields = [
  {
    label: 'Name',
    name: 'label',
    component: 'text',
  },
]

export default function Consultation(props) {
  const {
    title,
    subtitle,
    intro,
    quote,
    author,
    outro,
    color_scheme,
    className,
    css_overrides,
    color_scheme_id_override, lucid_page_section_id, meta,
    cms,
  } = props

  const [name, setName] = useState('')
  const [email, setEmail] = useState('')
  const [phone, setPhone] = useState('')
  const [message, setMessage] = useState('')
  const [location, setLocation] = useState('location_1')

  function handleSubmit(event) {
    event.preventDefault()
    const formSubmission = {name, email, phone, message, location}
    console.log(formSubmission)
    alert('You have submitted the form.')
  }

  return (
    <StyledSection
      cms={cms}
      css_overrides={css_overrides}
      color_scheme_id_override={color_scheme_id_override} meta={meta}
      lucid_page_section_id={lucid_page_section_id}
      className={className}
      name="consultation"
      colorSchemeId={color_scheme}>
      <SectionQuoteFormWrapper cms={cms} title={title} subtitle={subtitle} intro={intro} outro={outro} quote={quote} author={author} className={'consultation-form is-form einstein-form'}>
        <SectionConsultationForm/>
      </SectionQuoteFormWrapper>
    </StyledSection>
  )
}

Consultation.StyleCore = {
  config: {},
  target: {
    componentName: Object.keys({Consultation})[0].toLowerCase(),
  },
  css: ''
}


