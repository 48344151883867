import {DialogContent, DialogOverlay} from '@reach/dialog'
import {Tab, TabList, TabPanel, TabPanels, Tabs} from '@reach/tabs'
import React, {useEffect, useState} from 'react'
import styled from 'styled-components'
import {CrossIcon} from '../../components/icons'
import '@reach/tabs/styles.css'
import '@reach/dialog/styles.css'

const StyledTabPanel = styled(TabPanel)`
  height: 60vh;
`

const StyledDialogOverlay = styled(DialogOverlay)<{ level: number }>`
  z-index: ${(props) => 9000 + props.level};
`

const StyledDialogContent = styled(DialogContent)<{
  width?: string
  height?: string
}>`
  border-radius: 10px;
  overflow: hidden;
  width: ${(props) => props.width || '70vw'};
  box-shadow: 0 3px 7px rgba(0, 0, 0, 0.3);
  padding: 0;
  // we want to center horizontally, with more space at the bottom (looks better)
  margin-top: ${(props) =>
    props.height ? `${50 - (parseInt(props.height, 10) / 2 + 15)}vh` : '10vh'};
`

const DialogHeader = styled.div`
  border-bottom: 1px solid #ccc;
  position: sticky;
  top: 0;
  background-color: white;
  height: 50px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 25px;

  > h4 {
    margin-top: 1.5rem;
  }
`

const CancelCross = styled.button`
  background-color: inherit;
  border: none;
  cursor: pointer;
`

const InnerDialogContent = styled.div<{ height?: string }>`
  margin: 10px 20px;
  height: ${(props) => props.height || '60vh'};
  overflow: scroll;
`

const DialogFooter = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  position: sticky;
  bottom: 0;
  background-color: white;
  height: 50px;
  padding: 4px 25px;
  border-top: 1px solid #ccc;

  > div {
    display: flex;
  }
`

const StyledButton = styled.button`
  border: none;
  background-color: white;
  font-size: 1.1em;
  cursor: pointer;
`

const StyledCancel = styled(StyledButton)`
  color: red;
`

const StyledSave = styled(StyledButton)`
  color: ${(props) =>
    props.disabled ? '#adc3d7' : 'var(--tina-color-primary)'};
  cursor: ${(props) => (props.disabled ? 'not-allowed' : 'pointer')};
`

interface DialogLayoutProps {
  title: string
  showDialog: boolean
  closeDialog: () => void
  onSave: (() => void) | { name: string; action: () => void }
  children: React.ReactNode
  optionalAction?: { name: string; action: () => void }
  level?: number // 1-5 z-index steps
  isSaveAllowed?: boolean
  size?: Partial<{ width: string; height: string }>
}

// Props related to Tabs
interface DialogLayoutWithTabsProps extends DialogLayoutProps {
  tabs: string[]
  getCurrentTab?: (index: number) => void
}

// Props for dialog WITHOUT tabs, in case there are props only relevant for dialog without tabs
interface DialogLayoutWithoutTabsProps extends DialogLayoutProps {
  tabs?: never
  getCurrentTab?: never
}

/**
 *
 * @param title Dialog Title
 * @param showDialog if true Dialog is shown
 * @param closeDialog onClose action
 * @param onSave onSave name and action
 * @param children component composition
 * @param level 1-5 z-index steps
 * @param optionalAction Add another button in the footer
 * @param tabs list of tab name
 * @param getCurrentTab current tab starting from 0
 * @param isSaveAllowed disable save button
 * @param size specify width or height of popup
 *
 */
const DialogLayout = ({
  title,
  showDialog,
  closeDialog,
  onSave,
  children,
  level = 0,
  optionalAction,
  tabs,
  getCurrentTab,
  isSaveAllowed = true,
  size,
}: DialogLayoutWithTabsProps | DialogLayoutWithoutTabsProps) => {
  const [tabIndex, setTabIndex] = useState(0)

  useEffect(() => {
    if (getCurrentTab) getCurrentTab(tabIndex)
  }, [tabIndex, getCurrentTab])

  return (
    <StyledDialogOverlay
      isOpen={showDialog}
      onDismiss={closeDialog}
      level={level}
      data-testid="dialogOverlay"
    >
      {' '}
      <StyledDialogContent
        aria-labelledby={'label--create-page'}
        width={size?.width}
        height={size?.height}
      >
        <Tabs index={tabIndex} onChange={(index) => setTabIndex(index)}>
          <DialogHeader>
            <h4>{title}</h4>
            {tabs?.length && (
              <TabList>
                {tabs.map((tab, idx) => (
                  <Tab key={idx}>{tab}</Tab>
                ))}
              </TabList>
            )}
            <CancelCross aria-label="crossCancel" onClick={() => closeDialog()}>
              <CrossIcon />
            </CancelCross>
          </DialogHeader>{' '}
          <InnerDialogContent height={size?.height}>
            {React.Children.count(children) > 1 ? (
              <TabPanels>
                {React.Children.map(children, (child) => (
                  <StyledTabPanel>{child}</StyledTabPanel>
                ))}
              </TabPanels>
            ) : (
              <>{children}</>
            )}
          </InnerDialogContent>{' '}
          <DialogFooter>
            <div>
              <StyledCancel onClick={closeDialog}>Cancel</StyledCancel>
              {optionalAction && (
                <StyledButton onClick={optionalAction.action}>
                  {optionalAction.name}
                </StyledButton>
              )}
            </div>
            {typeof onSave === 'function' ? (
              <StyledSave onClick={onSave} disabled={!isSaveAllowed}>
                Save
              </StyledSave>
            ) : (
              <StyledSave onClick={onSave.action} disabled={!isSaveAllowed}>
                {onSave.name}
              </StyledSave>
            )}
          </DialogFooter>{' '}
        </Tabs>
      </StyledDialogContent>{' '}
    </StyledDialogOverlay>
  )
}
export default DialogLayout
