import {useEffect, useState} from 'react'
import {useCMS} from '@einsteinindustries/tinacms'
import {Button} from '@einsteinindustries/tinacms-styles'
import {BaseTextField} from '@einsteinindustries/tinacms-fields'
import useSWR from 'swr'
import {lucidDataFetcherV2} from '@/graphql/fetchers'
// import {
//   CREATE_ROUTE_MUTATION,
//   ARCHIVE_ROUTE_MUTATION,
//   UPDATE_ROUTE_MUTATION,
// } from '../../../graphql/mutations'
import {ALL_PAGES} from '@/graphql/queries'
import RoutesTable from './RoutesTable'
import NewRouteWizard from './NewRouteWizard'
import UpdateRouteDialog from './UpdateRouteDialog'
import {Container, NavBar, NavButton, SearchBox, SearchBoxContainer,} from './styles'
import LucidAlert from '@/components/shared/LucidAlert'
import PageError from '../../shared/PageError'
// import { useRouter } from 'next/router'
// import { Page } from '../../shared/types'

const routesFilters = [
  {
    name: 'ALL_ROUTES_FILTER',
    label: 'All',
  },
  {
    name: 'PAGE_ROUTES_FILTER',
    label: 'Pages',
  },
  {
    name: 'REDIRECT_ROUTES_FILTER',
    label: 'Redirects',
  },
]

// const ENDPOINT_URL = process.env.NEXT_PUBLIC_LUCID_API_ENDPOINT

export function RouteManager({siteId}) {
  // TODO: Utility function here to get site Id
  // const { asPath } = useRouter()
  // const siteIdFromUrl = asPath.split('/')[2]

  const {
    data: rawPages,
    error,
    mutate: mutatePages,
  } = useSWR([ALL_PAGES, {site_id: siteId}], lucidDataFetcherV2)

  const cms = useCMS()
  const lucidAlert = new LucidAlert(cms)

  // search filter
  const [searchFilter, setSearchFilter] = useState('')
  // nav filter
  const [navFilter, setNavFilter] = useState(
    routesFilters.find(({name}) => name === 'PAGE_ROUTES_FILTER').name
  )
  // new route modal
  const [showNewRouteDialog, setShowNewRouteDialog] = useState(false)
  // edit route modal
  const [updateRouteDialog, setUpdateRouteDialog] = useState({
    isOpen: false,
    route: null,
  })
  const [routes, setRoutes] = useState([])

  useEffect(() => {
    if (rawPages?.data?.pages) {
      let pageRoutes = []
      for (const page of rawPages?.data?.pages) {
        if (!(page.route)) continue
        const {route_aliases, ...parent} = page.route
        pageRoutes = pageRoutes.concat([parent], route_aliases.map(ra => ({...ra, page: {...page.route.page}})))
      }
      setRoutes(pageRoutes)
    }
  }, [rawPages])

  const isLoading = !error && !rawPages
  const isError = error

  if (isLoading) {
    return <div>loading...</div>
  }

  if (isError) {
    return <PageError title={'We had an error loading this route'} subtitle={'This route could not be loaded, and the page could not be displayed.'} refresh={true} error={isError}/>
  }

  const addRoute = async (query, variables) => {
    const {data} = await lucidDataFetcherV2(query, variables)

    if (data.hasOwnProperty('addRoute')) {
      lucidAlert.success(`Created route: "${data?.addRoute?.url}"`)
    } else {
      lucidAlert.error('Error creating route.')
    }
    mutatePages()
  }

  const updateRoute = async (query, variables) => {
    const {data} = await lucidDataFetcherV2(query, variables)

    if (data.hasOwnProperty('updateRoute')) {
      lucidAlert.success(`Updated route: "${data?.updateRoute?.url}"`)
    } else {
      lucidAlert.error('Error updating route.')
    }
    mutatePages()
  }

  const removeRoute = async (query, variables) => {
    const {data} = await lucidDataFetcherV2(query, variables)

    if (data.hasOwnProperty('removeRoute')) {
      lucidAlert.success('Deleted route.')
    } else {
      lucidAlert.error('Error removing route.')
    }

    mutatePages()
  }

  const handleNavButtonClick = (filterName) => {
    // `click` event handler
    return () => {
      setNavFilter(filterName)
    }
  }

  const openDialog = (dialogName, {route}) => {
    switch (dialogName) {
      case 'NEW_ROUTE_DIALOG':
        return
      case 'UPDATE_ROUTE_DIALOG':
        setUpdateRouteDialog(() => {
          return {
            isOpen: true,
            route,
          }
        })
        return
      default:
        console.error('Missing dialogName')
        return
    }
  }

  const closeDialog = (dialogName) => {
    switch (dialogName) {
      case 'NEW_ROUTE_DIALOG':
        setShowNewRouteDialog(false)
        return
      case 'UPDATE_ROUTE_DIALOG':
        setUpdateRouteDialog(() => {
          return {
            isOpen: false,
            route: null,
          }
        })
        return
      default:
        console.error('Missing dialogName')
        return
    }
  }

  return (
    <Container>
      <NavBar>
        <nav className="routes-manager-nav">
          {routesFilters.map((filter) => (
            <NavButton
              key={filter.name}
              isActive={navFilter === filter.name}
              onClick={handleNavButtonClick(filter.name)}
            >
              {filter.label}
            </NavButton>
          ))}
        </nav>
        <Button
          onClick={() => setShowNewRouteDialog(true)}
          primary
        >
          <span>New Route</span>
        </Button>
      </NavBar>
      <SearchBoxContainer>
        <SearchBox>
          <label>Search</label>
          <BaseTextField
            type="text"
            value={searchFilter}
            onChange={(e) => setSearchFilter(e.target.value)}
          />
        </SearchBox>
      </SearchBoxContainer>
      <RoutesTable
        routes={routes}
        // pages={pages}
        routesFilters={routesFilters}
        activeRoutesFilter={navFilter}
        searchFilter={searchFilter}
        openDialog={openDialog}
      />
      <NewRouteWizard
        showDialog={showNewRouteDialog}
        closeDialog={() => closeDialog('NEW_ROUTE_DIALOG')}
        pages={rawPages?.data?.pages}
        routes={rawPages?.data.pages.filter(p => p.route !== null).map(p => p.route)}
        addRoute={addRoute}
      />
      {updateRouteDialog.route !== null ? (
        <UpdateRouteDialog
          isOpen={updateRouteDialog.isOpen}
          close={() => closeDialog('UPDATE_ROUTE_DIALOG')}
          updateRoute={updateRoute}
          removeRoute={removeRoute}
          route={updateRouteDialog.route}
          // pages={pagesData?.data?.pages}
        />
      ) : null}
    </Container>
  )
}
