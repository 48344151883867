import {parseContentVariables} from '@/src/utils/shared/parseContentVariables'
import {InlineText, InlineTextProps} from '@einsteinindustries/react-tinacms-inline'
import {useState} from 'react'
import SectionRichtext, {SectionRichtextProps} from './SectionRichtext'
import {useLucidContext} from '@/src/state/ServerSideStore'

interface InlineTextWithVariablesProps extends InlineTextProps {
  value: string
  cms: boolean
}

export function InlineTextWithVariables({cms, value, ...props}: InlineTextWithVariablesProps) {
  const [{contents}] = useLucidContext(cms)

  const [editable, setEditability] = useState(false)
  function makeEditable() {
    setEditability(true)
  }

  function makeUneditable() {
    setEditability(false)
  }

  const finalText = parseContentVariables(value, contents as unknown as {[key: string]: string})

  const component = editable ? <InlineText {...props} /> : <>{finalText}</>

  return cms ? (
    <span
      onBlur={makeUneditable}
      onMouseDown={makeEditable}
    >
      {component}
    </span>
  ) : component
}

interface RichtextWithVariablesProps extends SectionRichtextProps {
  richtext: string
  cms: boolean
}

export function RichtextWithVariables({cms, ...props}: RichtextWithVariablesProps) {
  const [{contents}] = useLucidContext(cms)

  const [editable, setEditability] = useState(false)
  function makeEditable() {
    setEditability(true)
  }

  function makeUneditable() {
    setEditability(false)
  }

  const finalText = parseContentVariables(props.richtext, contents as unknown as {[key: string]: string})

  const component = editable
    ? <SectionRichtext {...props} />
    : <span dangerouslySetInnerHTML={{__html: finalText}} />

  return cms ? (
    <span
      onBlur={makeUneditable}
      onMouseDown={makeEditable}
    >
      {component}
    </span>
  ) : component
}
