export function BookIcon() {
  return (
    <svg
      height="100"
      width="100"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 333 333"
      fillRule="evenodd"
      clipRule="evenodd"
      shapeRendering="geometricPrecision"
      textRendering="geometricPrecision"
      imageRendering="optimizeQuality"
    >
      <path d="M154 270s-59-27-116-25V76h13v152s62 6 103 42zm7-9s-48-43-98-43l1-171s53 8 97 50v164zm14 0l1-164c44-42 97-50 97-50l1 171c-51 0-99 43-99 43zm108-32V76h14v169c-58-2-117 25-117 25 42-36 103-41 103-41zM51 64H26v25H13v186s105-2 144 19c0 0 10 6 24 0 0 0 40-26 142-18V94h-14V65h-26V33s-70 7-117 53c0 0-51-53-115-52v30z" />
    </svg>
  )
}
